#users-container {
	padding: 2em !important;
	width: 100%;
	position: relative;
    height: calc(100% - 120px);
    display: flex;

    .table-header li, .table-body li {
    	width: calc(100% / 8);
   	    justify-content: end;

   	    &.shot {
   	    	width: 120px;
   	    }
    }

	.users-section-right {
		width: 100%;

		&.shared {
			width: calc(100% - 342px);
			margin-left: 342px;
		}

		.pagination-div {
		    justify-content: center;
		    margin-top: 1em;
		}
	}
}

html, body, #root {
    overflow: hidden;
}

@media screen and (max-width: 1000px) {
	#users-container {
	
	}
}

@media screen and (max-width: 900px) {

#users-container {

}

}


@media screen and (max-width: 700px) {

#users-container {

}

}