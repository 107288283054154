.balance {
  background-color: #002426;
  // width: 260px;
  width: 100%;
  color: white;
  font-family: Poppins, sans-serif;
  border-radius: 0px 0px 10px 10px;
  padding: 10px;
  position: relative;

  .calendar-icon {
    position: absolute;
    top: 14px;
    right: 13px;
    cursor: pointer;
  }

  .small-text {
    font-size: 12px;
  }

  .flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;

    p {
      font-size: 13px;
      font-weight: 300;
    }
  }

  h2 {
    font-size: 25px;
  }

  .btn {
    width: 100%;
    height: 34px;
    margin: 10px auto;
    border: none;

    a {
      border: none;
      width: 195px;
      height: 40px;
      margin: .8em auto;
      display: block;
      border-radius: 3px;
      text-align: center;
      padding-top: 8px;
      background-color: #00a880;
      color: #ffffff;
    }
  }
}

@media screen and (max-width: 800px) {
  .balance {
    padding: 10px;
    height: 190px;
    width: 100%;

    .small-text {
      font-size: 11px;
    }

    .flex { 
      p {
        font-size: 12px;
      }
    }

    h2 {
      font-size: 20px;
    }

    .btn {
      width: 85%;
      height: 40px;
      margin: 15px auto;
      border: none;
    }
  }
}

@media screen and (max-width: 800px) {
  .balance {
    display: none;
  }
}
