.c-header {
  box-shadow: 0 1px 4px 0 #00000024;
  height: 4px;
  width: 100%;
  position: absolute;
  top: 0px;
  z-index: 1000000000;
  
  &-loader {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

.c-slidingLoader {
  position: relative;
  height: 4px;
  width: 100%;
  overflow: hidden;
  
  &-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: loading 1s infinite alternate cubic-bezier(0.77, 0, 0.175, 1);
  
      &::before {
          content: '';
          display: block;
          background-color: #00eab6;
          background-color: var(--custom_color);
          position: relative;
          height: 100%;
          width: 30%;
          transform: translateX(-50%);
      }
  }
  
  @keyframes loading {
      0% {
          transform: translateX(0);
      }
      
      100% {
          transform: translateX(100%);
      }
  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 110;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.spin {
    z-index: 1111111111111111111111111111111;
  }

  .spinner {
    position: relative;
    pointer-events: none;
    width: 2.5em;
    height: 2.5em;
    border: 0.4em solid transparent;
    border-color: #444;
    border-top-color: var(--custom_color);
    border-radius: 50%;
    animation: loadingspin 1s linear infinite;
    z-index: 111111111111111111111111111111111;
  }

  #close_spinner {
    color: #fff;
    position: absolute;
    bottom: 10px;
    font-size: 18px;
    border-radius: 50%;
    border: 1px solid #fff;
    height: 30px;
    width: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      border: 1px solid var(--custom_color);
      background-color: var(--custom_color);
    }
  }
}

@keyframes loadingspin {
  100% {
    transform: rotate(360deg);
  }
}
