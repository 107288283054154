.profile-submit-btn {
  width: 100%;
  height: 100%;
  background: inherit;
  border: none;
  font-family: Poppins, sans-serif;
  color: #ffffff;
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;
}
