#cadmin-container {
  position: relative;
  overflow: hidden !important;
}

#s-all, #s-all-2 {
  position: relative;

  a {
    font-family: inherit;
    position: relative;
    top: 13px;
    font-weight: 900;
    text-decoration: underline !important;
    font-family: Poppins;
    color: #00a880;
  }
}

.cs_logo {
  margin: 0px auto;
  display: flex;
  position: absolute;
  top: 3em;
  cursor: pointer;
  right: 3em;
  width: calc(25% - 3em);
}

#s-all-2 {
  position: absolute;
  top: 80px;
  right: 70px;
}

.createadmin-section {
  padding: 30px 30px 60px 30px;

  .breadcrumbs  {
    padding: 1em 2.5em 2em;
  }

  .restriction-nav {
    padding-left: 0.5px;
  }

.createadmin-box {
  width: 95%;
  background: #006066;
  position: relative;
  z-index: 10;
  margin: -1px auto 0 auto;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.half-screen {
  display: block;
  position: absolute;
  width: 50%;
  opacity: 1;
  height: auto;
  transition: 100ms ease-in;
  background: #DEFFF7;
  z-index: 1000000000;
  bottom: 0;
  right: 0;
  top: 72px;
  flex-direction: row;

  &.__hide {
    right: -50%;
    opacity: 0;
    // display: none !important;
  }

  .header {
    width: 100%;
    height: 70px;
    padding: 15px;
    background: linear-gradient(270.13deg, #03CF9E 39.02%, rgba(3, 207, 158, 0.35) 107.6%);

    h1 {
      position: absolute;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 39px;
      display: block;
      color: #FFFFFF;
      padding-left: 25px;
    }

    .cancelIcon {
      cursor: pointer;
      position: absolute;
      right: 1em;
      width: 40px;
      height: 40px;
      padding: 10px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 50%;
    }
  }

  .main {
    padding: 2em;
    height: auto;
    width: 100%;
    display: block;

    .overscroll {
      height: auto;
      max-height: calc(100vh - 235px);
      overflow: auto;
      padding-bottom: 1em;
    }

    h3 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      padding-bottom: 10px;
      align-items: center;
      color: #A09F9F;
    }

    ul.p-list {
      display: inline-flex;
      flex-direction: column;
      list-style: none;
      width: 45%;
      padding-right: 4%;
      padding-left: 0;
      border-top: 0.5px solid #03CF9E;

      &.two {
        padding-left: 1%;
        border-top: none;
      }

      &>li {
        padding: 1em 0 0;
        font-family: Poppins;
        position: relative;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #03CF9E;

        .toggle {
          position: absolute;
          right: 0;
          top: 1em;
        }
      }

    }






    .switch {
      position: relative;
      display: inline-block;
      width: 33px;
      margin: 0 2px;
      height: 25px;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 20px;
      width: 36px;
      background-color: #C4C4C4;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      width: 14px;
      height: 14px;
      left: 4px;
      bottom: 3px;
      background-color: #8A8A8A;
      -webkit-transition: .4s;
      transition: .4s;
    }

    input:checked + .slider {
      background-color: #03CF9E;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #03CF9E;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(14px);
      -ms-transform: translateX(14px);
      transform: translateX(14px);
      background: #00A880;
    }

    .slider.round {
      border-radius: 40px;
    }

    .slider.round:before {
      border-radius: 50%;
    }






  }
}


.deposit {
  padding: 3em !important;
  font-family: Poppins, sans-serif;

  h2 {
    color: #ffffff;
    font-size: 15px;
    margin-bottom: 50px;
    font-weight: 100;
  }

  .error {
    margin-bottom: 5px;
    text-align: center;
  }
}

.deposit-flex {
  width: 100% !important;
  display: flex !important;
  margin-bottom: 35px !important;
  justify-content: space-between !important;

  .ml-10 {
    margin-left: 10%;
  }

  .deposit-flex-item-1 {
    width: 280px;

    label {
      color: #03cf9e;
      font-size: 13px;
      display: block;
      margin-bottom: 10px;
    }

    input {
      background: #006066;
      font-family: Poppins, sans-serif;
      color: #ffffff;
      font-weight: 100;
      border: 2px solid #03cf9e;
      height: 44px;
      font-size: 13px;
    }

    input::placeholder {
      color: #ffffff;
      font-weight: 100;
      font-size: 13px;
    }

    input:focus {
      outline: none;
    }

    select {
      width: 100%;
      -webkit-appearance: none;
      -moz-appearance: none;
      background: #006066;
      background-image: url('../../themes/images/down_caret.svg');
      background-repeat: no-repeat;
      background-position-x: 97%;
      background-position-y: 15px;
      border: 2px solid #03cf9e;
      border-radius: 3px;
      height: 44px;
      padding-left: 5px;
      font-family: Poppins, sans-serif;
      color: #ffffff;
      font-weight: 100;
    }

    select:focus {
      outline: none;
    }
  }

  .deposit-flex-item-2 {
    width: 160px;

    label {
      color: #03cf9e;
      font-size: 13px;
      display: block;
      margin-bottom: 10px;
    }

    .bal-sym {
      display: flex;
      border: 2px solid #03cf9e;
      box-sizing: border-box;
      border-radius: 3px;
      padding-right: 10px;

      input {
        background: inherit;
        border: none;
        color: #ffffff;
        font-size: 13px;
        font-weight: 100;
      }

      input::placeholder {
        color: #ffffff;
        font-size: 13px;
        font-weight: 100;
      }

      input:focus {
        outline: none;
      }

      p {
        align-self: center;
        color: #ffffff;
        font-size: 13px;
      }
    }
  }
}

.deposit-amount {
  width: 280px;

  label {
    color: #03cf9e;
    font-size: 13px;
    display: block;
    margin-bottom: 10px;
  }

  .deposit-amount-flex {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .deposit-amount-item-1 {
      width: 65%;
      border: 2px solid #03cf9e;
      border-radius: 3px;

      input {
        background: inherit;
        border: none;
        color: #ffffff;
        font-size: 13px;
        font-weight: 100;
      }

      input::placeholder {
        color: #ffffff;
        font-size: 13px;
        font-weight: 100;
      }

      input:focus {
        outline: none;
      }
    }

    .deposit-amount-item-2 {
      width: 30%;

      select {
        width: 100%;
        height: 100%;
        padding: 3px;
        padding-left: 10px;
        font-family: Poppins, sans-serif;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: #006066;
        background-image: url('../../themes/images/down_caret.svg');
        background-repeat: no-repeat;
        background-position-x: 90%;
        background-position-y: 15px;
        border: 2px solid #03cf9e;
        border-radius: 3px;
        color: #ffffff;
      }

      select:focus {
        outline: none;
      }
    }
  }
}

.restriction-btns {
  display: flex;
  margin-bottom: 15px;
  margin-top: 5px;

  button {
    color: #006066;
    font-size: 12px;
    cursor: pointer;

    border: none;
    padding: 5px 7px;
    font-family: Poppins, sans-serif;
  }

  button:not(:nth-child(1)) {
    margin-left: 4px;
  }

  .yellow {
    background: #ffe602;
  }

  .blue {
    background: #33b4fd;
  }

  .pink {
    background: #cf03a2;
  }
}

.restriction-submit-btn {
  width: 280px;
  background: #03cf9e;
  border-radius: 3px;
  border: none;
  color: #FFFFFF;
  font-size: 14px;
  cursor: pointer;
}
}

.nopac {
  background-color: black !important;
  color: #fff !important;
}

@media screen and (max-width: 600px) {
  .restrictions-section {
    padding: 10px 10px 60px 10px;
  }

  .createadmin-box {
    width: 97%;
  }
}

// @media screen and (max-width: 700px) {
//   .deposit {
//     padding: 10px 15px;
//   }

//   .deposit-flex {
//     display: block;
//     width: 100%;
//     margin-bottom: 10px;

//     .deposit-flex-item-1 {
//       width: 100%;
//       margin-bottom: 15px;
//     }

//     .deposit-flex-item-2 {
//       width: 100%;
//     }
//   }

//   .deposit-amount {
//     width: 100%;
//   }

//   .restriction-submit-btn {
//     width: 100%;
//   }
// }
