// @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800&display=swap')
// @import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap')
// @import url('https://fonts.googleapis.com/css?family=Roboto:400,400i&display=swap')

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto/Roboto-Light.ttf');
}

@import 'class';
@import 'mixins';
@import 'variables';

#App {
  // font-family: "Open Sans", Roboto, Montserrat, sans-serif;
  width: 100%;
  height: 100%;
  overflow: auto;
}
