@use "sass:meta" as ---r09be8z6hd
h1,h2,h3,h4,h5,h6
  font-weight: 600 

h1
  font-size: 3rem
h2
  font-size: 2.5rem
h3
  font-size: 2rem
h4
  font-size: 1.5rem
h5
  font-size: 1.25rem
h6
  font-size: 1rem
/*** Padding spacing properties ***************/
.pl-0, .px-0, .p-0
  padding-left: 0 
.pl-4, .px-4, .p-4
  padding-left: 0.25rem 
.pl-8, .px-8, .p-8
  padding-left: 0.5rem 
.pl-12, .px-12, .p-12
  padding-left: 0.75rem 
.pl-16, .px-16, .p-16
  padding-left: 1rem 
.pl-24, .px-24, .p-24
  padding-left: 1.5rem 
.pl-32, .px-32, .p-32
  padding-left: 2rem 
.pl-40, .px-40, .p-40
  padding-left: 2.5rem 
.pl-48, .px-48, .p-48
  padding-left: 3rem 
.pl-56, .px-56, .p-56
  padding-left: 3.5rem 
.pr-0, .px-0, .p-0
  padding-right: 0 
.pr-4, .px-4, .p-4
  padding-right: 0.25rem 
.pr-8, .px-8, .p-8
  padding-right: 0.5rem 
.pr-12, .px-12, .p-12
  padding-right: 0.75rem 
.pr-16, .px-16, .p-16
  padding-right: 1rem 
.pr-24, .px-24, .p-24
  padding-right: 1.5rem 
.pr-32, .px-32, .p-32
  padding-right: 2rem 
.pr-40, .px-40, .p-40
  padding-right: 2.5rem 
.pr-48, .px-48, .p-48
  padding-right: 3rem 
.pr-56, .px-56, .p-56
  padding-right: 3.5rem 
.pt-0, .py-0, .p-0
  padding-top: 0 
.pt-4, .py-4, .p-4
  padding-top: 0.25rem 
.pt-8, .py-8, .p-8
  padding-top: 0.5rem 
.pt-12, .py-12, .p-12
  padding-top: 0.75rem 
.pt-16, .py-16, .p-16
  padding-top: 1rem 
.pt-24, .py-24, .p-24
  padding-top: 1.5rem 
.pt-32, .py-32, .p-32
  padding-top: 2rem 
.pt-40, .py-40, .p-40
  padding-top: 2.5rem 
.pt-48, .py-48, .p-48
  padding-top: 3rem 
.pt-56, .py-56, .p-56
  padding-top: 3.5rem 
.pb-0, .py-0, .p-0
  padding-bottom: 0 
.pb-4, .py-4, .p-4
  padding-bottom: 0.25rem 
.pb-8, .py-8, .p-8
  padding-bottom: 0.5rem 
.pb-12, .py-12, .p-12
  padding-bottom: 0.75rem 
.pb-16, .py-16, .p-16
  padding-bottom: 1rem 
.pb-24, .py-24, .p-24
  padding-bottom: 1.5rem 
.pb-32, .py-32, .p-32
  padding-bottom: 2rem 
.pb-40, .py-40, .p-40
  padding-bottom: 2.5rem 
.pb-48, .py-48, .p-48
  padding-bottom: 3rem 
.pb-56, .py-56, .p-56
  padding-bottom: 3.5rem 
/*** Margin spacing properties ***************/
.ml-0, .mx-0, .m-0
  margin-left: 0 
.ml-4, .mx-4, .m-4
  margin-left: 0.25rem 
.ml-8, .mx-8, .m-8
  margin-left: 0.5rem 
.ml-12, .mx-12, .m-12
  margin-left: 0.75rem 
.ml-16, .mx-16, .m-16
  margin-left: 1rem 
.ml-24, .mx-24, .m-24
  margin-left: 1.5rem 
.ml-32, .mx-32, .m-32
  margin-left: 2rem 
.ml-40, .mx-40, .m-40
  margin-left: 2.5rem 
.ml-48, .mx-48, .m-48
  margin-left: 3rem 
.ml-56, .mx-56, .m-56
  margin-left: 3.5rem 
.mr-0, .mx-0, .m-0
  margin-right: 0 
.mr-4, .mx-4, .m-4
  margin-right: 0.25rem 
.mr-8, .mx-8, .m-8
  margin-right: 0.5rem 
.mr-12, .mx-12, .m-12
  margin-right: 0.75rem 
.mr-16, .mx-16, .m-16
  margin-right: 1rem 
.mr-24, .mx-24, .m-24
  margin-right: 1.5rem 
.mr-32, .mx-32, .m-32
  margin-right: 2rem 
.mr-40, .mx-40, .m-40
  margin-right: 2.5rem 
.mr-48, .mx-48, .m-48
  margin-right: 3rem 
.mr-56, .mx-56, .m-56
  margin-right: 3.5rem 
.mt-0, .my-0, .m-0
  margin-top: 0 
.mt-4, .my-4, .m-4
  margin-top: 0.25rem 
.mt-8, .my-8, .m-8
  margin-top: 0.5rem 
.mt-12, .my-12, .m-12
  margin-top: 0.75rem 
.mt-16, .my-16, .m-16
  margin-top: 1rem 
.mt-24, .my-24, .m-24
  margin-top: 1.5rem 
.mt-32, .my-32, .m-32
  margin-top: 2rem 
.mt-40, .my-40, .m-40
  margin-top: 2.5rem 
.mt-48, .my-48, .m-48
  margin-top: 3rem 
.mt-56, .my-56, .m-56
  margin-top: 3.5rem 
.mb-0, .my-0, .m-0
  margin-bottom: 0 
.mb-4, .my-4, .m-4
  margin-bottom: 0.25rem 
.mb-8, .my-8, .m-8
  margin-bottom: 0.5rem 
.mb-12, .my-12, .m-12
  margin-bottom: 0.75rem 
.mb-16, .my-16, .m-16
  margin-bottom: 1rem 
.mb-24, .my-24, .m-24
  margin-bottom: 1.5rem 
.mb-32, .my-32, .m-32
  margin-bottom: 2rem 
.mb-40, .my-40, .m-40
  margin-bottom: 2.5rem 
.mb-48, .my-48, .m-48
  margin-bottom: 3rem 
.mb-56, .my-56, .m-56
  margin-bottom: 3.5rem 
.mt-auto, .my-auto, .m-auto
  margin-top: auto 
.mr-auto, .mx-auto, .m-auto
  margin-right: auto 
.mb-auto, .my-auto, .m-auto
  margin-bottom: auto 
.ml-auto, .mx-auto, .m-auto
  margin-left: auto 
/*** flex properties ***************/
.flex
  display: flex 
.flex-inline
  display: inline-flex
.align-items-start
  align-items: flex-start 
.align-items-end
  align-items: flex-end 
.align-items-center
  align-items: center 
.align-items-baseline
  align-items: baseline 
.align-items-stretch
  align-items: stretch 
.align-content-start
  align-content: flex-start 
.align-content-end
  align-content: flex-end 
.align-content-center
  align-content: center 
.align-content-between
  align-content: space-around 
.align-content-around
  align-content: space-around 
.align-content-stretch
  align-content: stretch 
.align-self-auto
  align-self: auto 
.align-self-start
  align-self: flex-start 
.align-self-end
  align-self: flex-end 
.align-self-center
  align-self: center 
.align-self-baseline
  align-self: baseline 
.align-self-stretch
  align-self: stretch 
.justify-content-center
  justify-content: center 
.justify-content-stretch
  justify-content: stretch 
.justify-content-end
  justify-content: flex-end 
.justify-content-around
  justify-content: space-around 
.justify-content-between
  justify-content: space-between 
.justify-content-start
  justify-content: flex-start 
.justify-content-evenly
  justify-content: space-evenly
.flex-wrap-reverse
  flex-wrap: wrap-reverse 
.flex-nowrap
  flex-wrap: nowrap 
.flex-wrap
  flex-wrap: wrap 
.flex-column-reverse
  flex-direction: column-reverse 
.flex-row-reverse
  flex-direction: row-reverse 
.flex-column
  flex-direction: column 
.flex-row
  flex-direction: row 
.flex-grow-0
  flex-grow: 0 
.flex-grow-1
  flex-grow: 1 
.flex-shrink-1
  flex-shrink: 1 
.flex-fill
  flex: 1 1 auto 

/*** Positioning properties ***************/
.relative
  position: relative 
.absolute
  position: absolute 
.sticky
  position: sticky 
.fixed
  position: fixed 
.has-top-0
  top: 0 
.has-bottom-0
  bottom: 0 
.has-right-0
  right: 0 
.has-left-0
  left: 0 
/*** Object properties ***************/
.object-contain
  object-fit: contain
.object-cover
  object-fit: cover
.object-fill
  object-fit: fill
.object-none
  object-fit: none
.object-scale-down
  object-fit: scale-down
.cursor-pointer
  cursor: pointer
/*** Truncate Text ***************/
.truncate, .truncate-2
  overflow: hidden
  display: -webkit-box
  -webkit-box-orient: vertical
.truncate
  -webkit-line-clamp: 1
.truncate-2
  -webkit-line-clamp: 2

.z-index-1
  z-index: 1
.z-index-0
  z-index: 0
.width-25
  width: 25% 
.width-50
  width: 50% 
.width-100
  width: 100% 
.width-fit-content
  width: fit-content 
.height-25
  height: 25% 
.height-50
  height: 50% 
.height-100
  height: 100% 
.height-fit-content
  height: fit-content 
Collapse




@include ---r09be8z6hd.load-css("sass-embedded-legacy-load-done:1")