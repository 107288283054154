#postnews-container {
	padding: 2em !important;
	width: 100%;
	position: relative;
    height: calc(100% - 120px);
    display: flex;

    .loader-container {
    	position: absolute;
	    top: 30%;
	    z-index: 100000000;
	    left: 46.5%;
    }


    [data-placeholder]:empty:before {
      content: attr(data-placeholder);
      color: inherit;
    }

    .table-header li, .table-body li {
    	width: calc(100% / 8);
   	    justify-content: end;

   	    &.shot {
   	    	width: 120px;
   	    }
    }

    #file, #caretposition {
    	display: none !important;
    }

    .n-title {
    	width: 100%;
	    display: block;
	    outline: none;
    }

	.post-container {
		width: calc(100% - 120px);
		position: absolute;
		min-height: calc(100% - 200px);
		padding: 20px;
		background: #000000;
		margin-top: 1em;
		border-radius: 8px;

		button.publish-btn {
		    position: absolute;
		    width: 120px;
		    height: 50px;
		    flex-direction: column;
		    justify-content: center;
		    right: 50px;
		    bottom: 20px;
		    border: 0;
		    outline: none;
		    cursor: pointer;
		    background: #03CF9E;
		    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		    border-radius: 80px;
		    font-family: Poppins;
		    font-style: normal;
		    font-weight: 500;
		    font-size: 14px;
		    line-height: 21px;
		    align-items: center;
		    color: #FFFFFF;

			img {
			    padding: 5px;
			    position: relative;
			    width: 30px;
			    border-radius: 50%;
			    height: 30px;
			    left: 6px;
			    top: 8px;
			    background: #fff;
			}
		}

		.post-header {
			display: flex;
			height: 50px;
			border-bottom: 0.5px solid #C4C4C4;
			margin-bottom: 10px;

			font-family: Poppins;
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 30px;
			align-items: left;
			color: #A09F9F;

			svg {
				position: relative;
			    margin-right: 1em;
			    top: 7px;
			}
		}

		.story-thumbnail {
			display: inline-block;
			width: 250px;
			height: auto;
			float: left;
			cursor: pointer;
		}

		.post-story {
			float: right;
			width: calc(100% - 260px);
			position: relative;
			min-height: 400px;
			display: inline-block;
			border: 1px solid #C4C4C4;
			box-sizing: border-box;
			border-radius: 5px;
			padding: 20px;

			.story {
			    font-family: Poppins;
			    font-style: normal;
			    font-weight: 500;
			    font-size: 14px;
			    outline: aliceblue;
			    min-height: 300px;
			    line-height: 21px;
			    align-items: center;
			    color: #C4C4C4;
			    font-weight: bold;

				a {
					color: #1FCF65 !important;
					margin-left: 2px;
					margin-right: 2px;
					display: inline-block;
				}

				img {
					cursor: pointer;
					margin-left: 10px;
					max-width: 250px;
					height: auto;
					margin-right: 10px;
					display: inline-block;
				}
			}

			div.story-actions {
				display: flex;
				flex-direction: row;
				position: absolute;
				bottom: 1em;

				img {
				    background: #DEFFF7;
				    border: 0.645349px solid #03CF9E;
				    box-sizing: border-box;
				    width: 30px;
				    height: 30px;
				    padding: 5px;
				    cursor: pointer;
				    margin: 10px 5px;
				    border-radius: 50%;

				    &._active {
				    	background: transparent;
				    	border: 1px solid #03CF9E;
				    }
				}
			}
		}
	}

	.news-section-right {
		width: 100%;

		&.shared {
			width: calc(100% - 342px);
			margin-left: 342px;
		}

		.pagination-div {
		    justify-content: center;
		    margin-top: 1em;
		}
	}
}

html, body, #root {
    overflow: hidden;
}

@media screen and (max-width: 1000px) {
	#news-container {
	
	}
}

@media screen and (max-width: 900px) {

#news-container {

}

}


@media screen and (max-width: 700px) {

#news-container {

}

}