// #document-container {
// 	padding: 2em !important;
// 	width: 100%;
// 	position: relative;
//     height: calc(100vh - 70px);
//     overflow: auto;
//     display: flex;

// 	table {
// 		width: 100%;
// 		border: 0;
// 		background: #002022;
// 		margin-top: 50px;
// 		border-collapse: collapse;
// 		thead, tbody {
// 			width: 100%;
// 			border: 0;
// 			border-collapse: collapse;
// 			tr {
// 				width: 100%;
// 				border: 0;
// 				height: 70px;
// 				left: 136px;
// 				top: 265px;
// 				background: #003134;;
// 				border-collapse: collapse;
// 				border-radius: 5px 5px 0px 0px;

// 				th {
// 					border: 0;
// 					border-collapse: collapse !important;
// 					font-family: Poppins;
// 					font-style: normal;
// 					font-weight: 500;
// 					font-size: 14px;
// 					line-height: 21px;
// 					text-align: left;
// 					padding-left: 20px;
// 					align-items: left;
// 					color: #FFFFFF;
// 				}
// 			}
// 		}

// 		tbody {
// 			tr {
// 				background: #006066;
// 				&:nth-child(even) {
// 					border-left: 3px solid #00A880;
// 				}
// 				&:nth-child(odd) {
// 					border-left: 3px solid #006066;
// 				}
// 				td {
// 					font-family: Poppins;
// 					font-style: normal;
// 					font-weight: 300;
// 					font-size: 14px;
// 					line-height: 21px;
// 					text-align: left;
// 					padding-left: 20px;
// 					align-items: left;
// 					color: #fff;
// 					position: relative;

// 					.d-file, .v-file {
// 						position: relative;
// 					    margin-left: 5px;
// 					    cursor: pointer;
// 					    top: 4px;
// 					}

// 					.v-file {
// 						top: 7px;
// 					}

// 					.dc-na {
// 						width: 30px;
// 					    height: 30px;
// 					    padding: 5px;
// 					    font-size: 14px;
// 					    border-radius: 50%;
// 					    align-items: center;
// 				        display: inline-flex;
// 					    justify-content: center;

// 					    &.r {
// 					    	background: #FF3B3B;
// 					    }
// 					    &.y {
// 					    	background: #FEC704;
// 					    }
// 					    &.g {
// 					    	background: #1FCF65;
// 					    }
// 					    &.p {
// 					    	background: #FE00A8;
// 					    }
// 					}

// 					.txt-default {
// 						position: relative;
// 					    top: -4px;
// 					}
// 				}
// 			}
// 		}
// 	}

// 	.users-section-right {
// 		width: 100%;

// 		&.shared {
// 			width: calc(100% - 342px);
// 			margin-left: 342px;
// 		}

// 		.pagination-div {
// 		    justify-content: center;
// 		    margin-top: 1em;
// 		}
// 	}
// }

// html, body, #root {
//     overflow: hidden;
// }

// @media screen and (max-width: 1000px) {
// 	#users-container {
	
// 	}
// }

// @media screen and (max-width: 900px) {

// #users-container {

// }

// }


// @media screen and (max-width: 700px) {

// #users-container {

// }

// }


.borderad-menu {
    border: 1px solid #2E2E2E;
    height: 50px;
    li {
        height: 44px;
        margin-top: 3px;
        margin-left: 3px;
        margin-right: 3px;
        padding: 0 .5em;
        ._tr_spn {
            display: none;
        }
        &._active {
            width: fit-content;
            padding: 0 0.9em 0 1.1em;
            display: inline-flex;
            flex-direction: row;
            justify-content: center;
        }
        &._active > ._tr_spn {
            display: inline-flex;
            margin-left: 10px;
            min-width: 25px;
            padding-left: 4px;
            padding-right: 4px;
            height: 25px;
            border-radius: 1.8em;
            background-color: #000;
            border: 1px solid #777;
            font-size: 12px;
            text-align: center;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
    }
}