.profile-files {

	.new-upd, .new-upd-2 {
		margin-left: 1em;
		color: #1010f8;
		cursor: pointer;
		height: auto;
		font-family: 'Poppins';
		align-items: center;
	}

	.new-upd-2 {
		display: none;
		color: #03cf9e;
	}

	img.vstat {
	    width: 25px;
	    height: 20px;
	}


	ul.for-folders {
		display: block;
		flex-direction: row;
		list-style: none;
		padding-left: 0;
		width: 100%;
		position: relative;

		li {
			position: relative;
			width: 160px;
			cursor: pointer;
			display: inline-flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 180px;
			border-radius: 5px;
			margin: 1em;
				background: #006066;

			&:hover {
				background: #006066;
			}

			img {
				width: 100px;
				height: 100px;
			}

			span {
				font-family: Poppins;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 21px;
				align-items: center;
				text-align: center;
				color: #03CF9E;
			}
		}
	}

	ul.for-files {
		display: block;
		list-style: none;
		// padding-left: 0;
		width: 100%;
		position: relative;

		li {
			display: flex;
			flex-direction: row;
			width: 100%;
			margin-bottom: 20px;

			h1 {
				display: block;
				margin: 1em 0;
				font-family: Poppins;
				font-style: normal;
				font-weight: 500;
				font-size: 22px;
				line-height: 33px;
				display: flex;
				color: #FFFFFF;

			}

			div {
				display: inline-flex;
				flex-direction: column;
				align-items: left;
				margin-left: 1em;
				padding-left: 1em;
				width: 350px;
				justify-content: center;

				span {
					display: inline-block;
					&.f-name {
						font-family: Poppins;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 21px;
						color: #03CF9E;
					}
					&.f-date {
						font-family: Poppins;
						font-style: italic;
						font-weight: normal;
						font-size: 12px;
						line-height: 18px;
						color: #C4C4C4;
					}
				}
			}

			.down-btn, .view-btn {
				width: 164px;
				height: 50px;
				background: #03CF9E;
				border-radius: 2px;
				font-family: Poppins;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 21px;
				display: flex;
				justify-content: center;
				align-items: center;
				border: 0;
				margin-left: 1em;
				align-items: center;
				text-align: center;
				color: #FFFFFF;
			}

			.view-btn {
				width: 116px;
				height: 50px;
				background: transparent;
				border: 1px solid #FFE602;
				box-sizing: border-box;
				border-radius: 2px;
			}
		}
	}

}