.profile-account-info-section {
  font-family: Poppins, sans-serif;

  h4 {
    color: #FFFFFF;
    margin-bottom: 15px;
  }

  .shield-warning {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    p {
      color: #A6A6A6;
      font-size: 13px;
      margin-left: 5px;
    }
  }
}