.are-you-still-logged-in {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 111111;
    display: flex;
    align-items: end;
    flex-direction: row;
    justify-content: center;
    transition: 500ms ease-in;
    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 500px;
        max-width: 100%;
        padding: 1em 8px;
        margin-bottom: 10px;
        height: fit-content;
        background: #000;
        border-radius: 4px;

        span {
            font-size: 100%;
            font-weight: 600;
        }
    
        button {
            width: 100px;
            height: 35px;
            cursor: pointer;
            margin-top: 1em;
            text-align: center;
            border-radius: 4px;
            border: 1px solid #000 !important;
            background-color: var(--custom_color);
            color: var(--custom_alt_color);
            justify-content: center;
            align-items: center;
            display: flex;
        }
    }
}

.table {
    td {
        color: #fff;
    }
}

#devfx-app {
    align-items: flex-start;

    .blur-ct {
        filter: blur(.25rem);
    }
    .App-accs, .App-trds {
        display: inline-flex;
        height: 60px;
        background: transparent;
        padding: 0 1em;
        outline: none;
        margin-left: 1.5rem;
        border: 2px solid #1E1F23;

        *:checked, *:hover {
            background: #000 -webkit-linear-gradient(bottom, #000 0%, #000 100%);
            color: #fff;
          }
    }

    .smol {
        display: inline-flex;
        width: 97%;
        color: #DDD;
        font-size: 13px;
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-bottom: 10px;
    }

    .ft14 {
        font-size: 14px;
        text-align: left;
    }

    .clf {
        display: flex;
        margin-top: 5px;
        color: #D14;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-bottom: 0px !important;
    }

    .overlay.fst {
        z-index: 1000000000000000;
    }

    .trans {
        width: 126px;
        height: 40px;
        display: inline-flex;
        background: #FF2E53;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        align-items: center;
        justify-content: center;

        &.dep {
            background: #1FCF65;
        }
    }
    

    .dropbtn {
        color: #fff;
        background-color: #141517;
        border: 2px solid #1E1F23 !important;
        font-size: 16px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 40px !important;
        width: 100px !important;
        margin-right: 1em;
        cursor: pointer;
    }

    .dropdown {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 40px !important;
        width: 100px !important;

        span.myd {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            img {
                &.myd {
                    width: 40px;
                    height: auto;
                }
                &.ad3 {
                    width: 15px;
                    height: 15px;
                    margin-left: 10px;
                }
            }
        }
    }

    .dropdown-content {
        position: absolute;
        background-color: #393939;
        width: 84px;
        position: absolute;
        overflow: auto;
        z-index: 1;
        left: 0px;
        top: 41px;
    }

    .dropdown-content {
        span {
            color: black;
            padding: 10px 16px;
            text-decoration: none;
            display: none;
            img {
                width: 40px;
                height: auto;
            }
        }
    }

    .show {
        display: block;

        span {
            display: block;
        }
    }

    .few-steps {
        left: 5px;
        top: 75px;
        position: absolute;
        width: 300px;
        height: 500px;
        background: #1E1F23;
        display: flex;
        z-index: 10000000;
        flex-direction: column;
        box-shadow: 1px 3px 5px #000;

        .fh {
            position: relative;
            padding: 10px;
            height: 100px;
            background: #303030;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .canx {
                position: absolute;
                cursor: pointer;
                right: 8px;
                top: 8px;
            }
            .loader-container {
                display: block;
                position: absolute;
                top: calc(50% - 25px);
                left: 15px;
                .loader, .loader:after {
                    margin-top: 0px;
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                }
                .loader {
                    border-top: 0.4em solid rgba(255, 255, 255, 0.2);
                    border-right: 0.4em solid rgba(255, 255, 255, 0.2);
                    border-bottom: 0.4em solid rgba(255, 255, 255, 0.2);
                    animation-duration: 1.5s;
                }
            }
            .j {
                width: 50px;
                height: 50px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border: 0px;
                border-radius: 50%;
                background-color: transparent;
                color: #fff;
                cursor: pointer;
            }
            span {
                font-size: 16px;
                width: calc(100% - 50px - 10px);
            }
        }

        ul {
            padding-left: 0px;
            list-style: none;
            display: flex;
            width: 100%;
            flex-direction: column;
            // margin-top: 1em;

            li {
                display: flex;
                font-size: 18px;
                padding: 15px 1em;
                align-items: center;
                justify-content: flex-start;
                flex-direction: row;
                span {
                    margin-left: 1.5em;
                }
                .i {
                    width: 30px;
                    height: 30px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    border: 0px;
                    border-radius: 50%;
                    background-color: #fff;
                    color: #1E1F23;
                    cursor: pointer;
                }
                img {
                    cursor: pointer;
                }
                .app-btn.dep {
                    width: 100%;
                    background-color: #1fcf65 !important;
                    border-color: #1fcf65 !important;
                }
            }
        }
    }

    .s-reg {
        position: absolute;
        width: 750px;
        height: 480px;
        // left: calc(50vw - 375px);
        // top: calc(50vh - 240px);
        background: #1E1F23;
        border-radius: 12px;
        box-shadow: 1px 3px 5px #000;
            
        &.noab {
            position: unset;
            margin-top: 0px !important;
            max-width: 100% !important;
            box-shadow: none !important;
            width: 100% !important;

            .form-control {
                // height: 40px !important;
                margin-bottom: 0px !important;
            }
        }

        .pbar-w {
            display: flex;
            width: 100%;
            position: absolute;
            top: -28px;
            transition: 400ms ease all;
            height: 16px;
            border-radius: 1em;
            background-color: #EBEBFF;

            &.bottom {
                top: unset;
                bottom: -60px;
            }
    
            .bar {
                width: 100%;
                height: 100%;
                transition: 400ms ease all;
                border-radius: 1em;
                background-color: var(--custom_color);

                &.top {
                    background-color: #1FCF65;
                }
            }

            span {
                position: absolute;
                top: -28px;
                color: #fff;
                display: inline-flex;
                font-size: 16px;
            }
        }

        .pbar-x {
            display: flex;
            width: 100%;
            position: absolute;
            bottom: -33px;
            transition: 400ms ease all;
            height: 22px;
            justify-content: space-between;

            span {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-size: 16px;
                cursor: pointer;
            }
        }

        &.w1 {
            width: 100%;
            max-width: 550px;
            height: auto;
            background-color: rgba(0, 0, 0, 0.8);
        }

        .blok {
            display: flex;
            width: 100%;

            &.cl {
                padding: 16px;
                height: 100%;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .next {
                    margin-top: 10px;
                    width: 100%;
                }

                &>*:not(.row) {
                    margin-bottom: 16px;
                }

                .row {
                    width: 100%;
                    .bac {
                        margin-left: 0px;
                    }
                }
            }

            .blk {
                display: inline-flex;
                width: 50%;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-top: 1em;

                & > * {
                    margin-top: 1.4em;
                }

                h1 {
                    margin-top: 10px;
                    font-size: 48px;
                    font-weight: 700;
                }

                h5 {
                    width: 75%;
                }

                &>span {
                    position: relative;
                    padding-left: 25px;
                    &:before {
                        top: 5px;
                        left: 0px;
                        width: 13px;
                        height: 13px;
                        content: '';
                        cursor: pointer;
                        border-radius: 4px;
                        position: absolute;
                        background-color: #fff;
                    }
                }

                .app-btn {
                    width: 70%;
                    height: 60px;
                    color: #fff;
                    border-radius: 4px;
                    margin-top: 2em;
                    display: inline-flex;
                    flex-direction: column;

                    span {
                        font-size: 14px !important;
                    }
                }

                &._1 {
                    h1 {
                        color: var(--custom_color);
                    }
                    .app-btn {
                        background-color: var(--custom_color);
                        border-color: var(--custom_color);
                        color: var(--custom_alt_color);
                    }
                }

                &._2 {
                    h1 {
                        color: var(--custom_color);
                    }
                    .app-btn {
                        background-color: var(--custom_color);
                        border-color: var(--custom_color);
                        color: var(--custom_alt_color);
                    }
                }
            }
        }

        &.w2 {
            .form-group {
                width: 100%;
                padding: 5px 16px;
                position: relative;

                &.fupdl {
                    label {
                        border: 0px;
                        width: 100%;
                        max-width: 100%;
                        background: #303030;

                        * {
                            text-decoration: none !important;
                        }

                        .file-types {
                            display: none;
                        }
                    }
                }

                &:not(.fupdl) {
                    label {
                        position: absolute;
                        left: 25px;
                        top: -4px;
                        font-size: 12px;
                        padding: 0 8px;
                        display: inline-flex;
                        color: #B2B2B2;
                        background-color: #141517;
                    }
                }

                input, select {
                    color: #fff;
                    height: 40px;
                    border-radius: 5px;
                    border: 1px solid #B2B2B2;
                    background-color: transparent;
                }

                select {
                    background-color: #1E1F23;
                    height: 45px;
                    // height: auto;
                    // * {
                    //     background-color: transparent;
                    // }
                }

                .ad {
                    position: absolute;
                    top: 25px;
                    right: 30px;
                }
            }
            .next {
                width: calc(100% - 32px) !important;
            }
        }

        &.w2, &.w3 {
            width: 100%;
            max-width: 440px;
            height: auto;
            margin-top: 77px;
            background-color: #141517;

            h4 {
                font-size: 22px;
                color: var(--custom_color);
                width: calc(100% + 32px);
                margin-bottom: 0px;
                border-bottom: 4px solid var(--custom_color);
                padding-bottom: 10px;
                margin-left: -16px;
                margin-right: -16px;
            }

            .iblk {
                margin: 16px;
                width: calc(100% - 32px);
                display: flex;
                position: relative;
                min-height: 100px;
                flex-direction: column;
                padding: 1em;
                border-radius: 5px;
                background-color: #1F1F1F;
                
                .pencil {
                    position: absolute;
                    cursor: pointer;
                    top: -10px;
                    right: 10px;
                    height: 20px;
                }

                ul {
                    display: flex;
                    padding-left: 0px;
                    margin-bottom: 0px;
                    flex-direction: column;

                    li {
                        display: inline-flex;
                        margin-bottom: 10px;
                        justify-content: space-between;

                        &:last-child {
                            margin-bottom: 0px;
                        }

                        b {
                            font-size: 14px;
                        }

                        input {
                            background-color: transparent;
                            width: 202px;
                            text-align: right;
                            border: 0px !important;
                            box-shadow: none !important;
                            outline: none !important;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        
        &.w3 {
            max-width: 790px;
            .pbar-w {
                max-width: 440px;
                left: calc((100% - 440px) / 2);
            }
        }

        .csl {
            position: absolute;
            cursor: pointer;
            top: 1.5em;
            right: 1.5em;
        }

        .sr-t {
            position: relative;
            width: 100%;
            background: #303030;
            height: 80px;
            padding-left: 2em;
            display: flex;
            align-items: center;
            border-radius: 12px 12px 0px 0px;
            justify-content: flex-start;

            h2 {
                width: auto;
                font-size: 22px;
                font-weight: 500;
                display: inline-flex;
            }
        }
    }

    #app-menu-overlay {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 1000000000;
        background: transparent;

        top: 70px;
        background-color: rgba(0, 0, 0, 0);

        #app-menu {
            position: absolute;
            width: 663px;
            height: 228px;
            right: 345px;
            top: 10px;
            display: block;
            background: #1E1F23;
            border-radius: 6px;
            box-shadow: 1px 2px 3px #000;

            .ml {
                display: inline-flex;
                width: 50%;
                height: 50%;
                flex-direction: column;
                padding-left: 88px;
                position: relative;
                justify-content: center;
                align-items: flex-start;
                text-decoration: none !important;

                img {
                    position: absolute;
                    top: calc(50% - 24px);
                    left: 22px;
                    height: 47px;
                    width: 47px;
                }

                h4 {
                    color: #fff;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 20px;
                    margin-bottom: 2px;
                    line-height: 30px;
                }

                span {
                    color: #C4C4C4;
                    font-size: 14px;
                }

                &.active {
                    h4, span {
                        color: var(--custom_color);
                    }
                }
                
            }
        }
    }

    header {
        height: 70px;
        display: flex;
        // width: calc(100vw - 1rem);
        background-color: #141517;
        border-bottom: 0.5px solid #3C3C3C;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        width: 100%;
        top: 0px;
        z-index: 100000;

        .App-logo {
            position: unset;
        }

        .a-left, .a-right {
            flex-direction: row;
            display: inline-flex;
            align-items: center;

            &>*, .tpr>* {
                margin-left: 2rem;
            }

            .menu, .tpr {
                margin-left: 15px;
            }

            .App-logo {
                margin-left: 0px;
            }

            .u-name, .ad, .nt, .ch, .ad2, .meni {
                cursor: pointer;
                margin-left: 30px;
            }

            .nt {
                margin-left: 1.5rem;
            }

            .live-chat-mbox {
                margin-left: 1rem;
            }

            .ch, .nt {
                margin-left: 0px;
            }

            .tpr {
                margin-left: 1.5rem !important;
                &>* {
                    margin-left: 20px !important;
                }
                .profile-dropdown {
                    margin-left: 0px !important;
                }
            }

            .meni {
                height: 28px;
                width: 28px;
            }

            .menu {
                height: 100%;
                margin-right: 20px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                * {
                    margin-left: 10px;
                }

                span {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 36px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .tpr, .lg {
                text-decoration: none !important;
                margin-left: 0px;
            }
        }

        .dp {
            height: 48px;
            width: 48px;
        }

        .overlay.drop, #app-menu-overlay {
            top: 70px;
            background-color: rgba(0, 0, 0, 0);
        }
    }

    .App-body {
        background-color: #141517;
        padding-top: 0px !important;
        justify-content: flex-start;
        min-height: auto;
        overflow-x: hidden;

        &.outside {
            height: calc(100vh - 70px);
            margin-top: 70px;
        }

        &.inside:not(.adm) {
            padding-top: 70px !important;
        }
    }
    
    .h-title {
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 0px;
    }

    .page-title {
        display: flex;
        justify-content: flex-start;
        padding: 1em 50px;
        align-items: center;
        border-bottom: 0.5px solid #3C3C3C;
    }
    
    .live-chat-mbox.mbox {
        .message-dropdown {
            display: block !important;
        }
    }

    .live-notice-ibox.ibox {

    }

    .live-hover-box.box {
        .profile-dropdown {
            display: block !important;
        }
    }











    .callouts {
        list-style-type: none;
    }
    
    // li + li {
    //     margin-left: 3.3333%;
    // }

    .callouts li {
        display: inline-block;
        position: relative;
        width: 300px;
        min-height: 80px;
        padding: 15px;
        background-color: #fff;
        border: 1px solid #c1c1c1;
        border-radius: 4px;
        box-shadow: 0 0 10px #c1c1c1;

        * {
            color: #444;
        }
        
        .close-call {
            position: absolute;
            bottom: 5px;
            right: 10px;
            color: blue;
            cursor: pointer;
        }
   }
  
  /* TOP */
  .callouts--top:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      left: 23px;
      top: -42px;
    border: 10px solid transparent;
    border-bottom: 32px solid rgb(193,193,193); /* IE8 Fallback */
    border-bottom: 32px solid rgba(193,193,193,0.5);
    z-index: 2;
  }
  .callouts--top:after {
    content: "";
      position: absolute;
      width: 0;
      height: 0;
      left: 25px;
      top: -32px;
    border: 8px solid transparent;
    border-bottom: 25px solid #fff;
    z-index: 3;
  }
  
  /* BOTTOM */
  .callouts--bottom:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      left: 23px;
      bottom: -42px;
    border: 10px solid transparent;
    border-top: 32px solid rgb(193,193,193); /* IE8 Fallback */
    border-top: 32px solid rgba(193,193,193,0.5);
    z-index: 2;
  }
  .callouts--bottom:after {
    content: "";
      position: absolute;
      width: 0;
      height: 0;
      left: 25px;
      bottom: -31px;
    border: 8px solid transparent;
    border-top: 24px solid #fff;
    z-index: 3;
  }
  
  /* LEFT */
  .callouts--left:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      left: -42px;
      top: 17px;
    border: 10px solid transparent;
    border-right: 32px solid rgb(193,193,193); /* IE8 Fallback */
    border-right: 32px solid rgba(193,193,193,0.5);
    z-index: 2;
  }
  .callouts--left:after {
    content: "";
      position: absolute;
      width: 0;
      height: 0;
      left: -31px;
      top: 20px;
    border: 8px solid transparent;
    border-right: 24px solid #fff;
    z-index: 3;
  }
  
  /* RIGHT */
  .callouts--right:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      right: -42px;
      top: 17px;
    border: 10px solid transparent;
    border-left: 32px solid rgb(193,193,193); /* IE8 Fallback */
    border-left: 32px solid rgba(193,193,193,0.5);
    z-index: 2;
  }
  .callouts--right:after {
    content: "";
      position: absolute;
      width: 0;
      height: 0;
      right: -31px;
      top: 20px;
    border: 8px solid transparent;
    border-left: 24px solid #fff;
    z-index: 3;
  }
  
  
}