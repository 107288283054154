.chat-section {
  display: flex;
  background: #2d2c2c;
  height: calc(100vh - 72px);
  width: 100%;

	.lblink {
		animation: lblink 3s linear infinite;
	}

  @keyframes lblink {
  0%{opacity: 0.2;}
  50%{opacity: 1;}
  100%{opacity: 0.2;}
  }

  .c-list {
    position: relative;
    width: 400px;
    height: 100%;
    display: inline-flex;
    flex-direction: column;
    background: #141517;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }

  .li-loading {
    justify-content: center;
    align-content: center;
    display: flex;
    margin-top: 15%;

    img {
      width: 222px;
      margin: 0 auto;
    }
  }

  .c-main {
    position: relative;
    width: calc(100% - 400px);
    height: 100%;
    display: inline-flex;
    flex-direction: column;
  }

  ul.people {
    display: flex;
    flex-direction: column;
    list-style: none;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-left: 0;
    height: auto;

    &>li {
      width: 100%;
      width: 400px;
      height: 77px;
      display: flex;
      position: relative;
      cursor: pointer;
      padding: 12px 1.5em;
      flex-direction: row;
      // border-bottom: 1px solid rgba(73, 221, 185, 0.25);

      &.active {
        background: #000;
      }

      .online {
        width: 14px;
        height: 14px;
        display: block;
        position: absolute;
        bottom: 10px;
        left: 60px;
        border-radius: 50%;
        background: #0BFF23;
        border: 3px solid #FFFFFF;
      }

      &>img {
        width: 50px;
        height: 50px;
        border: 0;
      }

      &>.p-info {
        width: calc(100% - 50px - 1em);
        padding: 0 0 0 1em;
        display: inline-flex;
        flex-direction: column;
        color: #fff;

        h4 {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #fff;
        }

        span {
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 19px;
          color: #A09F9F;
        }

        .gdot {
          position: absolute;
          width: 15px;
          height: 15px;
          background: #2bd22b;
          bottom: 25px;
          right: 1em;
          text-align: center;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
          color: #fff;
        }
      }
    }
  }


  .c-search {
    margin: 1.5em auto;
    display: flex;
    padding: 0 15px;
    width: 90%;
    border-radius: 3px;
    height: 50px;
    background: #fff;
    border-radius: 3px;

    input {
      background: inherit;
      border: none;
      color: #444 !important;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      display: flex;
      height: 100%;
      height: 50px;
      width: 95%;
      align-items: center;
    }

    img {
      width: 25px;
    }
    
    input:focus {
      outline: none;
    }

    input::placeholder {
      color: #C4C4C4;
    }
  }




  .section1 {
    width: 100%;
    display: flex;
    height: 100px;
    position: relative;
    padding: 25px 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

    img {
      width: 50px;
      height: 50px;
      display: inline-flex;
      border: 0;
    }

    .p2-info {
      width: calc(100% - 50px - 40px);
      padding: 0 0 0 1em;
      display: inline-flex;
      flex-direction: column;

      h4 {
        font-family: Poppins;
        font-style: normal;
        line-height: 24px;
        font-weight: 500;
        font-size: 16px;
        display: flex;
        align-items: center;
        color: #000000;
      }

      span {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
        color: #A09F9F;
      }
  }
}
  .section2 {
    width: 100%;
    height: auto;
    // height: 250px;
    overflow: auto;
    display: contents;

    p {
      font-size: 12px;
      color: #A09F9F;
      margin-bottom: 8px;
    }

    ul {
      width: 100%;
      padding-left: 0px;
      list-style-type: none;
      margin-bottom: 120px;
      padding-top: 1em;
      overflow: auto;
      height: calc(100vh - 280px);

      li {
        width: 100% !important;
        margin-bottom: 5px;
        font-weight: 300 !important;
        cursor: pointer;

        .m-time {
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 12px;
          display: flex;
          margin: 10px;
          align-items: center;
          color: #A7A7A7;
        }

        &.y-msg {
          display: flex;
          padding: 5px 1em;
          position: relative;

          .em {
            position: absolute;
            top: -14px;
            font-size: 12px;
            color: #F0F0F0;
          }

          div.m-text {
            word-break: break-all;
            padding: 12px 1em;
            width: auto;
            min-width: 140px;
            display: inline-flex;
            background: #F0F0F0;
            position: relative;
            color: #000;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 10px;
            max-width: 55%;
            border-radius: 0px 20px 20px 20px;
          }
        }

        &.m-msg {
          display: flex;
          padding: 5px 1em;
          position: relative;
          justify-content: flex-end;

          .em {
            position: absolute;
            top: -14px;
            font-size: 12px;
            color: #009688;
          }

          div.m-text {
            word-break: break-all;
            float: right;
            padding: 12px 1em;
            width: auto;
            min-width: 140px;
            display: inline-flex;
            background: #03CF9E;
            position: relative;
            color: #fff;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 10px;
            max-width: 55%;
            border-radius: 20px 0px 20px 20px;
          }
        }
        
        .iim {
          padding: 0px !important;
        }
      }
    }
  }

  .section3 {
    display: flex;
    width: calc(100% - 2px);
    height: 110px;
    margin-left: 2px;
    background: #2d2c2c;
    padding: 10px 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    flex-direction: row;
    border: 1px solid #DDD;
    align-items: center;
    justify-content: center;
    border: 0;

    &>div.s3 {
      display: flex;
      width: calc(100% - 40px);
      height: 90px;
      background: #FFFFFF;
      // box-shadow: 0px 4px 4px #F0F0F0;
      border-radius: 5px;
      padding: 10px;
      margin: 20px;
      position: absolute;
      bottom: 0;
      right: 0;
      flex-direction: row;
      border: 1px solid #DDD;
      align-items: center;
      justify-content: center;
    }

    textarea {
      width: calc(100% - 2em);
      height: 70px;
      margin-right: 2em;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      border: 0;
      display: flex;
      align-items: center;
      color: #000000;
      resize: none;
      outline: none !important;
    }

    .send {
      width: 30px;
      border: 0;
      position: absolute;
      right: 2em;
      bottom: 30px;
      height: 30px;
      margin-left: 10px;
      outline: none;
      cursor: pointer;
      background: transparent;
      border-radius: 50px;
    }
  }
}
