.qs-datepicker-container {
	background: #fff !important;
    font-size: 1rem;
    font-family: sans-serif;
    color: #000;
    position: absolute;
    width: 15.625em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    z-index: 9001;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid grey;
    border-radius: 0;
    border: 1px solid #eeeeee;
    overflow: hidden;
    background: #fff;
    -webkit-box-shadow: 0 1.25em 1.25em -0.9375em rgba(0, 0, 0, 0.3);
    box-shadow: 0 1.25em 1.25em -0.9375em rgba(0, 0, 0, 0.3);
}
.qs-datepicker-container * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.qs-centered {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.qs-hidden {
    display: none;
}
.qs-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.75);
    color: #fff;
    width: 100%;
    height: 100%;
    padding: 0.5em;
    z-index: 1;
    opacity: 1;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.qs-overlay.qs-hidden {
    opacity: 0;
    z-index: -1;
}
.qs-overlay .qs-overlay-year {
    background: rgba(0, 0, 0, 0);
    border: none;
    border-bottom: 1px solid #fff;
    border-radius: 0;
    color: #fff;
    font-size: 0.875em;
    padding: 0.25em 0;
    width: 80%;
    text-align: center;
    margin: 0 auto;
    display: block;
}
.qs-overlay .qs-overlay-year::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.qs-overlay .qs-close {
    padding: 0.5em;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
}
.qs-overlay .qs-submit {
    border: 1px solid #fff;
    border-radius: 0.26392em;
    padding: 0.5em;
    margin: 0 auto auto;
    cursor: pointer;
    background: hsla(0, 0%, 50.2%, 0.4);
}
.qs-overlay .qs-submit.qs-disabled {
    color: grey;
    border-color: grey;
    cursor: not-allowed;
}
.qs-overlay .qs-overlay-month-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}
.qs-overlay .qs-overlay-month {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: calc(100% / 3);
    cursor: pointer;
    opacity: 0.5;
    -webkit-transition: opacity 0.15s;
    transition: opacity 0.15s;
}
.qs-overlay .qs-overlay-month.active,
.qs-overlay .qs-overlay-month:hover {
    opacity: 1;
}
.qs-controls {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 10px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    background: transparent;
    -webkit-filter: blur(0);
    filter: blur(0);
    -webkit-transition: -webkit-filter 0.3s;
    transition: -webkit-filter 0.3s;
    transition: filter 0.3s;
    transition: filter 0.3s, -webkit-filter 0.3s;
}
.qs-controls.qs-blur {
    -webkit-filter: blur(5px);
    filter: blur(5px);
}
.qs-arrow {
    height: 1.5625em;
    width: 1.5625em;
    position: relative;
    cursor: pointer;
    border-radius: 0.26392em;
    -webkit-transition: background 0.15s;
    transition: background 0.15s;
}
.qs-arrow:hover {
    background: rgba(0, 0, 0, 0.1);
}
.qs-arrow:hover.qs-left:after {
    border-right-color: #000;
}
.qs-arrow:hover.qs-right:after {
    border-left-color: #000;
}
.qs-arrow:after {
    content: "";
    border: 0.39062em solid rgba(0, 0, 0, 0);
    position: absolute;
    top: 50%;
    -webkit-transition: border 0.2s;
    transition: border 0.2s;
}
.qs-arrow.qs-left:after {
    border-right-color: grey;
    right: 50%;
    -webkit-transform: translate(25%, -50%);
    -ms-transform: translate(25%, -50%);
    transform: translate(25%, -50%);
}
.qs-arrow.qs-right:after {
    border-left-color: grey;
    left: 50%;
    -webkit-transform: translate(-25%, -50%);
    -ms-transform: translate(-25%, -50%);
    transform: translate(-25%, -50%);
}
.qs-month-year {
    /*font-weight: 700;*/
    -webkit-transition: border 0.2s;
    transition: border 0.2s;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    cursor: pointer;
}
.qs-month-year:hover {
    border-bottom: 1px solid grey;
}
.qs-month-year:active:focus,
.qs-month-year:focus {
    outline: none;
}
.qs-month {
    padding-right: 0.5ex;
}
.qs-year {
    padding-left: 0.5ex;
}
.qs-squares {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0.3125em;
    -webkit-filter: blur(0);
    filter: blur(0);
    -webkit-transition: -webkit-filter 0.3s;
    transition: -webkit-filter 0.3s;
    transition: filter 0.3s;
    transition: filter 0.3s, -webkit-filter 0.3s;
}
.qs-squares.qs-blur {
    -webkit-filter: blur(5px);
    filter: blur(5px);
}
.qs-square {
    width: calc(100% / 7);
    height: 1.5625em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    color: #000000;
    -webkit-transition: background 0.1s;
    transition: background 0.1s;
    border-radius: 0.26392em;
}
.qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover {
    /*background: orange;*/
    color: #16be96;
}
.qs-current {
    font-weight: 700;
    background: #1dc59d;
    color: #fff;
}
.qs-active,
.qs-range-end,
.qs-range-start {
    background: #16be96;
    color: #fff;
}
.qs-range-start:not(.qs-range-6) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.qs-range-middle {
    background: #d4ebf2;
}
.qs-range-middle:not(.qs-range-0):not(.qs-range-6) {
    border-radius: 0;
}
.qs-range-middle.qs-range-0 {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.qs-range-end:not(.qs-range-0),
.qs-range-middle.qs-range-6 {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.qs-disabled,
.qs-outside-current-month {
    opacity: 0.2;
}
.qs-disabled {
    cursor: not-allowed;
}
.qs-day,
.qs-empty {
    cursor: default;
}
.qs-day {
    font-weight: 700;
    color: grey;
    font-size: 70%;
}
.qs-event {
    position: relative;
}
.qs-event:after {
    content: "";
    position: absolute;
    width: 0.46875em;
    height: 0.46875em;
    border-radius: 50%;
    background: #07f;
    bottom: 0;
    right: 0;
}














.dtp,
.dtp * {
	font-family: Poppins;
}

.dtp { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0.4); z-index: 20000000000000000000000000000000000000000000000; font-size: 14px; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }
.dtp > .dtp-content { background: #fff; max-width: 300px; box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); max-height: 520px; position: relative; left: 50%; }
.dtp > .dtp-content > .dtp-date-view > header.dtp-header { background: linear-gradient(90deg, #01A880 32.82%, #2AD3AB 89.15%); color: #fff; text-align: center; padding: 0.3em; }

.dtp div.dtp-date, .dtp div.dtp-time { background: linear-gradient(90deg, #01A880 32.82%, #2AD3AB 89.15%); text-align: center; color: #fff; padding: 10px; }
.dtp div.dtp-date > div { padding: 0; margin: 0; }
.dtp div.dtp-actual-month { font-size: 1.5em; }
.dtp div.dtp-actual-num { font-size: 3em; line-height: 0.9; }
.dtp div.dtp-actual-maxtime { font-size: 3em; line-height: 0.9; }
.dtp div.dtp-actual-year { font-size: 1.5em; color: #DCEDC8; }
.dtp div.dtp-picker { padding: 1em; text-align: center; }

.dtp div.dtp-picker-month, .dtp div.dtp-actual-time { font-weight: 500; text-align: center; }
.dtp div.dtp-picker-month { padding-bottom:20px!important; text-transform: uppercase!important; }

.dtp .dtp-close { position: absolute; top: 0.5em; right: 1em; }
.dtp .dtp-close > a { color: #fff; }
.dtp .dtp-close > a > i { font-size: 1em; }

.dtp table.dtp-picker-days { margin: 0; min-height: 251px;}
.dtp table.dtp-picker-days, .dtp table.dtp-picker-days tr, .dtp table.dtp-picker-days tr > td { border: none; }
.dtp table.dtp-picker-days tr > td {  font-weight: 700; font-size: 0.8em; text-align: center; padding: 0.5em 0.3em; }
.dtp table.dtp-picker-days tr > td > span.dtp-select-day { color: #BDBDBD!important; padding: 0.4em 0.5em 0.5em 0.6em;}
.dtp table.dtp-picker-days tr > td > a, .dtp .dtp-picker-time > a { color: #212121; text-decoration: none; padding: 0.4em 0.5em 0.5em 0.6em; border-radius: 50%!important; }
.dtp table.dtp-picker-days tr > td > a.selected{ background: #8BC34A; color: #fff; }
.dtp table.dtp-picker-days tr > th { color: #757575; text-align: center; font-weight: 700; padding: 0.4em 0.3em; }

.dtp .p10 > a { color: linear-gradient(90deg, #01A880 32.82%, #2AD3AB 89.15%); text-decoration: none; }
.dtp .p10 { width: 10%; display: inline-block; }
.dtp .p20 { width: 20%; display: inline-block; }
.dtp .p60 { width: 60%; display: inline-block; }
.dtp .p80 { width: 80%; display: inline-block; }

.dtp a.dtp-meridien-am, .dtp a.dtp-meridien-pm { position: relative; top: 10px; color: #212121; font-weight: 500; padding: 0.7em 0.5em; border-radius: 50%!important;text-decoration: none; background: #eee; font-size:1em; }
.dtp .dtp-actual-meridien a.selected { background: #689F38; color: #fff; }

.dtp .dtp-picker-time > .dtp-select-hour { cursor: pointer; }
.dtp .dtp-picker-time > .dtp-select-minute { cursor: pointer; }

.dtp .dtp-buttons { padding: 0 1em 1em 1em; text-align: right; }

.dtp.hidden, .dtp .hidden { display: none; }
.dtp .invisible { visibility: hidden; }

.dtp .left { float: left; }
.dtp .right { float: right; }
.dtp .clearfix { clear: both; }

.dtp .center { text-align: center; }

.dtp-picker-year{
    margin-bottom: 1px;
}

.year-picker-item{
    text-align: center;
    padding-top: 5px;
    font-size: large;
    cursor: pointer;
    margin: 0 auto;
}

.dtp-actual-year:hover{
    cursor: pointer;
}
.dtp-actual-year.disabled:hover{
    cursor: inherit;
}
.year-picker-item:hover{
    color:#689F38;
}

.year-picker-item.active{
    color:#689F38;
    font-weight: bold;
}

.dtp-select-year-range{
    display: inline-block;
    text-align: center;
    width: 100%;
}


.dpt button {
	display: inline-block !important;
	padding: 6px 12px !important;
	margin-bottom: 0 !important;
	font-size: 14px !important;
	font-weight: 400 !important;
	line-height: 1.42857143 !important;
	text-align: center !important;
	white-space: nowrap !important;
	vertical-align: middle !important;
	-ms-touch-action: manipulation !important;
	touch-action: manipulation !important;
	cursor: pointer !important;
	-webkit-user-select: none !important;
	-moz-user-select: none !important;
	-ms-user-select: none !important;
	user-select: none !important;
	background-image: none !important;
	border: 1px solid transparent !important;
	border-radius: 4px !important;
	border: none !important;
    border-radius: 2px !important;
    position: relative !important;
    padding: 8px 30px !important;
    margin: 10px 1px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    text-transform: uppercase !important;
    letter-spacing: 0 !important;
    will-change: box-shadow,transform !important;
    -webkit-transition: -webkit-box-shadow .2s cubic-bezier(.4,0,1,1),background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1) !important;
    -o-transition: box-shadow .2s cubic-bezier(.4,0,1,1),background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1) !important;
    transition: box-shadow .2s cubic-bezier(.4,0,1,1),background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1) !important;
    outline: 0 !important;
    cursor: pointer !important;
    text-decoration: none !important;
    background: 0 0 !important;
}