#unsubscribers-container {
	padding: 2em !important;
	width: 100%;
	position: relative;
    height: calc(100% - 120px);
    display: flex;

    .unsubscribers-content {
    	display: flex;
    	width: 100%;
    	height: auto;
    	margin: 2em 0;
    	align-items: center;
    	flex-direction: column;
    	justify-content: center;

    	h2.intro {
			font-family: Poppins;
			font-style: normal;
			font-weight: 500;
			font-size: 35px;
			line-height: 45px;
			margin-top: 20px;
			align-items: center;
			color: #C4C4C4;
    	}

    	p.intro {
			font-family: Poppins;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 21px;
			margin-top: 10px;
			align-items: center;
			color: #FFFFFF;
    	}

    	.create-list {
    		margin-top: 1em;
    		margin-left: 0;
    		width: 200px;

    		span, img {
    			position: relative;
    			top: 4px;
    		}

    		span {
    			top: -5px;
    			margin-left: 7px;
    		}
    	}
    }

    .page-title {
	    justify-content: space-between;
	}

	.table-header li:first-child, .table-body li:first-child {
		width: 350px;
	}

	.table-header li, .table-body li {
		width: calc(100% / 6);
		position: relative;


		.fil-u {
			position: relative;
		    top: -6px;
		    right: -8px;
		}

		.fil-d {
			position: relative;
		    bottom: -4px;
		    left: -4px;
		}
	}

	.table-header {
		background: #002F32;
	}

	.table-body {
		background: #006066;
	}

    .create-list {
		width: 120px;
		height: 50px;
		border: 0;
		background: #00A880;
		border-radius: 3px;
		font-family: Poppins;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
		align-items: center;
		text-align: center;
		color: #FFFFFF;
		margin-left: 1em;

		&.imp {
			color: #00A880;
			background: transparent;
			border: 2px solid #00A880;
		}
    }

	.unsubscribers-section-right {
		width: 100%;

		&.shared {
			width: calc(100% - 342px);
			margin-left: 342px;
		}

		.pagination-div {
		    justify-content: center;
		    margin-top: 1em;
		}
	}
}

html, body, #root {
    overflow: hidden;
}

@media screen and (max-width: 1000px) {
	#unsubscribers-container {
	
	}
}

@media screen and (max-width: 900px) {

#unsubscribers-container {

}

}


@media screen and (max-width: 700px) {

#unsubscribers-container {

}

}