#with-container {
	padding: 2em !important;
	width: 100%;
	position: relative;
    height: calc(100% - 120px);
    display: flex;

    .table-header li, .table-body li {
    	min-width: calc(100% / 9);
    	&.sn {
    		width: 80px !important;
    		min-width: 80px !important;
    	}
    }

	.viewdet {
		cursor: pointer;
		color: #ffa500;
		padding: 2px 10px;
		border: 1px solid #ffc107;
		border-radius: 2em;
		font-size: 12px;
		outline: none;

		&:hover {
			background-color: #ffa3;
		}
	}

    .pointers > * {
    	cursor: pointer;
    }


	.with-section-right {
		width: 100%;

		&.shared {
			width: calc(100% - 342px);
			margin-left: 342px;
		}

		.pagination-div {
		    justify-content: center;
		    margin-top: 1em;
		}
	}
}

html, body, #root {
    overflow: hidden;
}