.profile-email {

	.for-email {
		display: flex;
		outline: line;
		background: #EAFFFA;
		border-radius: 10px;
		margin-top: 0;
	    margin-bottom: 2px;

		li {
			text-align: left;
		}

		li.e-act {
			width: 10% !important;

			.checkbox-container-2 {
			    position: relative;
			    top: -4px;
			}

			svg {
				margin-left: 1em;
			    transform: scale(1.5);
			}
		}
		li.e-name {
			width: 15% !important;
			font-family: Poppins;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 21px;
			color: #000000;
			justify-content: end;
		}
		li.e-mail {
			width: 65% !important;
			font-family: Poppins;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 21px;
			color: #A09F9F;
			justify-content: end;
		}
		li.e-time {
			font-family: Poppins;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 21px;
			text-align: right;
			color: #A09F9F;
		}
	}
	
}