#depo-container {
	padding: 2em !important;
	width: 100%;
	position: relative;
    height: calc(100% - 120px);
    display: flex;


	.depo-section-right {
		width: 100%;

		&.shared {
			width: calc(100% - 342px);
			margin-left: 342px;
		}

		.pagination-div {
		    justify-content: center;
		    margin-top: 1em;
		}

		.table-header, .table-body {
			li {
				width: calc(100% / 8) !important;
			}
		}
	}
}

html, body, #root {
    overflow: hidden;
}