.profile-accounts {

	div.acc-div {
		display: flex;
		justify-content: flex-end;
	    padding-right: 1em;

		.add-acc {
			width: 150px;
		    height: 40px;
		    color: #fff;
		    border: 0;
		    background: #03CF9E;
		    border-radius: 2px 0px 0px 2px;
				position: relative;
				display: inline-flex;
				align-items: center;
				justify-content: center;

				img {
					position: relative;
    			margin-right: 5px;
				}
		}
	}

	.tb-del {
		position: relative;
		left: 10px;
		top: 5px;
		cursor: pointer;
	}
}
