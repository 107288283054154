.nav-link {
  display: flex;
  margin-top: 10px;
  padding: 10px 15px;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
  }

  p {
    margin-left: 20px;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    align-self: center !important;
    color: #fff;
  }
}

.nav-link:hover {
  cursor: pointer;
  background-color: #004044;
}

.l-active {
  border-left: 3px solid #03CF9E;
  background-color: #004044;
}