.search-container {
  width: 290px;
  height: 30px;
  position: relative;
  background-color: inherit;
  border: 2px solid #03CF9E;
  border-radius: 5px;
  font-family: Poppins, sans-serif;

  img {
    position: absolute;
    right: 10px;
    top: 6px;

    width: 15px;
    height: 15px;
  }

  input {
    background-color: inherit;
    border: none;
    height: 100%;
    color: #BAFFEE !important;
    font-size: 12px;
  }

  input::placeholder {
    color: #BAFFEE !important;
  }
}

@media screen and (max-width: 600px) {
  .search-container {
    width: 250px;
  }
}