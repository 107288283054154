@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");

.verification-image {
  width: 250px;
  height: 250px;
  border-bottom: 4px solid #cccccc;

  img {
    width: 100%;
    height: 100%;
  }

  margin: 10px auto 20px auto;
}

.verification-form {
  width: 336px;
  margin: 0px auto 10px auto;

  .input-boxes {
    width: 270px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    input[type="text"] {
      width: 60px;
      height: 60px;
      text-align: center;
      font-size: 14px !important;
      border: 2px solid #1fcf65 !important;
    }

    input:focus {
      outline: 0;
      border: 2px solid #1fcf65 !important;
    }
  }

  .error {
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    color: #FF3002;;
    margin-top: 15px;
  }
}

.verify-button-container {
  width: 270px;
  margin: 15px auto 0 auto;
}

.verification-submit-btn {
  background-color: #1fcf65;
  border: none;
  color: white;
  font-size: 14px;
  cursor: pointer;
  height: 40px;
}

.verification-text {
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
}

@media screen and (max-width: 600px) {
  .verification-image {
    width: 300px;
    margin: 30px auto 50px auto;
  }

  .verification-form {
    width: 300px;
  }
}