.trade-not-found-box {
  width: 300px;
  margin: 20px auto 20px auto;

  p {
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 18px;

    color: #03cf9e;
  }

  img {
    width: 100%;
    height: 100px;
    margin: 10px auto;
  }
}
