.borderad-menu {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  list-style: none;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 30px;
  height: 60px;

  .down-icon {
    cursor: pointer;
  }

  li {
    height: 60px;
    width: 150px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 33px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: #FFFFFF;
    cursor: pointer;

    &.tr {
      width: auto;
      padding: 0 1.5em;
      font-size: 16px;
      line-height: 27px;
    }

    &._active {
      width: 180px;
      background: #006066;
      border-radius: 30px;
      // transform: matrix(-1, 0, 0, 1, 0, 0);
    }
  }
}


div.datepicker {
  color: #393939 !important;
  font-family: Poppins !important;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 225px !important;
}

.del-icon.df {
  width: 35px;
  height: 35px;
  position: relative;
  top: 2px;
  left: 10px;
  opacity: 0.65;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.bt-i {
  top: 2px;
  left: 2px;
  width: 24px;
  height: 16px;
  display: inline;
  position: relative;
}

.table-filters {
    display: flex;
    height: 50px;
    margin-top: 10px;
    position: relative;

    .breadcrumbs {
      margin-top: -2em;
    }
    .check-row {
      top: 10px;
      position: relative;

      .checkbox-container .checkmark {
        border-width: 1px;
      }
    }

    .gr-dropdown {
      display: none;
      position: absolute;
      top: 40px;
      z-index: 1000000000000;
      text-align: left;
      width: 180px;
      height: auto;
      background: #010101;
      color: #fff;
      border: 0;

      &._active {
        display: block;
        position: absolute;
        z-index: 100000000000000;
        width: 100%;

      }
    }

    .gr-dropdown span {
      padding: 10px;
      display: block;
      font-weight: normal;
      font-size: 80%;
      cursor: pointer;
      cursor: pointer;
      border-bottom: 1px solid #ffa500;
    }

    .gr-dropdown span:hover {
      background: #ffa500;
    }

    .gr-dropdown span._active {
        background: #ffa500;
    }

    .gr-dropdown span img {
      float: left;
      width: 20px;
      height: auto;
      margin-right: 1em;
    }
    small {
      position: absolute;
      top: -6px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #C4C4C4;
      color: #393939;
    }

    .calendar-search, .filter-img {
      width: 30px;
      height: 30px;
      margin-left: 10px;
      margin-top: 5px;
      &.mini {
        width: 22px;
        height: 22px;
      }
    }

    .filter-img {
      margin-left: 1.2em;
    }

    .clear-btn {
      border: 0;
      background: none;
      outline: none;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      text-align: center;
      color: #393939;
      cursor: pointer;
      margin-right: 10px;
    }

    .filter-actions {
        display: inline-flex;
        justify-content: flex-end;
        width: 50%;
        position: absolute;
        right: 0;
        top: 0;

        &.tr {
          .search-container {
            width: 250px !important;
            margin-top: 12px;

            &.select-box {
              width: 80px !important;

              &.s20 {
                width: 200px !important;
              }
            }

            img {
              top: 11px !important;
            }
          }

          .filter-section {
              width: 130px !important;
              display: flex !important;
              border: 1px solid #393939 !important;
              border-radius: 5px !important;
              padding: 5px !important;
              height: 40px !important;
              // background: #393939 !important;
              margin: 12px 1em 0 10px !important;
          }

          .filter-section img {
            margin-top: 6px !important;
          }
        }
    }

    &.lab {
      padding-top: 1em;

      .filter-actions {
        bottom: -7px;
        top: unset;
      }
    }

    .create-btn {
      width: 130px;
      height: 40px;
      border: 0;
      outline: none;
      cursor: pointer;
      background: #393939;
      border-radius: 3px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #FFFFFF;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &.btn-alert {
        width: 180px !important;
        margin-left: 20px;
        color: #FFFFFF !important;
        background: #D14 !important;
      }
      &.btn-success {
        width: 180px !important;
        margin-left: 20px;
        color: #FFFFFF !important;
        background: #03cf9e !important;
      }
    }

    .fil-act-btn {
        margin: 0 10px;
        background: none;
        border: 0;
        outline: none !important;
    }

    .search-container {
      width: 101px;
      height: 40px;
      margin-right: 10px;
      position: relative;
      background-color: inherit;
      // background: #006066;
      // border: 1px solid #393939;
      background: #141517 !important;
      border: 1px solid #393939 !important;
      box-sizing: border-box;
      border-radius: 3px;
      position: relative;
      overflow: hidden;
      font-family: Poppins, sans-serif;


      img.search-img {
        position: absolute;
        right: 10px;
        top: 12px;
        width: 15px;
        height: 15px;
      }

      .input, input, select {
        background-color: inherit;
        border: 0;
        outline: none;
        padding-left: 20px;
        border: none;
        height: 100%;
        width: 100%;
        // color: #393939 !important;
        // color: #393939;
        color: #fff !important;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
      }

      select {
        width: calc(100% + 20px);
        background: none;

        option {
            background: #393939;
            color: #fff;

            &:hover, &:focus, &:active {
                background: #393939;
            }
        }
      }

      &.select-box:after {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #393939;;
        position: absolute;
        top: 40%;
        right: 5px;
        content: "";
        z-index: 98;
      }

      .input, input::placeholder {
        color: #fff !important;
      }
    }
}