#devfx-app {

    .container {
        max-width: 100vw;
        width: 100vw;
        padding: 0px;

        .my-cards {
          display: flex;
          width: 100%;
          flex-direction: row;
        }

        .ast {
            margin-left: 3px;
            margin-right: 3px;
        }

        .profile-page {
            display: flex;
            padding: 0px;
            padding-bottom: 50px;
            flex-direction: row;

            input[type='file'] {
                display: none;
            }

            .errorMessage, .success-message {
                background: transparent;
                position: relative;
                top: 14px;
            }

            .success-message {
                top: 6px;
            }

            #main-body, #right-side-menu {
                min-height: calc(100vh - 93px);
                justify-content: center;
                display: inline-flex;
                position: relative;
                align-items: center;
                justify-content: flex-start;
                flex-direction: column;
            }

            #main-body {
                width: calc(100vw - 381px - 14px);
                justify-content: flex-start;
                align-items: flex-start;
                padding: 1.5em;

                .astt {
                    font-weight: 600;
                    font-size: 22px;
                    margin-bottom: 1em;
                    line-height: 28px;
                }

                .tool-i {
                    position: absolute;
                    right: 1em;
                    top: -1.2em;
                    cursor: pointer;
                    width: 38px;
                    height: 38px;
                }

                button.tool-i {
                    background: var(--custom_color);
                    // color: #ffffff;
                    color: var(--custom_alt_color);
                    border: none;
                    padding: 5px 12px;
                    cursor: pointer;
                    border-radius: 5px;
                    font-size: 15px;
                    width: auto;
                }

                .nbw {
                    display: flex;
                    width: 100%;
                    padding: 1em;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    font-size: 14px;
                    background: rgba(255, 255, 255, 0.1);
                    border: 2px solid var(--custom_color);
                    box-sizing: border-box;
                    border-radius: 3px;
                }

                .row.v {
                    width: calc(100% + 20px);
                    .col {
                        border: 1px solid #3B3B3B;
                        box-sizing: border-box;
                        border-radius: 8px;
                        padding: 10px;
                        height: 135px;
                        margin: 10px;
                        display: inline-flex;
                        flex-direction: column;

                        span {
                            display: flex;
                            max-width: 100%;
                            text-align: left;
                            justify-content: flex-start;
                            // justify-content: center;
                            img {
                                margin-right: 8px;
                                height: 20px;
                                width: 20px;
                            }
                            b {
                                font-style: normal;
                                font-weight: 200;
                                font-size: 12px;
                                line-height: 18px;
                            }
                        }

                        .btn {
                            width: 100%;
                            height: 80px;
                            background: rgba(255, 255, 255, 0.1);
                            border-radius: 8px;
                            color: var(--custom_color);
                            outline: none !important;
                            box-shadow: none !important;
                            display: inline-flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-top: 10px;
                            // padding-top: 15px;
                            flex-direction: column;

                            span {
                                color: inherit;
                            }

                            .upv {
                                margin-top: 10px;
                                height: 24px;
                                width: 24px;
                            }

                            &.s {
                                background: rgba(255, 255, 255, 0.1);
                                justify-content: center;

                                .upv {
                                    margin-top: -5px !important;
                                    display: none;
                                }

                                span {
                                    color: #fff;
                                }
                            }

                            .force {
                                display: flex !important;
                            }
                        }
                    }
                }

                .dform {
                    width: calc(100%);
                }

                hr {
                    display: flex;
                    width: 100%;
                    background: #3C3C3C;
                    margin: 1.5em 0;
                }

                .row.c {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    padding-left: 0px;
                    list-style: none;

                    li {
                        width: auto;
                        padding: 0px;
                        display: inline-flex;
                    }
                    .cc {
                        width: 290px;
                    }
                }
            }

            #right-side-menu {
                border-left: 1px solid #3C3C3C;
                width: 380px;
                padding: 50px 0 20px;

                .small-overlay {
                    position: absolute !important;
                    border-radius: 50%;
                    z-index: 10;
                    width: 100%;
                    height: 240px;
                    margin: 0 auto;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: inherit;
                    z-index: 2;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                
                    .small-spinner {
                        position: relative;
                        pointer-events: none;
                        width: 1.2em;
                        height: 1.2em;
                        border: 5px solid transparent;
                        border-color: #fff;
                        border-top-color: var(--custom_color);
                        border-radius: 50%;
                        animation: spin 1s linear infinite;
                        z-index: 4;
                    }
                }

                .avatar {
                    width: 125px;
                    height: 125px;
                }

                .upd {
                    position: absolute;
                    z-index: 200;
                    top: 135px;
                    right: 130px;
                    height: 40px;
                    width: 40px;
                    cursor: pointer;
                }

                .p-name {
                    font-size: 24px;
                    font-weight: bold;
                    display: flex;
                    margin-top: 1em;
                }

                .acc-id {
                    background: #9108A7;
                    border-radius: 24px;
                    font-size: 14px;
                    padding: 4px 8px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 5px;
                }

                .dform {
                    width: calc(100% - 40px);
                    .form-group {
                        margin-bottom: 0px !important;
                        display: block;
                        height: 70px;
                        input, .app-btn {
                            height: 38px;
                        }
                    }
                }
                
                .block {
                    display: flex;
                    width: calc(100% - 2rem);
                    border: 1px solid #3C3C3C;
                    flex-direction: column;
                    padding: 1em;
                    margin: 1em 0 0;

                    ul {
                        display: flex;
                        padding-left: 0px;
                        margin-bottom: 0px;
                        flex-direction: column;

                        li {
                            display: inline-flex;
                            margin-bottom: 15px;
                            justify-content: space-between;

                            b {
                                font-size: 14px;
                            }

                            input {
                                background-color: transparent;
                                width: 202px;
                                text-align: right;
                                border: 0px !important;
                                box-shadow: none !important;
                                outline: none !important;
                                font-size: 14px;
                            }
                        }
                    }
                }

                .ed {
                    position: relative;
                    top: -2px;
                    margin-left: 10px;
                }
            }
        }
    }
}