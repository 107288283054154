html, body {
  background-color: #000000;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  * {
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    box-sizing: border-box;
  }

  #App {
    // height: auto !important;
    background: #141517;
  }
  
  .App { text-align: center; }
  
  .App-logo {
    // width: 132px;
    height: 50px;
    top: 28px;
    left: 39px;
    position: absolute;
  }
  
  .App-body {
    background-color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    
    &.outside {
      min-height: 100vh;
      padding-top: 100px;
    }
  
    &.active {
        background-color: #141517;
    }
  }
  
  .app-btn {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-size: 16px;
    color: var(--custom_alt_color) !important;
    background-color: var(--custom_color) !important;
    border-color: var(--custom_color) !important;
  }

  .page-title {
    margin-top: 0px !important;
  }

  .form-control, .form-control:focus {
    box-shadow: none !important;
    border-color: inherit;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: #141517;
    border-radius: 1em;
  }

  ::-webkit-scrollbar-track {
    background: #141517 !important;
    border-radius: 1em;
  }

  ::-webkit-scrollbar-thumb {
    cursor: pointer;
    background: #555 !important;
    border-radius: 1em;
  }
  *, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
  }
  
  html, body, #root {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    // background-color: #ffffff;
  }
  
  a, a:hover {
    text-decoration: none !important;
    text-decoration-color: white;
  }

  select {
    * {
      background-color: #141517;
    }
  }