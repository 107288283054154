#devfx-app {

    .container {
        max-width: 100vw;
        width: 100vw;
        padding: 0px;

        .page-title {
            border-bottom: 0px;
        }

        .acc-page {
            padding: 2em;
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 1em;

            table {
                // margin: 0;
            }

            .h-title {
                display: flex;
                line-height: 20px;
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 0px;
                padding-left: 1em;
                align-items: flex-start;
                justify-content: space-between;

                span {
                    font-size: 25px;
                }

                .add-acc {
                    width: 130px;
                    height: 40px;
                    position: relative;
                    top: -5px;
                }

                .form-control {
                    margin-left: 50px;
                    width: 80px;
                    position: relative;
                    top: -8px;
                    color: #fff;
                    background: transparent;
                }
            }

            .table-responsive {
                width: 100%;
                // margin-top: 1em;
            }

            table, thead, tbody, tr {
                display: flex;
                width: 100%;
            }

            table, thead, tbody {
                flex-direction: column;
            }

            tbody {
                border-bottom: 1px solid #3C3C3C !important;
            }

            td, th {
                text-align: left;
                font-size: 14px;
                width: calc(100% / 12);
                border: 0 !important;
                height: 40px;

                &.icent {
                    text-align: center !important;
                }
            }

            th {
                color: #B5B5B5;
                font-size: 10px;
            }
            
            th {
                display: inline-flex;
                justify-content: flex-start;
                align-items: center;
                border-top: 1px solid #3C3C3C !important;
                border-bottom: 1px solid #3C3C3C !important;
            }

            td.m2, th.m2 {
                min-width: 130px !important;
            }
            .dp-btn {
                display: inline-flex;
                height: 25px;
                width: auto;
                font-size: 10px;
                padding: 5px 10px;
            }
            .att {
                background: var(--custom_color);
                color: var(--custom_alt_color);
                border-radius: 24px;
                font-size: 10px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                position: relative;
                cursor: pointer;
                width: 45px;
                height: 18px;

                &.demo {
                    background: #008EDE;
                }
            }
        
            @media only screen and (max-width: 1500px) {
                th, td {
                    font-size: 10px !important;
                }
            }
        }
    }
}