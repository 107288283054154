.table-header, .table-body {
	margin-top: 1.5em;
	margin-bottom: 1em;
    display: flex;
    justify-content: space-between;
    list-style: none;
    background: #004044;
    border-radius: 5px 5px 0px 0px;
    color: #ffffff;
    font-weight: 100;
    font-size: 12px;
    text-align: center;
    background: #006066;
    border-radius: 10px;
    padding: 1em;
    width: 100%;
    position: relative;

	.check-row {
		position: absolute;
		left: 1.5em;
	    top: 9px;
		width: 50px;
	}

    li {
		display: flex;
	    flex-direction: row;
	    align-items: center;
	    text-align: left;
	    padding-left: 1em;
	    justify-content: center;
	    font-style: normal;
	    font-weight: 500;
	    font-size: 14px;
	    line-height: 16px;
	    font-family: 'Roboto';
	    color: #FFFFFF;

		.checkbox-container {
			position: relative;
		}
    }

    li:first-child {
		padding-left: 3em !important;
	}

	&.for-acc {
		text-align: left;
		li {
			min-width: 20%;
			text-align: left;
			padding-left: 0 !important;
		}
	}
 }

.table-body {
	height: 70px;
	background: #002F32;
	border: 1px solid #006066;
	box-sizing: border-box;
	border-radius: 10px;
	margin-top: 5px;
	margin-bottom: 0;

	.check-row {
	    top: 32%;
	}

	.flag {
		display: inline-block;
		width: 20px;
		height: 20px;
		position: relative;
		top: -2px;
		margin-right: 5px;
	}

	.tb-action {
		margin: 0 .5em;
    cursor: pointer;
	}
}

.table-body.for-acc {

    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0;
    list-style: none;
    font-size: 12px;
    color: #ffffff;
    margin-bottom: 2px;
    text-align: center;
    font-weight: 100;
    background: #002F32;
    border: 1px solid #006066;
    border-radius: 10px;
    padding: 1em;
    position: relative;

    &.ait {
      background: #002F49;
    }

    li {
      width: calc(50% / 4);
      display: flex;
      flex-direction: column;
      align-items: left;
      text-align: left;
      padding-left: 20px;
      justify-content: center;
    }

    li.acc-name {
    	padding-left: 90px !important;
    }

    img.ai_b {
      position: absolute;
      top: -2px;
      left: -16px;
      width: auto;
    }

    .capital {
		width: 180px;
		height: 30px;
		font-family: Poppins;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 30px;
		color: #FFFFFF;
    }

    .acc-gra {
    	margin-left: 1em;
	    position: relative;
	    top: 5px;
    }

    small.inf {
      color: #C4C4C4;
      float: left;
    }

    .o-price .td {
      color: #33b4fd;
    }

    .o-rate .td {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      align-items: center;
      color: #FFE602;
    }

    .c-rate .td {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      align-items: center;
      color: #78FF56;
    }

    .d-sell .td {
      color: #FF2E53;
    }

    .profit .td {
      font-weight: bold;
    }

    .acc-name .td {
      font-weight: bolder;
    }

    .deposit {
    	width: 80px;
		height: 30px;
		background: #03CF9E;
		border-radius: 2px;
		border: 0px;
		color: #fff;
		padding: 6px 13px;
    }

    .th {
      display: none;
    }

    .tab-sn {
      display: none;
    }

    .small-trans {
      width: 50px;
    }

    .t-to {
      font-size: 11px;
    }

    .t-from {
      font-size: 11px;
    }

    .trans-date {
      width: 100px;
      font-size: 12px;
    }

    .trans-type {
      width: 90px;
      border-radius: 20px;
      text-transform: uppercase;
      height: 40px;
    }

    .transfer {
      background: #DC02FF;
    }

    .withdraw {
      background: #FF5D02;
    }

    .ttype {
      width: 114px;
      height: 30px;
      border: 0px solid;
      background: #FFE602;
      border-radius: 50px;
      color: #FFF;
    }



	button.acc_type, .arr_down {
		width: 50px;
		height: 20px;
		position: absolute;
		border: 0px;
		left: 2em;
		color: #fff;
		font-size: 12px;
		top: 18px;
		background: #008EDE;
		border-radius: 40px;
	}

	button.acc_type.live {
		background: #03CF9E;
	}

	.arr_down {
		background: none;
	}
}

.table-header.for-payment {
	li {
		text-align: left;
		width: calc(100% / 7);
	}
}

.table-header.for-meetings, .table-body.for-meetings {
	li {
		text-align: left;
	    justify-content: flex-start;
		width: calc(100% / 7);
		
		&.len {
			min-width: 180px !important;
		}

		.v-all {
		    position: absolute;
		    bottom: 10px;
		    font-family: Poppins;
			font-style: italic;
			font-weight: 300;
			font-size: 12px;
			line-height: 18px;
		}
	}
}

.table-header.for-tasks, .table-body.for-tasks {
	li {
		text-align: left;
	    justify-content: flex-start;
		width: calc(100% / 6);
		
		&.len {
			min-width: 180px !important;
		}

		&.short {
			width: 150px !important;

      &.short2 {
        width: 200px !important;
        display: inline-flex;
        flex-direction: inherit;
        justify-content: flex-end;
      }

			img, svg {
				margin-left: 10px;
			}
		}
	}
}


.table-header.for-calls, .table-body.for-calls {
  li {
    text-align: left;
      justify-content: flex-start;
    width: calc(100% / 7);
    
    &.len {
      min-width: 180px !important;
    }

    &.short {
      width: 150px !important;

      img, svg {
        margin-left: 10px;
      }
    }
  }
}

b.pmore {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 25px;
  height: 25px;
  background: #171717;
  font-size: 1em;
  font-family: 'Poppins';
  cursor: pointer;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.9);
  border-radius: 50%;
  font-weight: 900;
}

.table-header.for-visits,
.table-body.for-visits {
  position: relative;

  li {
    text-align: left;
    justify-content: flex-start;
    width: calc(100% / 5) !important;
  }

  &.not-master {
    display: none !important;
  }

  height: 45px;
  background: #002F32;
  border: 1px solid #006066;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 3px;
  margin-bottom: 0;
  
  // &.len {
  //   min-width: 180px !important;
  // }

  &.short {
    width: 150px !important;

    img, svg {
      margin-left: 10px;
    }
  }
}

.table-header.for-trading {
    position: relative;
    width: calc(100% - 24px);
    margin: 2px 12px;
}

.table-body.for-trading {
    display: flex;
    justify-content: space-between;
    list-style: none;
    font-size: 12px;
    color: #ffffff;
    margin-bottom: 2px;
    text-align: center;
    font-weight: 100;
    background: #002F32;
    border: 1px solid #006066;
    border-radius: 10px;
    padding: 1em 0;
    justify-content: space-between;
    position: relative;
    width: calc(100% - 24px);
    margin: 2px 12px;

    &.ait {
      background: #002F49;
    }

    img.ai_b {
      position: absolute;
      top: -2px;
      left: -16px;
      width: auto;
    }

    small.time {
      color: #d78f04;
      float: left;
    }

    .o-price .td {
      color: #33b4fd;
    }

    .o-rate .td {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      align-items: center;
      color: #FFE602;
    }

    .c-rate .td {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      align-items: center;
      color: #78FF56;
    }

    .d-sell .td {
      color: #FF2E53;
    }

    .profit .td {
      font-weight: bold;
    }

    .ins-name .td {
      font-weight: bolder;
    }

    .close-trade {
      background: #03CF9E;
      border-radius: 2px;
      border: 0px;
      color: #fff;
      padding: 6px 13px;
      border-radius: 4px;
    }
    
    li {
      width: calc(100% / 11);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .th {
      display: none;
    }

    .tab-sn {
      display: none;
    }
}

.table-header.for-trading {
    padding: 1em 0;
}

.table-header.for-trading, .table-body.for-trading {
	li {
		text-align: left;
	    justify-content: flex-start;
		width: calc(100% / 11);
		align-items: flex-start;
		&.leni {
			min-width: 130px !important;
		}
		&.lenx {
			min-width: 110px !important;
		}
		&.len {
			min-width: 115px !important;
		}
		&.short {
			width: 70px !important;
		}
	}
}


.table-body.for-payment {
	li {
		text-align: left;
		width: calc(100% / 7);
	}

    .trans-date {
      font-size: 12px;
    }

    .ttype {
      width: 114px;
      height: 30px;
      border: 0px solid;
      background: #FFE602;
      border-radius: 50px;
      color: #FFF;
    }

    .transfer {
      background: #DC02FF;
    }

    .withdrawal {
      background: #FF5D02;
    }
}

.table-body.for-pay {

    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0;
    margin-bottom: 50px;
    padding: 0;
    list-style: none;
    font-size: 12px;
    color: #ffffff;
    margin-bottom: 15px;
    text-align: center;
    font-weight: 100;
    
    li {
      width: calc(100% / 7);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .th {
      display: none;
    }

    .tab-sn {
      display: none;
    }

    .small-trans {
      width: 50px;
    }

    .t-to {
      font-size: 11px;
    }

    .t-from {
      font-size: 11px;
    }

    .trans-date {
      width: 100px;
      font-size: 12px;
    }

    .trans-type {
      width: 90px;
      border-radius: 20px;
      text-transform: uppercase;
      height: 40px;
    }

    .transfer {
      background: #DC02FF;
    }

    .deposit {
      background: #FFE602;
    }

    .withdraw {
      background: #FF5D02;
    }

    .ttype {
      width: 114px;
      height: 30px;
      border: 0px solid;
      background: #FFE602;
      border-radius: 50px;
      color: #FFF;
    }
}

.cent li {
  justify-content: center !important;
}