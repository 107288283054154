.new-debit-card {
  width: 260px;
  padding: 10px;
  margin-right: 1em;
  position: relative;
  border-radius: 1em;
  box-shadow: 1px 1px 2px #ffa500;
  background: linear-gradient(145deg, #69696a, transparent);

  img {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  button {
    background: #655c4d;
    border-radius: 6px !important;
    color: #ffa500;
    font-size: 11px;
    border: 1px solid #ffa500;
    padding: 3px 6px;
  }

  h3 {
    font-size: 17px;
    color: #FFFFFF;
    font-weight: 300;
    margin-top: 50px;
    text-align: left;
  }

  .fut {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    span {
      font-size: 14px;
      color: #afafaf;
    }
  }

  .sm {
    position: absolute;
    left: 10px;
    top: 40px;
  }
  .cvv {
    position: absolute;
    right: 10px;
    top: 42px;
    font-size: 15px;
    color: #979797;
  }
}

.exp-info {
  display: flex;
  align-items: center;

  p {
    font-size: 10px;
    color: #FFFFFF;
  }

  .expiry-date {
    margin-left: 10px;
  }
}

.debit-card:not(:first-of-type) {
  margin-left: 2%;
}

@media screen and (max-width: 900px) {
  .debit-card {
    width: 70%;
    margin-top: 20px;
  }

  .debit-card:not(:first-of-type) {
    margin-left: 0;
  }
}