.imobile {
    display: none !important;
}
    
// html, body, #root, .App-body.inside {
//     min-width: 1250px !important;
//     width: 1250px !important;
//     overflow: auto;
// }

@media only screen and (max-height: 750px) and (min-width: 769px) {
    #devfx-app .overlay.fst.wel {
        z-index: 1000000000000000;
        overflow: auto;
        display: block;
        overflow-x: hidden;

        .s-reg:not(.noab) {
            position: absolute !important;
            margin: 0 auto !important;

            &.w1 {
                top: 20%;
                left: calc((100% - 550px) / 2);
            }

            &.w2 {
                top: 10px;
                left: calc((100% - 440px) / 2);
                &.que {
                    top: 20%;
                }
            }
            
            &.w3 {
                top: 10px;
                left: calc((100% - 790px) / 2);
            }
        }
    }
}

@media only screen and (max-height: 730px) and (min-width: 768px) {
    .chart, .hr-resizable {
        height: calc(100vh - 465px);
        // height: calc(100vh - 465px) !important;
    }
    
    .trade-history {
        .table-responsive {
            height: 102px !important;
            &.isa {
                height: 162px !important;
            }
        }

        &.exit {
            .table-responsive {
                height: calc(100vh - 65px) !important;
            }
        }
    }
}

@media only screen and (max-width: 1250px) {
    .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
        margin-left: 0px !important;
    }

    .App-body.inside {
        min-width: 1250px;
        .container {
            min-width: 1250px;
        }
    }
    #devfx-app .container .trade-page #trade-body {
        width: calc(1250px - 331px - 5px);
    }
    #devfx-app .container .trade-page .page-header {
        width: calc(1250px - 331px - 5px - 200px) !important;
    }
}
        
@media only screen and (max-width: 1400px) {
    .page-header {
        * {
            font-size: 80%;
        }
    }
    .trans.dep {
        margin-right: 20px !important;
    }
    #devfx-app header .a-left .tpr > *, #devfx-app header .a-right .tpr > * {
        margin-right: 0px !important;
    }
    .graftype {
        top: 0px !important;
    }
}

.pro-flex {
    width: 100%;
    display: flex;
    padding-top: 20px;
    align-items: flex-start;
    flex-direction: column;
}

@media only screen and (max-width: 768px) {

    .pro-active {
        display: flex !important;
    }

    .pro-inactive {
        display: none !important;
    }

    .profile-page #main-body {
        justify-content: flex-start;
        align-items: flex-start;
        display: none !important;

        &.sh {
            width: 100% !important;
            display: flex !important;
        }

        // .row.v.r {
            .col {
                flex: unset !important;
            }
        // }

        .form-group {
            width: 100% !important;
        }

        .tool-i {
            right: -5px !important;
            top: -60px !important;
        }
        
        .my-cards {
            display: flex;
            width: 100%;
            align-items: center;
            flex-direction: column;

            .new-debit-card {
                margin-bottom: 1em;
            }
        }
    }

    #devfx-app .container .profile-page #right-side-menu {
        width: 100% !important;
    }

    .App-body.inside {
        min-width: 100%;
        .container {
            min-width: 100%;
        }
    }
    .page-header {
        * {
            font-size: inherit;
        }
    }
    .trans.dep {
        margin-right: 0px !important;
    }
    #devfx-app .container .trade-page #trade-body {
        width: calc(100vw - 331px - 5px);
    }
    #devfx-app .container .trade-page .page-header {
        width: calc(100vw - 331px - 5px - 200px) !important;
    }

    #devfx-app .s-reg .blok.cl {
        .blok.cl {
            padding: 0px !important;
        }
    }

    #devfx-app .s-reg.w2 .form-group:not(.fupdl) label {
        padding: 0px 2px !important;
        left: 10px !important;
        font-size: 10px;
    }

    #devfx-app .s-reg.w2 .iblk ul li b, #devfx-app .s-reg.w3 .iblk ul li b {
        text-align: left;
    }

    #devfx-app .s-reg.w2 .iblk ul li b, #devfx-app .s-reg.w3 .iblk ul li b, #devfx-app .s-reg.w2 .iblk ul li input, #devfx-app .s-reg.w3 .iblk ul li input {
        font-size: 10px !important;
        min-width: 100px;
    }
    
    #devfx-app .full.ac {
        padding-top: 10px;
        .trader.ac {
            top: 12px;
            height: 32px;
            position: absolute;
        }
    }

    .s-reg.w1 {
        h1 {
            font-size: 1.5em;
        }

        h4 {
            font-size: 16px;
            color: #f0f0f0;
        }
        .bac {
            b {
                font-size: 24px;
            }
        }
    }

    .s-reg.w3 {
        .bac {
            b {
                font-size: 24px;
            }
        }
    }

    .blok.cl {
        h5 {
            font-size: 16px !important;
        }
    }

    #devfx-app .s-reg.w2 .form-group {
        padding: 5px !important;
    }

    #devfx-app .s-reg.w2 .iblk, #devfx-app .s-reg.w3 .iblk {
        margin: 5px;
        width: calc(100% - 10px);
    }

    #devfx-app .s-reg.w2, #devfx-app .s-reg.w3 {
        margin-top: 25px;
    }
    
    #devfx-app .overlay.fst {
        top: 0px;
        overflow: auto;
        display: flex;
        margin-bottom: 50px;
        padding-bottom: 50px;
        justify-content: flex-start;
        align-items: center;
        min-height: 100vh;
        background-color: #000;

        .s-reg {
            background-color: #000;
            .form-group:not(.fupdl) label {
                background-color: #000;
            }
            .form-group select {
                background-color: #000;
            }
        }

        .s-reg:not(.noab) {
            min-height: 100vh;
            margin-top: 0px !important;

            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    #devfx-app .s-reg .pbar-x {
        display: flex;
        width: 100%;
        position: initial !important;
        margin-bottom: 5px;
        transition: 400ms ease all;
        height: 40px;
        border-top: 1px solid #dddddd3b !important;
        padding-top: 10px;
        margin-top: -15px;
        justify-content: space-between;
    }

    #devfx-app .s-reg .pbar-w {
        position: initial !important;
    }

    // #devfx-app .overlay.fst {
    //     overflow: auto;
    // }

    #devfx-app .container.welcome .transaction-page {
        width: 100% !important;
        padding: 0px !important;
        margin: 0px !important;

        .rw.row-3 {
            width: 100% !important;
            padding: 0px !important;
            margin: 0px !important;
            overflow: hidden;

            .full {
                .form-group:not(.fga) {
                    padding: 5px 3px !important;
                }

                .next.contid.bo {
                    width: 100% !important;
                }
            }
        }
    }

    #devfx-app .container .acc-ft .fga {
        position: relative !important;
        padding: 0px !important;
    }

    #devfx-app .s-reg.w2 .next {
        width: 100% !important;
    }

    #devfx-app .s-reg .blok.cl .row.b {
        margin-bottom: 0px !important;
    }

    #devfx-app .s-reg.w2 .form-group select {
        background-color: #141517;
    }

    // .s-reg {
    //     .blok.cl {
    //         .app-btn.next {
    //             width: 100% !important;
    //         }
    //     }
    // }

    .App-body {
        background-color: #141517;
        position: relative;
        // height: calc(100vh - 100px) !important;
        // min-height: calc(100vh - 100px) !important;
        
        &.inside {
            overflow: hidden !important;
        }

        &.login-page, &.register-page {
            justify-content: flex-start;
            .container {
                border-top: 1px solid #343435;
                padding-top: 10px;
            }
        }
    }

    #App {
        &::-webkit-scrollbar {
            width: 1px;
            height: 1px;
            background: #141517 !important;
            border-radius: 1em;
        }
        &::-webkit-scrollbar-thumb {
            cursor: pointer;
            background: #141517 !important;
            border-radius: 1em;
        }
    }

    #devfx-app #news-container-user #latnews li {
        width: 90% !important;
    }

    #devfx-app #news-container-user .news-section-left img.ifm {
        width: calc(100% - 2em) !important;
        float: none !important;
    }

    #devfx-app #news-container-user .news-section-left {
        width: calc(100% - 2em) !important;
        margin: 1em !important;
        padding: 5px !important;
    }

    #devfx-app #news-container-user .news-section-left .table-filters .filter-actions {
        width: calc(100% - 50px) !important;
        right: unset !important;
        left: 15px !important;

        .search-container {
            width: 100% !important;
        }
    }

    #devfx-app #news-container-user .news-section-left .cancel {
        position: absolute;
        top: 18px !important;
        right: 9px !important;
        // border-radius: 50%;
        // background: #3b3b3b;
        padding: 5px;
        cursor: pointer;
        // border: 1px solid #000;
    }

    #devfx-app #news-container-user h1.news-header {
        font-size: 17px !important;
        line-height: 28px !important;
        padding-left: 10px;
        margin: 0 15px;
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: #00000059;
        border-left: 5px solid #ffa500;
        border-right: 5px solid #ffa500;
        text-decoration: none !important;
    }

    #devfx-app .container .transaction-page .rw.row-3 {
        width: 100% !important;
        border: 0px !important;
        padding: 15px !important;
        margin: 15px !important;
    }

    #app-menu-overlay {
        top: 0px !important;
        z-index: 100000000000000 !important;
        background: #0000009e !important;

        #app-menu {
            width: 70% !important;
            min-width: 250px !important;
            max-width: 350px !important;
            height: 100% !important;
            z-index: 100 !important;
            right: 0px !important;
            top: 0px !important;
            padding-top: 65px;
            display: flex !important;
            flex-direction: column !important;

            .csl {
                width: 25px;
                position: fixed;
                top: 20px;
                right: 20px;
            }

            .App-logo {
                height: auto;
                width: 50px;
                top: 10px;
                left: 15px;
                position: absolute;
            }

            .ml {
                height: 70px !important;
                width: 100% !important;
                padding-left: 50px !important;

                img {
                    left: 10px !important;
                    height: 30px !important;
                    width: 30px !important;
                    top: calc(50% - 15px) !important;
                }

                h4 {
                    font-size: 16px !important;
                }

                span {
                    font-size: 12px !important;
                }
            }
        }
    }

    #devfx-app header .a-left > *, #devfx-app header .a-left .tpr > *, #devfx-app header .a-right > *, #devfx-app header .a-right .tpr > * {
        margin-left: 0px;
    }

    #devfx-app header .a-left .tpr > *, #devfx-app header .a-right .tpr > *, .live-notice-ibox {
        margin-left: 12px !important;
    }
    #devfx-app header .a-right > * {
        margin-left: 1em !important;
    }

    #devfx-app header .a-left .tpr, #devfx-app header .a-right .tpr {
        margin-left: 12px !important;
    }
    
    .i-list li .i-act .buy-act, .i-list li .i-act .sell-act {
        width: 40% !important;
        height: 47px !important;
    }

    .i-list li .i-act .ait-act {
        width: 20% !important;
        height: 47px !important;
    }

    .b-tabs {
        display: flex;
    }

    .imobile {
        display: inline-flex !important;
    }

    .not-imobile {
        display: none !important;
    }

    .widep {
        width: calc(100% - 30px);
        display: flex;
        position: fixed;
        z-index: 100000;
        justify-content: space-between;
        flex-direction: row;
        left: 15px;
        bottom: 95px;

        a {
            width: 48% !important;
        }
    }

    img.fullscreen {
        height: 24px;
        margin-left: 10px;
    }

    .chart-section-top .chart-section-top-left ul {
        // justify-content: space-around !important;
        justify-content: space-between;
        padding: 0 15px;

        .gr-dropdown {
            top: 38px;
            background: #1e1f23;
        }

        li {
            margin-left: 0px;
            min-width: 70px !important;

            .t-arrow {
                right: 7px;
            }

            .grd {
                font-size: 12px;
            }
            &.l150 {
                width: 120px !important;
            }

            &.four._active .gr-dropdown {
                display: block;
                width: 120px;

                .cgt {
                    img {
                        width: 15px;
                    }
                    font-size: 12px;
                }
            }
        }
    }

    .chart {
        min-height: 100px;
        // height: calc(100vh - 440px - 80px);
        height: calc(100vh - 37px - 35px - 35px - 12px - 65px - 70px - 80px - 86px - 15px - 80px);
    }

    .chart-section-top-left.imobile {
        margin-bottom: 12px;
        flex-direction: row !important;
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 10px;

        &>* {
            display: inline-flex;
            height: 35px;
            align-items: center;
        }
        label {
            padding-top: 8px;
            font-size: 16px !important;
        }
    }

    .blue-select {
        width: auto !important;
    }

    .gr-hist-level.imobile {
        width: 100%;
        margin-top: 18px;
        margin-left: 0px;
        justify-content: space-around;

        .cgt {
            color: #666D8F;
            width: auto !important;
            align-items: center;
            font-size: 13px;

            &._active {
                color: #FFA500;
            }
        }
        
        .tz-name {
            position: relative;
            display: inline-flex;
            align-items: center;
            top: 0;
            img {
                height: 12px;
                width: 12px;
            }
            span {
                font-size: 12px;
                color: #fff;
            }
        }
    }

    #devfx-app .container .trade-page #trade-body, #devfx-app .container .trade-page #market-menu {
        min-height: auto;
        height: auto;
        overflow-x: hidden;
    }

    .acc-page {
        padding: 5px !important;
        .h-title {
            margin-top: 10px;
            flex-direction: column;
            justify-content: center !important;
            align-items: center !important;
        }
        .trade-history {
            margin-top: 1em;
        }
        .add-acc {
            font-size: 14px;
            border-radius: 2em;
        }
    }

    .overlay.fav .modal-section {
        max-height: 100%;
        height: 100%;
    }

    .overlay.fav .modal-section .bsell-modal {
        height: 99%;
        margin-top: 1% !important;
    }

    .switch-box .slider {
        background-color: #1FCF65;
    }

    .graftype > span.active {
        color: #1FCF65 !important;
    }

    .graftype {
        span {
            font-size: 12px;
        }
    }

    .chart-section {
        padding: 5px;
    }

    .gr-dropdown {
        right: 0;
        left: unset;
    }

    .apcc {
        display: flex;
        width: 100%;
        height: 65px;
        padding: 10px 15px;
        &.trn {
            margin-top: 10px !important;
        }

        .App-accs, .App-trds {
            cursor: pointer !important;
            width: 100% !important;
            left: 15px !important;
            margin-left: 0px !important;
            height: 48px !important;
            border: 1px solid #353945 !important;
            background: #1E1F23 !important;
            border-radius: 8px !important;

            &.trn {
                width: 93% !important;
                margin-left: 3% !important;
            }
        }
    }

    .container.transactions .page-title {
        padding: 15px 1em 0 !important;
    }

    #devfx-app .container .transaction-page .rw.row-3 {
        padding-top: 0px !important;
    }

    #devfx-app .container .transaction-page {
        padding: 0px;
        display: flex;
        position: relative;
        flex-direction: column !important;
        width: 93% !important;
    }

    .h-title {
        // font-size: 20px;
    }

    #devfx-app .container .transaction-page .rw {
        min-height: auto !important;
    }

    .tmob-hist {
        display: -webkit-box;
        list-style: none;
        margin-top: 20px;
        padding-bottom: 20px;
        padding-left: 0px;
        flex-direction: row;
        word-wrap: normal;
        position: relative;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;

        li {
            width: 300px;
            height: auto;
            margin-left: 15px;
            margin-right: 15px;
            background: #1E1F23;
            border-radius: 10px;
            padding: 10px 20px;
            flex-direction: column;
            border: 1px solid #3C3C3C;

            div {
                width: 100%;
                display: flex;
                flex-direction: row;
                margin-bottom: 7px;
                justify-content: space-between;
                h4 {
                    margin: 0px;
                }

                .lft {
                    * {
                        display: inline-flex;
                        justify-content: flex-start;
                    }
                }

                .rgt {
                    * {
                        display: inline-flex;
                        justify-content: flex-end;
                    }
                }
            }

            .coll {
                display: inline-flex;
                flex-direction: column;

                small {
                    font-size: 10px;
                    color: #98A9BC;
                }
            }
        }
        
                     
        &::-webkit-scrollbar {
            width: 8px;
            height: 1px;
            background: #000;
            border-radius: 1em;
        }

        &::-webkit-scrollbar-thumb {
            cursor: pointer;
            background: #000 !important;
            border-radius: 1em;
        }
    }

    .chart .zoom-controls {
        background: transparent !important;
        box-shadow: none !important;
        border: 1px solid transparent !important;
    }

    .favourites {
        border: 0px !important;
    }

    .a-right {
        .trans, .dropdown, .menu {
            display: none !important;
        }
    }

    #trade-body, 
    #devfx-app .container .trade-page .favourites, #devfx-app .container .trade-page .trade-comp-container {
        width: 100vw !important;
    }

    .trade-page.trade {
        #market-menu, .page-header.balance, .order-book, .trade-history {
            display: none !important;
        }
        .gr-dropdown span {
            text-align: center;
        }
    }

    .trade-page.wallet {
        #market-menu, .order-book, .trade-history, .trade-comp-container, .i-list {
            display: none !important;
        }

        .apcc {
            position: fixed;
            top: 70px;
        }

        #trade-body .page-header {
            top: 135px !important;
            width: 100% !important;
            height: calc(100vh - 270px) !important;
            border: 0px solid transparent !important;
            flex-direction: column !important;
        }

        #trade-body .page-header ul {
            width: 100%;
            padding-bottom: 25px !important;
            height: auto !important;
            padding-left: 5px !important;
            padding-right: 5px !important;
            flex-direction: column !important;

            &.fcl {
                flex-direction: row !important;
                justify-content: space-between;
                width: 100% !important;

                li {
                    img {
                        margin-bottom: 6px;
                    }

                    width: 47%;
                    justify-content: center;
                    align-items: flex-start;
                    padding: 10px 15px !important;
                    height: 90px;
                    border-radius: 10px;
                    margin: 0px !important;
                    background: #1E1F23;
                }
            }

            &.fcd {
                li {
                    span {
                        font-size: 14px;
                        color: #777E90;
                    }
                    img {
                        display: inline-flex;
                        margin-right: 10px;
                        width: 14px;
                    }
                    display: flex !important;
                    margin: 0px !important;
                    justify-content: space-between !important;
                    flex-direction: row !important;
                }
            }
        }
    }

    .trade-page.market {
        #trade-body, .page-header.balance, .order-book, .trade-history {
            display: none !important;
        }

        .apcc {
            position: fixed;
            top: 70px;
        }

        #market-menu {
            width: 100% !important;
            position: relative;
            padding-top: 70px;

            .bs-section {
                display: none !important;
            }
            
            .i-list {
                height: calc(100vh - 70px - 65px - 68px - 53px - 80px - 80px) !important;
            }
        }
    }

    .favourites {
        margin-top: 0px !important;
    }

    #market-menu .search-g input {
        height: 40px;
        border-radius: 16px !important;
    }

    .trade-history .cl-btn {
        height: 30px;
        background-color: #393939 !important;
        border: 1px solid #5B5B5B !important;
        border-radius: 2em !important;
    }

    .notification-dropdown2 .section2 ul {
        overflow: hidden !important;
    }

    .notification-dropdown2.mobile {
        top: 48px;
        max-height: calc(100% - 50px) !important;
        overflow: hidden;
    }

    .message-dropdown.mobile::before, .notification-dropdown2::before {
        display: none !important;
    }

    .trade-page.bsell {
        #trade-body, .page-header.balance, .trade-history {
            display: none !important;
        }

        #market-menu {
            width: 100% !important;
            position: relative;
            
            .block .ivlv {
                right: 39% !important;
            }

            .i-list, .search-g {
                display: none;
            }
            
            .block ul li {
                height: 22px !important;
            }
            .form-group.aamt {
                margin-bottom: 15px !important;
            }
            .bs-section {
                padding-top: 5px;
                height: auto !important;
            }

            .order-book {
                position: relative;
            }
        }
    }

    .trade-page.order {
        #market-menu, #trade-body, .page-header.balance {
            display: none !important;
        }
    }

    .sign-fill.nn, .sign-devfx.nn {
        display: none;
    }

    .sign-fill .card.s {
        height: auto !important;
    }

    .sign-fill .card .card-body {
        padding: 1rem 20px 1.5rem;
    }

    .r2 {
        display: flex !important;
        margin-top: 40px;
    }

    .lop {
        display: flex !important;
        font-weight: 700;
        font-size: 16px;
    }

    .sign-fill .card {
        border: 1px solid #343435;
    }
        
    .sign-head {
        font-weight: 600;
        font-size: 25px;
        line-height: 33px;
        text-align: center;
    }

    .bt-ctd {
        margin: 1em auto;
        width: 220px;
        display: block !important;
        background: transparent;
    }

    .sign-todo {
        padding-left: 10px;
        li h4 {
            font-size: 18px;
        }

        .txt-sm br {
            display: none;
        }
    }

    .bac {
        margin-left: 0px;
    }
}

@media only screen and (max-width: 440px) {
    #devfx-app .s-reg .pbar-w {
        margin: 10px;
        max-width: 100%;
        left: 0px !important;
        width: calc(100% - 20px);
    }

    #devfx-app .s-reg .pbar-x {
        margin-left: 10px;
        width: calc(100% - 20px);
    }
}



@media only screen and (max-width: 1400px) {

    .dash-container.admin {
        .container .trade-page #trade-body .page-header ul {
            height: 50px !important;

            li {
                justify-content: space-between !important;
                padding: 0px !important;
                // margin: 0px !important;

                span {
                    font-size: 10px;
                    margin-bottom: 0px !important;
                }

                b {
                    font-size: 12px !important;
                }
            }
        }
    }

}