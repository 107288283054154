.overlay.des {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 110;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1111111111111;
  
  span.err {
    margin: 0px;
    color: red;
    text-align: center !important;
    align-items: center;
    display: block;
    font-size: 1em;
    margin-bottom: -1em;
  }

  select * {
    background-color: #444;
  }
  
  .modal-section {
    z-index: 1111;
    min-height: 300px;
    width: 480px;
    overflow: auto;
    max-height: 98%;
    position: relative;

    .upper-modal {
      position: relative;
      width: 200px;
      margin: 0 auto;
      top: 4em;
      height: 6em;
      display: flex;
      padding: 10px 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #03cf9e;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    .bsell-modal {
      padding: 2em 3em;
      text-align: center;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-family: Poppins, sans-serif;
      border-radius: 0px;
      position: relative;
      width: 100%;
      background: #EAFFFA;


      .modal-cancel {
        cursor: pointer;
        position: absolute;
        top: 12px;
        right: 13px;
        border-radius: 50%;
      }

      .bsell-modal-content {
        width: 100%;
        h6 {
          font-family: Poppins;
          font-style: normal;
          text-align: left;
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          color: #03CF9E;
        }
        p {
          margin-top: 3em;
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          display: flex;
          text-align: left;
          align-items: center;
          color: #03CF9E;
        }

        p.btns {
          text-align: right;
          justify-content: right;
          display: flow-root;
          height: 45px;

          .continue, .switch {
            border: 1px solid #00A880;
            margin: 1em;
            color: #fff;
            width: 100px;
            height: 30px;
            outline: none;
            border-radius: 2px;
            background: #00A880;
          }

          .continue {
            color: #00A880;
            background: transparent;
          }

        }

        p.inps {
          display: block !important;
          .accs {
            width: 100%;
            height: 30px;
            background-color: inherit;
            color: #03cf9e;
            font-family: Poppins, sans-serif;
            height: 40px;
            border: 1px solid #03CF9E;
            border-radius: 3px;
            margin-bottom: 1em;
            padding-left: 1em;
            padding-right: 1em;
            display: block;
          }
          .sacc {
            margin-top: 2em;
            width: 100%;
            color: #fff;
            height: 40px;
            border: 1px solid #03CF9E;
            border-radius: 3px;
            background: #03CF9E;
            text-align: center;
            display: block;
          }
        }

      }
    }
  }

  button {
    cursor: pointer;
  }

  span {
    font-weight: 100;
  }

  .hide {
    display: none !important;
  }

}


@media screen and (max-width: 480px) {

  .overlay.fav {
    .modal-section {
      width: 98%;

      .bsell-modal {
        padding: 3em 15px;


        p.btns {
          text-align: right !important;
          text-align: center !important;
          justify-content: center !important;
          display: block !important;
          height: auto !important;
          position: relative !important;

          .continue, .switch {
            margin: .5em 1em !important;
            width: calc(100% - 4em) !important;
            height: 40px !important;
          }

          .continue {
          }
        }
      }
    }
  }

}