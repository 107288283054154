.credit-modal {
    width: 100% !important;
    background: #353535 !important;
    padding: 0px;
    border-radius: 10px;
    position: relative;

    .cctype {
        position: absolute;
        text-transform: uppercase;
        padding: 5px 10px;
        display: inline-flex;
        width: fit-content;
        color: #fff1f1;
        left: 12px;
        top: 9px;
        font-weight: bold;
        font-size: 14px;
    }

    .account-close-btn {
        position: absolute;
        right: 15px;
        cursor: pointer;
    }

    .ct {
        text-align: center;
    }

    .account-form-section {
        margin-bottom: 20px;
        width: 100%;

        label {
            color: #a09f9f;
            width: 100%;
            text-align: left;
            margin-bottom: 7px;
        }

        input {
            color: #444 !important;
            border: none;
            text-align: left;
            border-radius: 0px;
            height: 42px !important;
            // background-color: transparent !important;
            // border: 1px solid #fff !important;
        }

        &.cvv {
            // margin-top: 62px;
        }

        .cc-mm, .cc-yy, .cc-cvc {
            text-align: center;
        }

        &.sub {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            input {
                width: 65px;
                padding-right: 2px;
                padding-left: 2px;
            }

            span {
                display: inline-flex;
                width: auto;
                height: 100%;
                justify-content: center;
                align-items: center;
                padding-left: 5px;
                padding-right: 5px;
            }
        }
    }

    .continue {
        margin-top: 10px;
        border: none;
        background: #ffa500 !important;
        border-radius: 2px;
        width: 150px;
        height: 40px;
        color: #fff !important;
        font-family: Poppins, sans-serif;
        cursor: pointer;
    }

    .bgw {
        background-color: #fff !important;
        color: #444;
    }
    
    .account-flex-2 {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
}