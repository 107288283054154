.full-width {
	width: 100%;
}

#dash-container {
	width: 100%;
    // display: flex;
	position: relative;
	background: #001415;
	padding: 2em !important;
    height: calc(100% - 120px);

    .can-tab {
    	width: 100%;
    	display: flex;
    	color: #fff;
    	margin: 0 0 15px 0;
    	height: 50px;
		background: #006066;
		border-radius: 5px;

		ul {
			display: flex;
			flex-direction: row;
			width: auto;
		    list-style: none;
		    font-family: 'Poppins';
		    padding: 0 1em;
		    height: 100%;

		    li {
		    	display: inline-flex;
		    	justify-content: center;
		    	align-items: center;
		    	height: 100%;
		        width: auto;
		        cursor: pointer;
			    margin: 0 2em;
			    color: #A0A0A0;
			    padding-bottom: 4px;

			    &.active {
			    	color: #fff;
				    padding-bottom: 0px;
			    	border-bottom: 4px solid #00A880;
			    }
		    }
		}
    }

    .cdar {
    	position: absolute;
    	top: 15px;
    	right: 20px;
    	height: 30px;
    	width: 30px;
    	z-index: 1000000000;
    }

    #mcanvas {
    	width: 100%;
    	height: 305px;
    	overflow: hidden;
	    display: flex;
	    position: relative;
		background: #00606640;
    	border: 1px solid #004044;

    	&>div {
    		width: 100% !important;
    	}
    }

    #section-2 {
    	display: flex;
    	width: 100%;
    	justify-content: space-between;
    	margin-top: 3em;
    	height: 250px;
    }

    .mcan-1 {
	    height: 300px;
	    overflow: hidden;
	    position: relative;
	    display: inline-flex;
	    border-radius: 30px;
	    width: calc(100% - 610px);
	    border: 1px solid #004044;
    }

    .mcan-2 {
	    width: 600px;
	    height: 300px;
	    overflow: hidden;
	    display: inline-flex;
	    position: relative;
	    border-radius: 30px;
	    border: 1px solid #004044;


    	canvas {
		    top: -24px;
		    width: 657px !important;
		    position: relative;
		    left: -25%;
		    height: 100% !important;
    	}
    }

    #ooff {
	    width: 100% !important;
	    height: 100% !important;
    }

    #canvas {
    	position: relative;
	    top: -26px;
	    width: 100%;
	    height: calc(100% + 26px) !important;
    }
}