.sign-head {
    font-weight: 600;
    font-size: 42px;
    line-height: 63px;
    text-align: center;
}

.iti.iti--allow-dropdown {
    width: 100% !important;
}

#App {
    background: #000000;
}

// .iti.iti--container {
//     display: none;
// }

.crcp_14 {
    font-size: 14px;
    a {
        font-size: 14px;
        color: #fff !important;
        text-decoration: underline !important;
        &:hover {
            color: var(--custom_color) !important;
            text-decoration: underline !important;
        }
    }
}

.iti__country-list {
    background-color: #141517 !important;
    border: 1px solid #4A4A4B !important;
    * {
        color: #fff !important;
        font-size: 15px;
    }
}



._progress{
    position: relative;
    height: 14px;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 10px;
    border: 10px solid #000000;
    border-radius: 15px;
}
._progress .color{
    position: absolute;
    background-color: var(--custom_color);
    width: 0px;
    top: -4px;
    height: 8px;
    border-radius: 15px;
    animation: _progres 6s infinite linear;
}
._prepin {
    color: #747373;
    animation: _prepinn 2s infinite linear;
}

@keyframes _prepinn{
    0%{
        color: #747373;
    }
    70%{
        color: #fff;
    }
};
@keyframes _progres{
    0%{
      width: 0%;
    }
    25%{
        width: 50%;
    }
    50%{
        width: 75%;
    }
    75%{
        width: 85%;
    }
    100%{
        width: 100%;
    }
};

.bac {
    text-align: left;
    font-size: 22px;
    line-height: 35px;
    position: relative;
    display: inline-flex;
    margin-top: 15px !important;
    margin-left: 35px;
    position: relative;
    max-width: 535px !important;
    justify-content: space-between;

    b {
        font-weight: 600;
        font-size: 48px;
        position: relative;
        text-align: center;
        bottom: -15px;
        color: var(--custom_color);
    }
}

.error, .success {
    font-family: Poppins, sans-serif;
    color: #FF1E1E;
    text-align: center;
    font-size: 12px;
    margin-top: 10px;
  }
  
  .success {
    color: #03cf9e;
    font-weight: bold;
  }  

.lop {
    font-weight: 700;
    font-size: 23px;
    line-height: 34px;
    display: flex;
    cursor: pointer;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 12px;
    justify-content: center;
}

.sign-todo {
    list-style: none;
    padding-left: 75px;
    margin-top: 1.5em;
    display: flex;
    width: 90%;
    margin-left: 5%;
    flex-direction: column;

    li {
        position: relative;
        padding-top: 8px;
        padding-left: 2em;
        padding-bottom: 30px;
        display: inline-flex;
        border-left: 1px solid #fff;
        flex-direction: column;
        float: left;

        &:last-child {
            border-left: 2px solid transparent;
        }

        h4 {
            font-size: 24px;
            text-align: left;
            font-weight: 600;
        }

        .ind {
            position: absolute;
            left: -24px;
            top: 0px;
            cursor: pointer;
            width: 45px;
            height: 45px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border: 4px solid #fff;
            border-radius: 50%;
            background: #000;

            &.active {
                background-color: var(--custom_color);
                border-color: var(--custom_color);
                color: var(--custom_alt_color) !important;
            }
        }

        .txt-sm {
            color: #C9C9C9;
            text-align: left;
            font-size: 14px;
        }
    }
}

.bt-ctd, .lop2, .r2 {
    display: none !important;
}

.sign-devfx {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sign-fill {
    margin-bottom: 1em;
    // justify-content: flex-end;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &.center {
        align-items: center;
        justify-content: center;
    }

    .card {
        min-height: auto;
        background: #141517;
        border-radius: 12px;
        position: relative;
        overflow: hidden;
        max-width: 500px !important;
        width: 100%;

        &.s {
            min-height: 650px;
        }

        &.lgn {
            max-width: 450px !important;
        }

        .card-body {
            padding: 1rem 2.5rem 1.5rem;
        }

        .card-header {
            height: 60px;
            padding: 0 .8rem;
            background: #1E1F23;

            .row {
                height: 100%;

                .label {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    color: inherit;
                    text-decoration: none !important;
                    border-bottom: 4px solid transparent;

                    &.active {
                        color: var(--custom_color);
                        border-bottom: 4px solid var(--custom_color);
                    }
                }
            }
        }
    }
}

.dform {
    .form-group {
        margin-bottom: 5px;

        label {
            color: #B2B2B2;
            font-size: 10px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: -0.005em;
        }

        .form-control {
            border: 1px solid #4A4A4B;
            box-sizing: border-box;
            border-radius: 5px;
            height: 45px;
            color: #fff;
            background: transparent !important;
        }
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: all 5000s ease-in-out 0s;
}

.app-btn.blk {
    background: #444 !important;
    background-color: #444 !important;
    border-color: transparent !important;
}