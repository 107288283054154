.profile-sales {


	ul {
		display: block;
		list-style: none;
		padding-left: 0;
		width: 100%;

		li {
		    padding: 20px;
		    margin: 5px calc(10% / 6);
		    display: inline-flex;
		    flex-direction: row;
		    width: 30%;
		    height: 60px;
		    background: #002F32;
		    border: 1px solid #006066;
		    border-radius: 10px;
		    font-family: Poppins;
		    font-style: normal;
		    font-weight: 500;
		    font-size: 14px;
		    line-height: 21px;
		    align-items: center;
		    color: #FFFFFF;
		}
	}
}