#account-container {
	padding: 2em 4em !important;
}

#account-container, #tab-row-accounts {
	width: 100%;
	position: relative;
	height: auto;
	display: flex;
	flex-direction: column;
	font-family: Poppins, sans-serif;

    .loader-container {
    	position: absolute;
	    top: 30%;
	    z-index: 100000000;
	    left: 46.5%;
    }
  h2 {
    color: #ffffff;
    font-size: 15px;
    font-weight: 100;
    margin-bottom: 20px;
  }

	.page-title {
	    justify-content: space-between;
	    margin-bottom: 1em;

	    .add_account {
	    	background: #00A880;
			border-radius: 2.4px;
			font-family: Poppins;
			font-style: normal;
			font-weight: 500;
			font-size: 13px;
			border: 0;
			line-height: 19px;
			width: 128px;
			height: 40px;
			align-items: center;
			text-align: center;
			color: #FFFFFF;
			cursor: pointer;

			img {
				position: relative;
				top: 3px;
			}
	    }
	}


  .ins_up, .ins_down {
    display: none;
  }

  .t-history-header {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
    background: #004044;
    border-radius: 5px 5px 0px 0px;
    color: #ffffff;
    font-weight: 100;
    font-size: 12px;
    margin-bottom: 5px;
    text-align: center;
    background: #006066;
    border-radius: 10px;
    padding: 1em;
    width: 100%;

    li {
      width: calc(50% / 4);
      display: flex;
      flex-direction: column;
      align-items: left;
      text-align: left;
      padding-left: 20px;
      justify-content: center;
    }

    li.acc-name {
    	width: 50%;
    }

    .small-trans {
      width: 50px;
    }

    .trans-date {
      width: 100px;
      font-size: 12px;
    }

    .trans-type {
      width: 100px;
    }
  }

  .t-history-record {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0;
    list-style: none;
    font-size: 12px;
    color: #ffffff;
    margin-bottom: 2px;
    text-align: center;
    font-weight: 100;
    background: #002F32;
    border: 1px solid #006066;
    border-radius: 10px;
    padding: 1em;
    position: relative;

    &.ait {
      background: #002F49;
    }

    li {
      width: calc(50% / 4);
      display: flex;
      flex-direction: column;
      align-items: left;
      text-align: left;
      padding-left: 20px;
      justify-content: center;
    }

    li.acc-name {
    	width: 50%;
    	padding-left: 80px;
    }

    img.ai_b {
      position: absolute;
      top: -2px;
      left: -16px;
      width: auto;
    }

    .capital {
		width: 180px;
		height: 30px;
		font-family: Poppins;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 30px;
		color: #FFFFFF;
    }

    .acc-gra {
    	margin-left: 1em;
	    position: relative;
	    top: 5px;
    }

    small.inf {
      color: #C4C4C4;
      float: left;
    }

    .o-price .td {
      color: #33b4fd;
    }

    .o-rate .td {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      align-items: center;
      color: #FFE602;
    }

    .c-rate .td {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      align-items: center;
      color: #78FF56;
    }

    .d-sell .td {
      color: #FF2E53;
    }

    .profit .td {
      font-weight: bold;
    }

    .acc-name .td {
      font-weight: bolder;
    }

    .deposit {
    	width: 80px;
		height: 30px;
		background: #03CF9E;
		border-radius: 2px;
		border: 0px;
		color: #fff;
		padding: 6px 13px;
    }

    .th {
      display: none;
    }

    .tab-sn {
      display: none;
    }

    .small-trans {
      width: 50px;
    }

    .t-to {
      font-size: 11px;
    }

    .t-from {
      font-size: 11px;
    }

    .trans-date {
      width: 100px;
      font-size: 12px;
    }

    .trans-type {
      width: 90px;
      border-radius: 20px;
      text-transform: uppercase;
      height: 40px;
    }

    .transfer {
      background: #DC02FF;
    }

    .withdraw {
      background: #FF5D02;
    }

    .ttype {
      width: 114px;
      height: 30px;
      border: 0px solid;
      background: #FFE602;
      border-radius: 50px;
      color: #FFF;
    }
  }

	button.acc_type, .arr_down {
		width: 50px;
		height: 20px;
		position: absolute;
		border: 0px;
		left: 2em;
		color: #fff;
		font-size: 12px;
		top: 18px;
		background: #008EDE;
		border-radius: 40px;
	}

	button.acc_type.live {
		background: #03CF9E;
	}

	.arr_down {
		background: none;
	}

	.nofw {
		text-align: left;
	    display: block;
	    padding: 1em 0;

	    .arr_down {
	    	top: 2em;
	    }

		li {
			width: 100% !important;
			display: block;

			.accs, .filter {
				position: relative;
				display: inline-block;
				width: 50%;
				padding: 8px 1em;
				flex-direction: column;
				margin-left: 4em;
				justify-content: center;
				height: 50px;
				background: #006066;
				border: 1px solid #006066;
				border-radius: 5px;

			    li {
			    	padding-left: 100px;
				    font-weight: 500;
				    font-size: 20px;
				    line-height: 30px;
				    color: #FFFFFF;
				    position: relative;

				    .acc_type {
				    	width: 60px;
						height: 20px;
						top: 5px;
				    }

				    .drop_arr {
				    	position: absolute;
				    	top: 12px;
					    right: 0;
					}
			    }
			}

			.filter {
				width: 200px;
				height: 50px;
				background: #03CF9E;
				border-radius: 2px;
				font-family: Poppins;
			    font-style: normal;
			    font-weight: 500;
			    font-size: 20px;
			    line-height: 30px;
			    align-items: center;
			    text-align: center;
			    color: #FFFFFF;
			    outline: none !important;
				position: absolute;
			    right: 2em;

			    option {
			    	background: #DEFFF7;
			    	color: #00A880;
			    	padding-left: 1em;
					font-family: Poppins;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 30px;
			    }
			}
		}
	}

	.acc-stat-row, .acc-stat-row-2 {

		table {
			margin-top: 1em;
			margin-bottom: 1em;
			border: 0;

			th, td {
			    width: auto;
			    height: 30px;
			    font-family: Poppins;
			    padding: 0 1em;
			    font-style: normal;
			    font-weight: normal;
			    font-size: 20px;
			    line-height: 30px;
			    align-items: center;
			    color: #A6A6A6;
			}

			td {
				color: #fff;

				&.text-success {
					color: #03CF9E;
				}
			}
		}
	}

	.acc-stat-row-2 {
		margin-left: -2em;
	    width: calc(100% + 2em) !important;

		table {
			width: 100%;
			th {
				width: 25%;
				padding: 0;
				span.thr {
					font-size: 14px;
					line-height: 21px;
					margin-left: 1em;
					padding-left: 5px;
				}
				div {
					margin: 10px 1em;
					height: 100px;
					background: #004044;
					display: flex;
					padding: 1em 10px;
					justify-content: center;
					width: calc(100% - 2em - 4px);
					border-right: 4px solid #008EDE;

					.text-success {
						color: #03CF9E;
						font-size: 20px;
						width: 50%;
						position: relative;
						text-align: left;

						b {
							color: #fff;
							float: right;
							position: relative;
							right: -3px;
							font-weight: normal;
							font-size: 13px;
							top: 3px;
						}
					}
					.text-danger {
						color: #FF1E1E;
						line-height: 30px;
						width: calc(50% - 1em);
						text-align: right;
					}
					small {
						font-size: 13px;
						line-height: 19px;
					}
				}
			}
		}
	}

	#account-graph {
		position: relative;
		width: 100%;
		height: 300px;
		display: flex;
		background: #006066;
	}
}

@media screen and (max-width: 1200px) {
	#account-container {
		padding: 1em !important;

		.ins_up, .ins_down {
			position: absolute;
			left: 10px;
			top: 12px;
			width: 32px;
			height: 32px;
		}

		button.acc_type:not(.inside), .arr_down {
		    display: block;
		    position: relative;
		    left: 0;
		    top: -4px;
		}

		.acc-stat-row-2 table th div .text-success b {
		    color: #fff;
		    position: relative;
		    right: -3px;
		    float: unset;
		    font-weight: normal;
		    font-size: 13px;
		    top: 0px;
		}

		.nofw {
			li.acc-name {
				padding: 1em !important;
				display: flex !important;
			    flex-direction: column !important;

			    .accs, .filter {
			    	display: block !important;
			    	width: auto !important;
			    	padding: 0 !important;
			    	margin-left: 0 !important;
			    	right: 0 !important;
			    	width: auto !important;
			    	margin-top: 1em;
			    	position: relative !important;
			    }

			    .accs {

			    	li {
				    	margin: 0;
					    padding: 0;
					    padding-left: 37px !important;
					    margin-top: 10px;
					    margin-left: -10px;
					}
			    }

			    .filter {
			    	padding: 10px !important;
			    }
			}
			.arr_down {
				display: none;
			}
		}

	  	.t-history-record {
	  	flex-direction: column;

	  	li {
  		    width: 100% !important;
	        padding-left: 0 !important;
		    display: flex;
		    height: auto;
		    padding: 1em 0;
		    flex-direction: initial;

		    .td {
		        display: flex !important;
			    text-align: left !important;
		    }

		    &.acc-name {
		    	span.td {
				    position: relative;
				    height: auto;
				    flex-direction: column;
				    line-height: 20px;
		    	}
		    }
	  	}

	  	.acc-stat-row-2 {
	  		height: auto;
	  		padding: 0;
	  		margin: 0;
	  	}
	  }

	  .t-history-record img.ai_b {
	    left: unset !important;
	    position: absolute;
	    top: -2px;
	    right: -16px;
	    width: auto;
	  }

	  .t-history-record._active .ins_up {
	    display: block;
	  }

	  .t-history-record._active .ins_down {
	    display: none;
	  }

	  .t-history-record:not(._active) .ins_down {
	    display: block;
	  }

	  .t-history-record:not(._active) .ins_up {
	    display: none;
	  }

	  .th, .td {
	    display: inline-block !important;
	    width: 50% !important;
	    float: left;
	    line-height: 13px;
	    position: relative;
	    word-break: break-all;
	    font-size: 13px;
	  }

	  #account-container > h2 {
	    display: none;
	  }

	  .th {
	    font-weight: 500;
	    text-align: center !important;
	    color: #B9B9B9;
	  }

	  .td {
	    float: right;
	    font-weight: 500;
	    text-align: center !important;
	    color: #FFFFFF;
	  }

	  .t-history-record small.inf {
	    float: none;
	  }

	  .tab-sn {
	    display: flex !important;
	    width: 61px;
	    transform: skew(-20deg);
	    background: #006066;
	    flex-direction: column;
	    justify-content: center;
	    align-items: center;
	    height: 38px;
	    position: absolute;
	    z-index: 2;
	    margin-left: 32px;
	    cursor: pointer;
	    top: -36px;
	    padding: 0;
	    border-top-left-radius: 2em;
	  }

	  .tab-sn div {
	    transform: skew(20deg);
	    position: relative !important;
	    left: 37px;
	    display: block;
	    width: 49px;
	    padding-top: 10px;
	    text-align: left;
	    height: 38px;
	    background: #006066;
	    font-size: 1.2em;
	    border-top-right-radius: 1em;
	  }

	  .t-box {
	    background: transparent !important;
	  }

	  #account-container {
	    background: transparent;
	    padding: 0px;
	  }

	  #account-container .t-history-record .trans-type {
	    height: auto;
	  }

	  #account-container .t-history-record {
	    display: flex;
	    width: 100%;
	    justify-content: center;
	    flex-direction: column;
	    padding: 1em;
	    position: relative;
	    margin-top: 10px;
	    border-radius: 10px;
	    background: #002426;
	    border: 1px solid #006066;
	  }

	  .t-history-header {
	    display: none !important;
	  }

	  #account-container .t-history-record li {
	    display: none !important;
	    width: 100% !important;
	    justify-content: center !important;
	    align-items: center !important;
	    flex-direction: column;
	    height: 30px;
	    padding-top: 10px;
	  }

	  #account-container .t-history-record._active small.time {
	    position: relative;
	    top: 5px;
	  }

	  #account-container .t-history-record._active li {
	    height: 48px;
	  }

	  #account-container .t-history-record._active li {
	    display: block !important;
	  }

	  #account-container .t-history-record .acc-name {
	    display: block !important;
	  }

	  #account-container .t-history-record._active .small-trans {
	    display: none !important;
	  }

	  	.acc-stat-row-2 table th {
		    width: 100%;
		    padding: 0;
		    display: block;
		    height: 160px;
		}
	}

}

@media screen and (max-width: 500px) {
	#account-container {
		.acc-name {
			margin-top: 2.5em !important;
		}

		.th {
	        display: flex !important;
		    text-align: left !important;
	    }

	    .acc-stat-row {
	    	table {
		    	 th {
				    font-size: 14px;
				}
			}
		}
	}
}