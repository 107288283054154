.filter-section {
  width: 155px;
  background-color: inherit;
  display: flex;
  border: 2px solid #fff;
  border-radius: 5px;
  padding: 5px;

  img {
    width: 15px;
    height: 15px;
    margin-top: 2px;
  }

  select {
    background-color: inherit;
    border: none;
    height: 100%;
    color: #fff;
    font-size: 12px;
  }
}