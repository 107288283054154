.profile-dropdown {
  position: absolute;
  cursor: pointer;
  display: none;
  right: 0px;
  top: 55px;
  background: #1E1F23;
  border-radius: 3px;
  z-index: 20000000000 !important;
  width: 300px;
  box-shadow: 1px 2px 10px #212121;

  .section1 {
    margin-left: 0px !important;
    background: #303030;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1em;

    .grey {
      color: #A09F9F;
      margin-bottom: 0px;
    }

    img {
      height: 55px;
      width: 55px;
    }

    div {
      margin-left: 1em;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      height: 100%;
      flex-direction: column;
    }

    h3 {
      font-size: 17px;
      margin-bottom: 5px;
    }

    p {
      font-size: 12px;
    }

    h2 {
      font-size: 25px;
    }
  }

  .section3 {
    border-top: 0.5px solid #dadada6e;
    height: 125px;
  }

  .section2,
  .section3 {
    width: 96%;
    margin: 0 auto;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .grey {
      font-size: 12px;
      color: #A09F9F;
      margin-bottom: 8px;
    }

    h3 {
      margin-bottom: 15px;
      display: inline-flex;
      img {
        &.wall {
          width: 27px;
          height: 27px;
          position: relative;
          top: 3px;
        }
      }
    }

    .show {
      display: block;
      top: 6px;
      right: 10px;
      cursor: pointer;
      width: 21px;
      height: 17px;
      position: absolute;
    }

    ul {
      width: 100%;
      list-style-type: none;
      padding-left: 10px;
      margin-bottom: 0px;

      li {
        width: 100% !important;
        margin-bottom: 5px;
        font-weight: 300 !important;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        a {
          color: #FFFFFF;
        }

        img {
          height: 20px;
          width: 20px;
          position: relative;
          top: 5px;
          border-radius: 0;
          margin-right: 1em;
        }

        a:hover {
          color: #FFFFFF;
        }
      }
    }
  }
}

.profile-dropdown::before {
  content: '';
  top: -5px;
  width: 15px;
  right: 50px;
  height: 15px;
  display: block;
  position: absolute;
  background: #303030;
  transform: rotate(45deg);
}