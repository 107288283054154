.pagination-div {
  position: relative;
  display: flex;
  min-height: 50px;
  justify-content: flex-end;

  * {
    font-family: Poppins;
  }

  .pagination-ul {
    display: block;
    float: right;
    margin: 5px;
    margin-bottom: 1em;
    padding: 0;
    min-width: 272px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    li {
      display: inline-block;
      width: auto;
      height: 40px;
      width: 40px;
      float: left;
      margin: 0 2px;
      text-align: center;
      cursor: pointer;

      img, svg, span {
        display: inline-block;
        margin: 0 auto;
      }
      span {
        font-size: 1em;
        color: #fff;
        top: 5px;
        position: relative;
      }
      &._active {
        span {
          color: #00A880;
        }
      }
    }
    .one {
      border-right: 1px solid #C4C4C4;
    }
    .three {
      border-left: 1px solid #C4C4C4;
    }
    .one, .two, .three {
      width: 28px;
      height: 32px;
      position: relative;
      top: 4px;
    }

  }

}

@media screen and (max-width: 700px) {

  .pagination-div {
    height: 50px;
    justify-content: center;
  }

}