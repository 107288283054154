.password-box {
  width: 90%;
  margin: 0px auto 15px auto;
  color: #03CF9E;

  input {
    border: none;
    background: inherit;
    border: 1px solid #00A880;
    color: #03CF9E;
    border-radius: 3px;
  }

  input::placeholder {
    color: #c4c4c4;
  }

  .error {
    font-family: Poppins, sans-serif;
    color: rgb(221, 19, 19);
    font-size: 12px;
    text-align: left !important;
  }
}