.information {
  width: 90%;
  margin: 5px auto;
  padding: 10px 0;
  font-family: Poppins, sans-serif;
  border-bottom: 0.4px solid #888888;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 12px;
  }

  div {
    display: flex;
    align-items: center;
    img {
      margin-left: 10px;
      width: 20px;
      // &.ed {
      //   background: #003e42;
      //   border-radius: 50%;
      // }
    }
  }

  .key {
    color: #FFFFFF;
  }

  .value {
    color: #FFFFFF;
    font-weight: 300;
  }

  .data-value {
    background: transparent;
    color: inherit;
    font-family: inherit;
    border: 0;
    outline: none;
    width: 100%;
    margin: 0;
    margin-top: -10px;
    font-size: inherit;
    text-align: right;
    margin-bottom: -10px;
    padding: 0;
  }

  &.alt {
    width: 46%;
    flex-direction: column;
    display: inline-flex;
    margin-left: 1%;
    margin-right: 3%;

    .key {
      text-align: left;
      color: #8D8A8A;
      width: 100%;
    }

    .data-value {
      text-align: left;
    }

    div {
      width: 100%;
      justify-content: space-between;

      .value {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: left;
        color: #FFFFFF;
      }
    }
  }
}

.account-details-section .information {
  border-bottom: 0.4px solid #d8d8d8 !important;
}
