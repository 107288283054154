#devfx-app {

    #news-container-user {
        background: #141517;
        max-width: 100vw;
        width: 100vw;
        flex-direction: column;
        padding: 0px !important;
        padding-top: 10px !important;

        .page-title {
            padding: 8px 50px;

            .h-title {
                display: flex;
                justify-content: space-between;
                width: 100%;

                .ar {
                    width: 80px;
                    display: flex;
                    justify-content: space-between;

                    img {
                        cursor: pointer;
                    }
                }
            }
        }

        #news-container {
            .loader-container {
                transform: scale(2);
            }
        }

        .news-content {
            figure, img {
                display: none !important;
            }
            [class*="reactionButtonContainer-"], [class*="title-"] {
                display: none !important;
            }
            [class*="monthAndDay-"] {
                margin-right: 10px;
            }
            [class*="source-"] {
                font-size: 12px;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                position: relative;
            }
            h1 {
                width: calc(100% - 60px);
                font-size: 35px !important;
                font-weight: 600 !important;
            }
        }
    
        .read-more {
            background-color: transparent;
            border: 0px;
            box-shadow: none !important;
            outline: none !important;
            cursor: pointer;
            color: var(--custom_color) !important;
        }

        .loader-container {
            position: absolute;
            z-index: 100000000;
            left: 46.5%;
            top: 30%;
        }

        .h-title {
            text-align: center;
        }

        .current-news {
            height: auto;
        }

        #latnews {
            display: block;
            padding-left: 0px;
            height: auto;
            width: auto;
            margin: 0 auto;
            margin-top: 0px;

            li {
                width: 30%;
                height: 370px;
                margin: 1.5%;
                cursor: pointer;
                background: #1E1F23;
                display: inline-flex;
                border-radius: 8px;
                flex-direction: column;
                transition: 250ms ease-in-out;

                &:hover {
                    transform: scale(1.05);
                }

                img {
                    border-radius: 8px 8px 0px 0px;
                    width: 100%;
                    height: 240px;
                }

                .ctxt {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: space-around;
                    padding: 1.1em;
                    height: 150px;

                    h4 {
                        font-size: 18px;
                        text-align: center;
                    }
                }
                * {
                    display: inline-flex;
                }
            }
        }
    
        a.publish-btn {
            position: fixed;
            width: 150px;
            height: 50px;
            flex-direction: column;
            justify-content: center;
            right: calc(50% - 75px);
            bottom: 20px;
            border: 0;
            display: flex;
            flex-direction: row;
            background: var(--custom_color);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.81);
            border-radius: 80px;
            border: 1px solid var(--custom_color);
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            align-items: center;
            // color: #FFFFFF;
            color: var(--custom_alt_color);
    
            img {
                padding: 5px;
                position: relative;
                width: 30px;
                border-radius: 50%;
                height: 30px;
                left: 6px;
                background: #fff;
            }
        }
    
        .news-section-left {
            width:  calc(100% - 4em);
            float: left;
            overflow: auto;
            background: #1E1F23;
            margin: 2em;
            padding: 1em;
            padding-top: 30px;
            position: relative;
            box-shadow: 1px 4px 7px #000;

            .cancel {
                position: absolute;
                top: 25px;
                right: 30px;
                cursor: pointer;
            }

            .table-filters .filter-actions {
                display: inline-flex;
                justify-content: center;
                width: 65%;
                position: absolute;
                right: 0;
                top: 0;
            }
    
            img.ifm {
                width: 35%;
                display: inline-block;
                float: left;
                margin-right: 2em;
                margin-left: 1em;
                margin-bottom: 1em;
            }

            p {
                margin-bottom: 5px !important;
                display: block;
                padding-left: 10px;
                font-weight: 100;
                text-align: left;
            }
        }
    
        .news-section-right {
            padding: 10px 2em 0;
            width: 40%;
            float: left;
            
            ul.todays-ul {
                display: block;
                width: 100%;
                margin-top: 1em;
                list-style: none;
                margin-left: 0;
                padding-left: 0;
                list-style: none;
                border: .5px solid #CCCCCC;
                border: 0;
                box-shadow: 1px 2px 3px 1px #070707;
                border-radius: 5px;
    
                li.todays-li {
                    padding: 1em;
                    background: #004448;
                    justify-content: end;
                    display: flex;
                    border: .5px solid #CCCCCC;
                    border: 0;
    
                    img {
                        height: 80px;
                        float: left;
                        min-width: 80px;
                        padding-right: 0;
                    }
                    span {
                        margin-left: 2em;
                        float: left;
                        width: -webkit-fill-available;
                        font-family: Poppins;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 21px;
                        color: #FFFFFF;
                        position: relative;
                    }
                }
            }
    
            .fti {
                width: 100%;
                margin-top: 1em;
            }
    
        }
    
        h1.news-header {
            font-family: Poppins;
            font-weight: bold;
            font-size: 30px;
            text-decoration: underline;
            margin-bottom: 20px;
            line-height: 40px;
            color: #FFFFFF;
        }
    
        .read-more {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            align-items: center;
            text-align: right;
            color: #1FCF65;
        }
    
        p.news-content, div.news-content {
            font-family: Poppins;
            min-height: 120px;
            // font-size: 14px;
            // line-height: 21px;
            font-size: 16px;
            line-height: 30px;
            margin-top: 1em;
            font-weight: bold;
            color: #8A8A8A;
            text-align: left;
        }
    
        .similar-stories.alt {
            margin-top: 0px;
            padding-left: 30px;
    
            h1 {
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 22px;
                line-height: 32px;
                display: flex;
                align-items: center;
            }

            .scrollNews {
                // background-color: #333;
                overflow: auto;
                white-space: nowrap;
                padding-bottom: 10px;
    
                * {
                    white-space: normal;
                }
            }
    
            
            .scrollNews::-webkit-scrollbar {
                margin-bottom: 10px;
            }
    
            ul.stories {
                display: flex;
                width: 100%;
                margin-top: 1em;
                list-style: none;
                margin-left: 0;
                padding-left: 0;
    
                li {
                    margin-right: 16px;
                    display: flex;
                    width: 500px;
                    height: 200px;
                    border-radius: 8px;

                    .ctn {
                        width: 500px;
                        height: 200px;
                        display: flex;
                        flex-direction: row;
                        img {
                            width: 250px;
                            height: 200px;
                            justify-content: center;
                            display: inline-flex;
                            align-items: center;
                        }
                    }

                    .right {
                        width: 250px;
                        display: inline-flex;
                        flex-direction: column;
                        font-size: 14px;

                        align-items: center;
                        justify-content: center;
                        word-wrap: normal;

                        h3 {
                            font-size: 15px;
                            padding: 10px;
                        }
                    }
                }
            }
    
            .ss-thumbnail {
                width: 100%;
                min-width: 200px;
                max-width: 100%;
                border-radius: 5px;
            }
        }
    
        .btn.del {
            width: 73px;
            height: 30px;
            float: right;
            cursor: pointer;
            border: 0px solid;
            color: #fff;
            margin-top: 5px;
            background: #FF5722;
            border-radius: 2px;
            position: absolute;
            bottom: 0;
            right: 100px;
            background: #f23426;
        }
    
        .btn.read {
            width: 73px;
            height: 30px;
            float: right;
            cursor: pointer;
            border: 0px solid;
            color: #fff;
            margin-top: 5px;
            background: #008EDE;
            border-radius: 2px;
        }
        .btn-bottom {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    
        .pagination-div {
            justify-content: center;
            margin-top: 1em;
        }
    }
    
    @media screen and (max-width: 1000px) {
        #news-container {
            .read.btn-bottom {
                position: relative !important;
            }
            .similar-stories {
                overflow: auto;
            }
        }
    }
    
    @media screen and (max-width: 900px) {
    #news-container {
        display: block !important;
        .news-section-left,
        .news-section-right {
            padding: 0 !important;
            width: 100% !important;
        }
        .news-section-right { 
            margin-top: 2em;
        }
    }
    }
    
    
    @media screen and (max-width: 700px) {
    #news-container {
        .news-section-left {
            img.ifm {
                display: block;
                width: 100%;
            }
        }
    
    }
    
    }
}