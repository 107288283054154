.profile-section-container {
  padding: 20px 20px 50px 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  
  img.copy {
    position: absolute;
    right: -25px;
    height: 18px;
    top: 1px;
    cursor: pointer;
  }

  button._sdt {
    margin: 10px 20px;
    background: #006066;
    border: 1px solid #fff !important;
    outline: none;
    border-radius: 4px;
    color: #ffffff;
    font-family: Poppins, sans-serif;
    cursor: pointer;
    width: 100%;
    display: flex;
    margin-bottom: -5px;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
    text-align: center;
  }

  .rlk {
    background: #000;
    padding: 4px 15px;
    border-radius: 2em;
    border: 1px solid #0d6efd;
    outline: none !important;
  }

  .profile-left-section {
    width: 30%;
    background: #006066;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
  }

  .profile-right-section {
    width: 65%;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;

    .profile-bg {
      background: #006066;
      box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      padding: 15px;
    }

    &.admin {
      width: 100%;

      .adm-ptab {
        width: 100%;
        display: flex;
        list-style: none;
        flex-direction: column;

        li {
          width: 100%;
          display: flex;
          margin-top: 1em;
          flex-direction: row;

          .c {
            display: inline-flex;
            align-items: center;
          }

          .c, .v input {
            width: 200px;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #FFFFFF;
          }

          .v {
            input {
              width: 500px;
              outline: none;
              font-weight: lighter;
              background: transparent;
              border: 1.5px solid #00A880;
              border-radius: 4px;
            }
          }
        }
      }

      &>.profile-bg.end {
        display: flex;
        justify-content: flex-end;
      }
      .btn-save {
        margin: 5px;
        background: #00a880;
        border-radius: 1px;
        color: #ffffff;
        border: none;
        padding: 7px 12px;
        font-family: Poppins, sans-serif;
        cursor: pointer;
      }

      .header-image-section {
        width: auto;
        display: flex;

        .a-name {
          display: inline-flex;
          flex-direction: column;
          margin-left: 80px;
          justify-content: center;

          span {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
          }

          h2 {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 36px;
            line-height: 54px;
            display: flex;
            align-items: center;

            color: #FFFFFF;
          }
        }

        .header-image-sections-imgs, .c-icon1, .c-icon1 img {
          width: 170px;
          height: 170px;
          margin: 0px !important;
        }
      }

      .account-details-section {
        padding: 3em;
      }
    }
  }

  .user-information-section {
    margin-bottom: 20px;
  }

  .financial-details-section {
    margin-top: 15px;
    font-family: Poppins, sans-serif;
  }

  .account-details-section {
    margin-top: 15px;
    font-family: Poppins, sans-serif;
  }
}

.errorMessage {
  font-family: Poppins, sans-serif;
  color: red;
  font-size: 12px;
  text-align: center;
  margin: 0 auto .5em;
  padding-bottom: 5px;
}

.success-message {
  width: 95%;
  margin: 0 auto .5em;
  text-align: center;
  background: #fff;
  padding: 5px;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  color: #00a880;
}

.my-cards {
  display: flex;
}

@media screen and (max-width: 900px) {
  .profile-section-container {
    flex-direction: column;

    .profile-left-section {
      width: 100%;
      margin: 0 auto;
    }

    .profile-right-section {
      width: 100%;
      margin-top: 30px;
    }
  }

  .my-cards {
    flex-direction: column;;
  }
}

.red2 {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins';
  font-size: 1em !important;
}

.profile-right-section.admin .header-image-sections-imgs,
.profile-right-section.admin .c-icon1,
.profile-right-section.admin .c-icon1 img {
  width: 130px !important;
  height: 130px !important;
  margin: 0px !important !important;
}