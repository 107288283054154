.chart-section {
  width: 100%;
  position: relative;
  background: transparent !important;
  border-radius: 0px !important;
  margin-top: 0px !important;
  padding: 10px;
}

[disabled] {
  filter: grayscale(0.8);
  .sell, .market-sell-data {
    background: #03cf9e !important;
  }
}

.gr-hist-level {
  display: flex;
  flex-direction: row;
  margin-left: 1em;
  position: relative;
  &>span {
    width: 50px;
    cursor: pointer;
    text-align: center;
    display: inline-flex;
    &._active {
      color: var(--custom_color) !important;
    }
  }

  .tz-name {
    position: absolute;
    top: 5px;
    right: 0;
    cursor: pointer;

    img {
      user-select: auto;
      width: 15px;
      height: 15px;
      margin-right: 4px;
      position: relative;
      top: -1px;
    }
    span {
      font-size: 14px;
      color:#A8A8A8;
    }
  }
}

.chart-section-top {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .chart-section-top-left {
    display: flex;
    .blue-select {
      width: auto;
      height: 30px;
      background: transparent;
      border-radius: 5px;
      padding-left: 7px;
      color: #fff;
      cursor: pointer;
      border: none;
      font-size: 24px;
      display: block;
      outline: none !important;
      box-shadow: none !important;

      * {
        border:0px;
        outline:0px;
        font-size: 14px;
        background-color: #222222;
      }
    }
    .green-select {
      width: 160px;
      height: 35px;
      margin-left: 1em;
      font: 14px;
      text-align: center;
      background: #1E1F23;
      border-radius: 3px;
      padding: 4px 10px;
      border: none;
      color: #ffffff;

      * {
        border:0px;
        outline:0px;
        font-size: 14px;
        background-color: #222222;
      }
    }

    
    option:hover, option:checked {
      background-color: #ffa500 !important;
      box-shadow: 0 0 10px 100px #000 inset;
    }
  

  ul {
    display: flex;
    align-items: center;
    list-style-type: none;
    padding-left: 0px;
    margin: 0;

    li {
      position: relative;
      width: 50px !important;
      height: 35px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      background: #1E1F23;
      border-radius: 3px;
      margin-left: 10px;
      color: #fff !important;
      cursor: pointer;

      &.l100 {
        width: 111px !important;
      }

      &.l150 {
        width: 150px !important;
      }

      .icon {
        width: 14px !important;
        height: 14px !important;
      }

      .t-arrow {
        position: absolute;
        bottom: 11px;
        right: 2px;
      }
    }
  }
  }
  
  .chart-section-top-right {
    display: inline-flex;

    .graftype {
      display: flex;
      flex-direction: row;
      cursor: pointer;
      align-items: center;
      position: relative;
      top: 5px;
      justify-content: center;
    }

    .fullscreen {
      display: inline-flex;
      margin-left: 10px;
      margin-right: 1em;
      height: 30.93px;
      width: 28.92px;
    }
  }
}

.trdv {
  margin: 20px 0 10px 0;
  font-family: Poppins, sans-serif !important;
  overflow: hidden;
  flex: 1;
  position: relative;
  &.hide {
    display: none !important;
  }
}

.chart, .hr-chart {
  height: calc(100vh - (250px + 70px + 69px + 60px + 30px + 24px + 35px));
  display: flex;
  .zoom-controls {
    display: flex;
    position: absolute;
    user-select: none;
    background: #282828;
    opacity: 0.5;
    z-index: 1000;
    width: 80px;
    height: 33px;
    border-radius: 2em;
    justify-content: space-around;
    align-items: center;
    bottom: 40px;
    border: 1px solid #1f1f1f;
    left: calc(50% - 41px);
    box-shadow: 1px 2px 3px #000;

    &:hover {
      background: #181818;
      opacity: 1;
    }

    span {
      font-weight: 500;
      font-size: 150%;
      cursor: pointer;
      width: 40px;
      align-items: center;
      justify-content: center;
      display: inline-flex;
    }
  }
}

.trade-comp-container.exit {
  .hr-chart {
    height: calc(100vh - 120px) !important;
  }
}

.trdv {
  height: calc(100vh - (250px + 70px + 69px + 60px + 30px + 35px));
  display: flex;
  // overflow: scroll;
  // resize: vertical;
}

.gr-hist-level {
  // cursor: n-resize;
}

.chart {
  margin: 20px 0 10px 0;
  font-family: Poppins, sans-serif !important;
  overflow: hidden;
  flex: 1;
  position: relative;

  &.hide {
    display: none !important;
  }

  .loader-container {
    position: absolute;
    z-index: 580;
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 4em;
  height: 4em;
}

.loader {
  margin: 120px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.4em solid rgba(255, 255, 255, 0);
  border-right: 0.4em solid rgba(255, 255, 255, 0);
  border-bottom: 0.4em solid rgba(255, 255, 255, 0);
  border-left: 0.4em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.price-options {
  display: flex;
  width: 70%;
  justify-content: space-between;
  margin: 0 auto 5px auto;
  font-family: Poppins, sans-serif;
  font-size: 12px;
}

.success {
  color: #03cf9e;
}

.danger {
  color: #ff3939;
}

.chart-cta-section {
  width: 40%;
  margin: 0 auto;
  display: flex;
  font-family: Poppins, sans-serif;
  color: #ffffff;
  font-size: 15px;
}

.chart-sell,
.chart-buy {
  width: 40%;
}

.chart-map {
  width: 20%;
}

.sell,
.buy,
.map {
  height: 70px;
  cursor: pointer;
}

.sell,
.buy {
  display: flex;
  width: 100%;
  padding: 5px 15px;
}

.map {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #2a4750;
}

.sell {
  background-color: #ff1e1e;
  border-radius: 3.7px 0px 0px 3.7px;
}

.buy {
  background: #03cf9e;
  border-radius: 0px 3.7px 3.7px 0px;
}

.sell-info,
.buy-info {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.sell-info {
  margin-right: 10px;
}

.buy-right,
.map-center,
.sell-left {
  color: #a09f9f;
  font-size: 10px;
  margin-top: 5px;
}

p.sell-left, p.buy-right, p.map-center {
  color: #fff;
  font-size: .9em;
  margin-top: 5px;
}

.gr-dropdown {
  display: none;
  position: absolute;
  top: 34px;
  left: 0px;
  z-index: 1000000000000;
  text-align: left;
  min-width: 100%;
  width: 150px;
  height: auto;
  background: #222222;
  color: #fff;
  border: 0;
}

.gr-dropdown span {
  padding: 5px 1em;
  font-size: 15px;
  display: block;
  font-weight: normal;
  cursor: pointer;
}

.gr-dropdown span:hover {
  background: #2b2b2b;
}

.gr-dropdown span._active {
    background: #6c6c6c;
}

.gr-dropdown span img {
  float: left;
  width: 20px;
  height: auto;
  margin-right: 1em;
}

.instrument-icons li.one._active .gr-dropdown {
  display: block;
}

.instrument-icons li.two._active .gr-dropdown {
  display: block;
  width: 80px;
}

.instrument-icons li.three._active .gr-dropdown {
  display: block;
  width: 100px;
}

.instrument-icons li.four._active .gr-dropdown {
  display: block;
  width: 150px;
}

.buy-info {
  margin-left: 10px;
}

.map-center {
  text-align: center;
}

.buy-right {
  text-align: right;
}

@media screen and (max-width: 1100px) {
  // .balance-margin {
  //   flex-direction: column;
  // }

  // .chart-cta-section {
  //   width: 50%;

  //   font-size: 11px;
  // }

  // .price-options {
  //   width: 90%;
  // }

  // .chart-section-top {
  //   .chart-section-top-left {
  //     flex-direction: column;
  //     .blue-select {
  //       outline: none !important;
  //       width: 200px;
  //     }

  //     button {
  //       width: 130px;
  //       font-size: 13px;
  //       margin-left: 0;
  //       margin-top: 20px;
  //     }
  //   }
  //   .chart-section-top-right {
  //     flex-direction: column;
  //     margin-right: 0;
  //   }
  // }
}

@media screen and (max-width: 600px) {
  .chart-section {
    background: inherit;
    margin-top: 0;
  }
  .chart-section-top {
    flex-direction: column;
    .chart-section-top-left {
      flex-direction: column !important;
      button {
        margin-left: 0;
        margin-top: 10px;
      }
    }

    .chart-section-top-right {
      justify-content: flex-start;
      margin-top: 10px;
      margin-right: 0;
      flex-direction: column;

      ul {
        margin-top: 10px;
        padding: 0;
      }
    }
  }

  .sell-info {
    margin-right: 30px !important;
    font-size: 12px;
  }

  .buy-info {
    margin-left: 30px !important;
    font-size: 12px;
  }

  .price-options {
    font-size: 9px;
    width: 95%;
  }

  .chart-cta-section {
    width: 90%;

    font-size: 11px;
  }
}


.fullscreen {
  display: inline-flex !important;
  &.active {
    display: none !important;
  }
}

.trade-comp-container.exit {
  .main-chart, .trdv {
    height: calc(100vh - 110px);
  }
  .fullscreen {
    display: none !important;
    &.active {
      display: inline-flex !important;
    }
  }
}