.notification-dropdown2 {
  position: absolute;
  // cursor: pointer;
  // display: none;
  right: -121px;
  top: 40px;
  border-radius: 3px;
  z-index: 20000000000 !important;
  box-shadow: 1px 2px 10px #212121;
  width: 280px;
  height: auto;
  background: #1E1F23;
  border-radius: 4px 4px 0 0;

  &.user {
    right: -5px;
  }

  &.admin {
    right: -50px;
  }

  .nhead {
    color: #fff !important;
    height: 40px;
    width: 100%;
    padding: .5em 1em;
    display: flex;
    background: #303030;
    justify-content: space-between;

    & > * {
      font-weight: normal;
      display: inline-block;
      font-family: 'Poppins';
    }
  }

  .clear_all {
    top: 12px;
    right: 1em;
    float: right;
    font-size: 12px;
    padding: 2px 5px;
    border-radius: 2em;
    height: 23px;
    color: #fff;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid #4b4b4b;
    background: #1e1f23;
  }

  .section1 {
    width: 280px;
    height: 50px;
    position: relative;
    background: #FFFFFF;
    border-radius: 3px 3px 0px 0px;
    padding: 10px 15px 0px;

    img.udp {
      width: 30px;
      height: 30px;
      display: inline-flex;
      margin-left: 1em;
    }

    img.dbn {
      position: absolute;
      right: 1em;
      top: 17px;
      width: 15px;
      height: 13.57px;
    }

    .grey {
      color: #A09F9F;
    }

    div {
      margin: 10px 0 0 0;
    }

    h3 {
      font-size: 17px;
    }

    p {
      font-size: 12px;
    }

    h2 {
      font-size: 25px;
    }
  }

  .section2 {
    width: 100%;
    height: 100%;
    max-height: 300px;
    overflow: auto;
    min-height: 20px;

    p {
      font-size: 12px;
      color: #A09F9F;
      margin-bottom: 8px;
    }

    ul {
      width: 100%;
      padding-left: 0px;
      list-style-type: none;
      padding-top: 5px;
      height: 100%;
      overflow: auto;

      li {
        width: calc(100% - 20px) !important;
        padding: 1em;
        padding-bottom: 1.2em;
        position: relative;
        text-align: left;
        -webkit-user-select: none;
        user-select: none;
        color: #c4c4c4;
        font-weight: 300;
        margin: 2%;
        margin-left: 10px;
        // list-style: inside;
        cursor: pointer;
        border-bottom: 1px solid #303030;

        span {
          position: absolute;
          width: 100%;
          display: inline-block;
          bottom: 5px;
          font-size: 10px;
          right: 2px;
          color: #DDD;
          text-align: right;
        }

        &.new {
          // font-weight: bold !important;
          color: #fff;
        }

        &:last-child {
          border-bottom: 0px;
        }

        .m-time {
          font-family: "Poppins";
          font-weight: normal;
          align-items: center;
          font-style: normal;
          line-height: 12px;
          font-size: 11px;
          color: #A7A7A7;
          display: flex;
          margin: 10px;
        }
      }
    }
  }
}

.notification-dropdown2::before {
  content: '';
  background: #303030;
  width: 15px;
  height: 15px;
  position: absolute;
  top: -4px;
  right: 125px;
  z-index: -1;
  display: block;
  transform: rotate(45deg);
}

.notification-dropdown2.mobile {
  display: block !important;
  right: 1% !important;
  width: 98%;
  top: 48px;
  bottom: 2px;
  max-height: 82%;
  position: fixed;

  .section2 {
    max-height: 100% !important;
  }
}

.notification-dropdown2.mobile {

  .section1 {
    width: 100%;
  }

  .section2 {
    height: 100%;
  }

  .section3 input {
    width: calc(100% - 70px);
  }
}

.notification-dropdown2.mobile::before {
  right: 41% !important;
}