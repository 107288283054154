.trade-history {
    padding: 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    // margin-top: 1em;

    .fullscreen { 
        display: none !important;
        &.active {
            display: inline-flex !important;
        }
        width: 20px;
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .edit-trade {
        top: 0px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        position: relative;
        display: inline-flex;
        margin-left: 8px;
        // background: #002fae;
    }

    .h-title {
        display: flex;
        line-height: 20px;
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 0px;
        padding-left: 1em;
        align-items: flex-start;

        .form-control {
            margin-left: 50px;
            width: 80px;
            position: relative;
            top: -8px;
            color: #fff;
            background: transparent;
        }
    }

    .trade-stabs {
        padding-left: 20px;
        display: flex;
        width: 100%;
        flex-direction: row;
        height: 45px;
        list-style: none;
        cursor: pointer;
        font-size: 16px;
        color: #B2B7C0;
        // padding-top: 5px;
        // margin-bottom: 2px;
        padding-top: 10px;
        margin-bottom: 10px;
        border-top: 1px solid #3c3c3c;

        li {
            padding: 5px 0;
            margin: 0 20px 0 0;
            display: inline-flex;
            align-items: center;
            border-bottom: 5px solid transparent;
            &.active {
                color: var(--custom_color);
                border-bottom: 5px solid var(--custom_color);
            }
        }
    }

    .table-responsive {
        width: 100%;
        // margin-top: 1em;
        table {
            margin-top: 0px;
        }
    }

    table, thead, tbody, tr {
        display: flex;
        width: 100%;
    }

    table, thead, tbody {
        flex-direction: column;
    }

    thead, tbody {
        padding-left: 20px;
        padding-right: 20px;
        flex-direction: column;
    }

    tbody {
        border-bottom: 1px solid #3C3C3C !important;
    }

    td, th {
        text-align: left;
        font-size: 14px;
        width: calc(100% / 12);
        border: 0 !important;
        position: relative;
        height: 50px;

        &.icent {
            text-align: center !important;
        }
        &.imin {
            min-width: 90px;
        }
    }

    table.alt {
        td, th {
            width: calc(100% / 11);
        }
    }

    th {
        color: #B5B5B5;
        font-size: 10px;
        height: 30px;
    }
    
    thead {
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        border-top: 1px solid #3C3C3C !important;
        border-bottom: 1px solid #3C3C3C !important;
    }

    td.m2, th.m2 {
        min-width: 130px !important;
    }

    td, .tmob-hist li {
        &.m2 {
            display: inline-flex;
            flex-direction: column;
        }
        span {
            position: relative;
        }
        .dt {
            font-weight: 500;
        }
        time {
            font-size: 10px;
            position: relative;
            top: -1;
            color: #FFA500;
        }
        .aut {
            font-size: 10px;
            position: relative;
            top: -1px;
            background-color: #FFA500;
            color: #fff;
            height: 15px;
            justify-content: center;
            padding: 0px 5px;
            width: -moz-fit-content;
            width: fit-content;
            border-radius: 1.5em;
            border: 1px solid #FFA500;
            align-items: center;
            display: inline-flex;
        }
        .rt {
            color: #FFA500;
        }
        .b {
            color: #1FCF65;
            &.btn {
                border: 1px solid #1FCF65;
                border-radius: 2em;
                min-width: 65px;
                cursor: pointer;
                height: 30px;
                align-items: center;
                justify-content: center;
                background: #1fcf6538;
            }
        }
        .s {
            color: #FF2E53;
            &.btn {
                border: 1px solid #FF2E53;
                border-radius: 2em;
                min-width: 65px;
                cursor: pointer;
                height: 30px;
                align-items: center;
                justify-content: center;
                background: #ff2e533b;
            }
        }
        .cr {
            &.up {
                color: #78FF56;
            }
            &.down {
                color: #FF2E53;
            }
        }
        .pl, .pr {
            font-weight: 500;
            &.profit {
                color: #1FCF65;
            }
            &.loss {
                color: #FF2E53;
            }
        }
        .arw {
            position: absolute;
            top: -5px;
            right: -18px;
            height: 10px;
            width: 10px;
        }
    }
    .cl-btn {
        display: inline-flex;
        height: 25px;
        width: auto;
        outline: none;
        box-shadow: none;
        font-size: 10px;
        padding: 5px 10px;
    }
    .att {
        background: #ffa500;
        border-radius: 24px;
        font-size: 10px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
        width: 45px;
        height: 18px;

        &.demo {
            background: #008EDE;
        }
    }
}