.dash-container {
  // background-color: #004044;
  background-color: #141517;
  display: flex;
  min-height: 100vh;

  * {
    font-size: 14px;
  }

  // &>.col-12 {
  //   height: calc(100vh - 75px) !important;
  // }
}

.are-you-still-logged-in {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 111111;
  display: flex;
  align-items: end;
  background-color: rgba(255, 255, 255, 0.1);
  flex-direction: row;
  justify-content: center;
  transition: 500ms ease-in;
  div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 500px;
      max-width: 100%;
      padding: 1em 8px;
      margin-bottom: 10px;
      height: fit-content;
      background: #000;
      border-radius: 4px;

      span {
          font-size: 100%;
          font-weight: 600;
      }
  
      button {
          width: 100px;
          height: 35px;
          cursor: pointer;
          margin-top: 1em;
          text-align: center;
          border-radius: 4px;
          border: 1px solid #000 !important;
          background-color: #ffa500;
          justify-content: center;
          align-items: center;
          display: flex;
      }
  }
}

html {
  scrollbar-color: black #141517 !important;
  scrollbar-width: thin !important;
}

.user-profile-body, .createadmin-section .createadmin-box, .restriction-nav li.selected-trans-tab, .restriction-nav li.selected-trans-tab::before {
  background-color: #000 !important;
}

.restriction-nav li, .restriction-nav li::before {
  background-color: #1d1d1d !important;
}

.deposit-flex {
  input, select {
    width: 100%;
    background-color: #ffffff1f !important;
    border: 1px solid #fff !important;
  }
  label {
    color: #fff !important;
  }
  [type="button"] {
    height: 40px;
    background-color: #FFA500 !important;
    background: #FFA500 !important;
    border: 1px solid transparent !important;
  }
}

.notification-dropdown {
  background: #303030 !important;
}

.notification-dropdown .clear_all {
  text-decoration: none;
  border: 1px solid #000;
  padding: 1px 4px;
  border-radius: 1em;
  background: #333;
  color: #fff;
}

.pagination-2 button.pg-no {
  background: #444 !important;
}
.modal-cancel {
  height: 25px;
}
input.accs, select.accs {
  border: 1px solid #343434 !important;
  margin-top: 4px;
}
.modal-main-img {
  border-radius: 50%;
}
.left-nav .link-icons .l-nav-link p {
  margin-left: 10px;
  margin-top: 15px;
}
.top-nav-list._admin {
  font-size: 14px;
}
.hide {
  display: none !important;
}
.top-nav {
  height: 70px;
}
.left-nav .link-icons .l-nav-link {
  padding: 5px !important;
}

.trade-history .t-history-header {
  padding: 10px !important;
}

.trade-history .t-history-record {
  padding: 10px 5px !important;
}

.inst-table, .table-body, .t-history-record, .todays-li {
  background: #0a0a0b !important;
  border: 1px solid transparent !important;
}

.outter-left {
  border-right: 1px solid #121212;
}
.top-nav {
  border-bottom: 1px solid #000;
}

.tab-row {
  background-color: transparent !important;
}

.profile-details .detail-row, .profile-details .detail-row-2, .profile-files ul.for-folders li, .profile-notes ul li, .transaction-history-header, .outter-left {
  background-color: #000 !important;
}

.profile-section-container .profile-right-section .profile-bg {
  background: #2d2d2d !important;
}
.createadmin-section .half-screen .header {
  background: #373737 !important;
}

.createadmin-section .half-screen {
  background: #141517 !important;
}

.createadmin-section .createadmin-box {
  z-index: 0 !important;
}

.profile-section-container .profile-right-section.admin .adm-ptab li .v input {
  border: 1px solid transparent !important;
}

.profile-section-container .profile-right-section.admin .btn-save {
  background: #ffa500 !important;
}

.overlay.popups .edit-modal-section .edit-header {
  background: linear-gradient(90deg, #484848 32.82%, #000000 89.15%) !important;
}

.overlay.popups .edit-modal-section {
  background: #ffffff !important;
}

.transaction-history .transaction-history-header .trans-type {
  width: 102px !important;
  font-size: 12px !important;
  position: relative;
  margin-left: -8px;
}

.open-trades-container {
  * {
    font-size: 12px;
  }
}

.transaction-history-record {
  margin-bottom: 2px !important;
}

.d-title {
  color: #fff !important;
}

.deposit-modal-section .upper-modal, .profile-notes ul li .d-foot {
  background: #444 !important;
}

.lower-modal-content {
  button {
    border: 1px solid #a9a9a9 !important;
  }
}

.cm-undo {
  color: #444 !important;
}

#document-container table tbody tr {
  background: #0a0a0b !important;
}

.overlay .modal-section .bsell-modal {
  background: #141517 !important;
  border: 1px solid #393939 !important;
}

.borderad-menu li._active, thead tr, thead, .table-header, .t-history-header {
  background: #393939 !important;
}

.p-tabs {
  border-bottom: 1px solid #393939 !important;
}
.breadcrumbs li:last-child {
  color: #FFA500 !important;
}
.bsell-modal-content h6, .modal-section .bsell-modal .bsell-modal-content p, .p-tabs, .trade-not-found-box p {
  color: #e1e1e1 !important
}
.bsell-modal-content select, input {
  color: #fff !important;
}

.l-nav-link.active, .link-icons.active, .pagination-2 button.pg-p, .pagination-2 button.pg-n, .table-filters .create-btn, .sacc, #save-all-changes-btn, .create-list {
  background-color: #FFA500 !important;
  background: #FFA500 !important;
  border: 1px solid transparent !important;
}

.pg-no {
  border: 1px solid transparent !important;
}

.presence {
  display: flex;
  background: #004044;
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 10000;
  left: calc(50% - 70px);
  top: 110px;
  border-radius: 50%;
  border: 1px solid #00a880;

  &.online {
    background: #00a880;
    border: 1px solid #00a880;
  }
}

// .presence-td {
//   width: 15px;
//   height: 15px;
//   z-index: 10000;
//   position: absolute;
//   left: 20px;
//   top: calc(50% - 8px);
//   border-radius: 50%;
//   background: #ff9800;
//   border: 1px solid #ff9800;

//   &.online {
//     background: #00a880;
//     border: 1px solid #00a880;
//   }
// }

.presence-tx, .presence-td {
  width: auto;
  height: 17px;
  border-radius: 12px;
  padding: 2px 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  margin-top: 4px;
  cursor: pointer;
  background: #ff9800;
  border: 1px solid #ff9800;

  &.online {
    background: #00a880;
    border: 1px solid #00a880;
  }
}

.spres {
  position: relative;
  display: inline-flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: center !important;
}

.dash-container > .right {
  width: calc(100% - 170px);
}

.dash-container > .smaller-right {
  width: calc(100% - 60px);
}

.trade-section {
  display: flex;
  height: calc(100% - 60px);
  width: 100%;
  .left {
    position: relative;
    background-color: #006066;
    border: 1px solid #a6a6a6;
    width: 220px;
    display: block;

    .filter-img {
      position: absolute;
      right: 15px;
      top: 20px;
      cursor: pointer;
    }
  }

  .big-left {
    width: 320px;

    .loader-container {
      position: absolute;
      z-index: 580;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .right {
    width: calc(100% - 220px);
  }

  .big-right {
    width: calc(100% - 320px);
  }
}

.margin-top {
  margin-top: 80px;
}

.margin-top-small {
  margin-top: 10px;
}


.right > .trade-comp-container {
  width: 100%;
  padding: 0 20px 10px 20px;
}

.trade-overview-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;

  .balance-margin {
    display: flex;
    width: 43%;

    .balance-demo {
      width: 70%;
    }

    .margin-stuff {
      margin: 0 10px;
    }
  }

  .margin-stuff {
    width: 160px;
    height: 160px;
    background: #004448;
    border-radius: 0px 0px 20px 20px;
    padding: 10px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .margin {
      font-family: Poppins, sans-serif;
      color: #ffffff;

      h6 {
        color: #8a8a8a;
        font-size: 12px;
      }

      p {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .trade-section {
    .left {
      position: fixed;
      width: 220px;
      height: 400px;
      z-index: 4;
      display: none;
      bottom: 0 !important;
      left: 0;
    }

    .display-l-nav {
      display: block;
    }

    .right {
      width: 100% !important;
    }
  }

  .dash-container > .right {
    width: calc(100% - 60px);
  }

  .market-section {
    display: block !important;

    .big-left {
      position: static;
      width: 600px;
      margin: 0 auto;
      display: block !important;
      background: inherit;
      height: inherit;
      border: none;
      padding-bottom: 70px;

      .filter-img {
        display: none;
      }

      .margin-top-small {
        margin-top: 10px;
        display: block !important;
      }
    }

    .big-right {
      display: none;
    }
  }
}

@media screen and (max-width: 800px) {
  .trade-section {
    .left {
      bottom: 50px !important;
    }
  }
  .right > .trade-comp-container {
    padding: 0 10px 80px 10px;
  }

  .dash-container {
    padding-bottom: 50px;
  }

  .trade-overview-row {
    flex-direction: column;

    .balance-margin {
      width: 100%;
      flex-direction: row;
      // justify-content: space-around;
      margin-left: 10px;

      .margin-stuff {
        display: none;
      }
    }
  }

  .market-section {
    .big-left {
      width: 450px;
    }
  }
}

@media screen and (max-width: 600px) {
  .dash-container {
    position: relative;
  }

  .dash-container > .right {
    width: 100%;
  }

  .market-section {
    .big-left {
      width: 350px;
    }
  }
}


.blurable {
  .blurc {
    -webkit-user-filter: blur(.3em);
    -ms-filter: blur(.3em);
    filter: blur(.3em);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}


.dash-container._daff {
  .blurable {
    .blurc {
      -webkit-user-filter: blur(0px) !important;
      -ms-filter: blur(0px) !important;
      filter: blur(0px) !important;
      -webkit-user-select: unset;
      -ms-user-select: unset;
      user-select: unset;
    }
  }
}