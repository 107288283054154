.breadcrumbs {
	display: flex;
    flex-direction: row;
    list-style: none;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    align-items: center;
    color: #C4C4C4;
    padding-left: 0;

    li {
        margin: 0 5px;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        display: inline-flex;
        height: 30px;

    	&>svg {
    		position: relative;
    		top: 0px;
    	}

    	&>img {
    		margin-left: 10px;
    		position: relative;
    		top: 0px;
    	}
    }

    li:last-child {
    	color: #03CF9E;
    }
}