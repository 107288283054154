.notification-dropdown {
  position: absolute;
  // cursor: pointer;
  // display: none;
  right: -121px;
  top: 40px;
  font-family: Poppins, sans-serif;
  background: #212121;
  border-radius: 3px;
  z-index: 20000000000 !important;
  box-shadow: 1px 2px 10px #212121;
  width: 280px;
  height: auto;
  border-radius: 3px;

  &.user {
    right: -5px;
  }

  &.admin {
    right: -50px;
  }

  .nhead {
    color: #000 !important;
    height: 40px;
    width: 100%;
    padding: .5em 1em;
    background: #fff;
    justify-content: space-between;
    border-radius: 4px 4px 0 0;

    & > * {
      font-weight: normal;
      display: inline-block;
      font-family: 'Poppins';
    }
  }

  .clear_all {
    top: 12px;
    right: 1em;
    float: right;
    font-size: 90%;
    color: #000000;
    cursor: pointer;
    user-select: none;
    margin-top: 2px;
    font-family: 'Poppins';
    text-decoration: underline;
  }

  .section1 {
    width: 280px;
    height: 50px;
    position: relative;
    background: #FFFFFF;
    border-radius: 3px 3px 0px 0px;
    padding: 10px 15px 0px;

    img.udp {
      width: 30px;
      height: 30px;
      display: inline-flex;
      margin-left: 1em;
    }

    img.dbn {
      position: absolute;
      right: 1em;
      top: 17px;
      width: 15px;
      height: 13.57px;
    }

    .grey {
      color: #A09F9F;
    }

    div {
      margin: 10px 0 0 0;
    }

    h3 {
      font-size: 17px;
    }

    p {
      font-size: 12px;
    }

    h2 {
      font-size: 25px;
    }
  }

  .section2 {
    width: 100%;
    height: 100%;
    max-height: 290px;
    overflow: auto;
    min-height: 20px;

    &::-webkit-scrollbar {
      width: 7px;
      border-radius: 1em;
      background: #212121;
    }

    &::-webkit-scrollbar-track {
      background: #212121 !important;
      border-radius: 1em;
    }

    &::-webkit-scrollbar-thumb {
      cursor: pointer;
      border-radius: 1em;
      background: #b7b7b7 !important;
    }

    p {
      font-size: 12px;
      color: #A09F9F;
      margin-bottom: 8px;
    }

    ul {
      width: 100%;
      padding-left: 0px;
      list-style-type: none;
      padding-top: 5px;
      height: 100%;
      overflow: auto;

      li {
        width: calc(100% - 20px) !important;
        padding: 1em;
        padding-bottom: 1.2em;
        position: relative;
        text-align: left;
        -webkit-user-select: none;
        user-select: none;
        color: #c4c4c4;
        font-weight: 300;
        margin: 2%;
        margin-left: 10px;
        // list-style: inside;
        cursor: pointer;
        border-bottom: 0.3px solid #ffffff8c;

        span {
          position: absolute;
          width: 100%;
          display: inline-block;
          bottom: 2px;
          font-size: 80%;
          right: 2px;
          color: #DDD;
          text-align: right;
        }

        &.new {
          // font-weight: bold !important;
          color: #fff;
        }

        &:last-child {
          border-bottom: 0px;
        }

        .m-time {
          font-family: "Poppins";
          font-weight: normal;
          align-items: center;
          font-style: normal;
          line-height: 12px;
          font-size: 11px;
          color: #A7A7A7;
          display: flex;
          margin: 10px;
        }

        &.y-msg {
          padding: 5px 1em;
          display: flex;

          div.m-text {
            padding: 5px 10px;
            width: auto;
            min-width: 140px;
            display: inline-flex;
            background: #00A880;
            border-radius: 3px;
            position: relative;

            &::before {
              position: absolute;
              width: 17px;
              height: 20px;
              left: -4px;
              top: -5px;
              content: '';
              z-index: 0;
              border-top: 10px solid transparent;
              border-right: 13px solid #00a880;
              border-bottom: 14px solid transparent;
              transform: rotate(-52deg);
            }
          }
        }

        &.m-msg {
          padding: 5px 1em;
          display: flex;
          justify-content: flex-end;

          div.m-text {
            float: right;
            padding: 5px 10px;
            width: auto;
            min-width: 140px;
            display: inline-flex;
            background: #004448;
            border-radius: 3px;
            position: relative;

            &::before {
              position: absolute;
              width: 17px;
              height: 20px;
              right: 0px;
              top: -10px;
              content: '';
              z-index: 0;
              border-top: 0px solid transparent;
              border-right: 20px solid #004448;
              border-bottom: 18px solid transparent;
              transform: rotate(90deg);
            }
          }
        }
      }
    }
  }

  .section3 {
    display: flex;
    width: 100%;
    height: 50px;
    padding: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    input {
      width: 214px;
      height: 30px;
      border: 0;
      color: #fff;
      padding: 3px 1em;
      background: #039EA8;
      border-radius: 50px;
      outline: none !important;
    }

    .send {
      width: 30px;
      border: 0;
      height: 30px;
      margin-left: 10px;
      padding: 8px;
      outline: none;
      cursor: pointer;
      background: #00A880;
      border-radius: 50px;
    }
  }
}

.notification-dropdown::before {
  content: '';
  background: #212121;
  background: #ffffff;
  width: 15px;
  height: 15px;
  position: absolute;
  top: -4px;
  right: 125px;
  z-index: -1;
  display: block;
  transform: rotate(45deg);
}

.notification-dropdown.admin::before {
  right: 53px;
}

#notificationList::-webkit-scrollbar {
  width: 8px;
}

#notificationList::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

#notificationList::-webkit-scrollbar-thumb {
  background: #888; 
}

#notificationList::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.notification-dropdown.mobile {
  display: block !important;
  right: 1% !important;
  width: 98%;
  top: 48px;
  bottom: 2px;
  max-height: 82%;
  position: fixed;

  .section2 {
    max-height: 100% !important;
  }
}

.notification-dropdown.mobile {

  .section1 {
    width: 100%;
  }

  .section2 {
    height: 100%;
  }

  .section3 input {
    width: calc(100% - 70px);
  }
}

.notification-dropdown.mobile::before {
  right: 41% !important;
}