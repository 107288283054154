.verification-text-header {
  width: 350px;
  text-align: center;
  margin: 5px auto 30px auto;
  font-size: 17px;
  font-family: Poppins, sans-serif;
  line-height: 1.3;
}

.phone-image {
  width: 200px;
  height: 200px;

  img {
    width: 100%;
    height: 100%;
  }

  margin: 10px auto 20px auto;
}

.remove-border-bottom {
  border-bottom: none !important;
}

@media screen and (max-width: 500px) {
  .verification-text-header {
    margin-top: 20px;
    font-size: 18px;
    width: 320px;
  }
}