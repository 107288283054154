.header-image-section {
  position: relative;
  width: 100px;
  margin: 10px auto;
  padding: 10px 0 10px 0;

  .header-image-sections-imgs {
    position: relative;
    width: 100%;

    .c-icon1 {
      width: 70%;
      
      margin: 10px auto !important;
      img {
        width: 100%;
        height: 70px;
        border-radius: 50%;
      }
    }

    .c-icon2 {
      width: 25px;
      height: 25px;
      bottom: 0;
      right: 20px;
      position: absolute;
    }

    .small-overlay {
      position: absolute !important;
      background-color: red;
      border-radius: 50%;
      z-index: 10;
      width: 62%;
      height: 95%;
      margin: 0 auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: inherit;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    
      .small-spinner {
        position: relative;
        pointer-events: none;
        width: 1.2em;
        height: 1.2em;
        border: 0.4em solid transparent;
        border-color: rgb(49, 48, 48);
        border-top-color: #00a880;
        border-radius: 50%;
        animation: spin 1s linear infinite;
        z-index: 4;
      }
    }
  }

  input[type=file] {
    position: absolute;
    width: 25px;
    height: 25px;
    top: 30px;
    left: 30px;
    z-index: -2;
  }
}



@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
