.user-profile-body {
	width: 340px;
    height: calc(100% - 70px);
    display: flex;
    bottom: 0;
    top: 70px;
    background: #EAFFFA;
    position: absolute;
    z-index: 100000;
    flex-direction: column;
    overflow: auto;





.w3-light-grey, .w3-hover-light-grey:hover, .w3-light-gray, .w3-hover-light-gray:hover {
    color: #000!important;
    // margin-bottom: 10px;
    margin-top: 3px;
	font-family: Poppins;
	font-style: normal;
    background-color: #f1f1f1!important;
}
.w3-round-xlarge {
    border-radius: 16px;
}

.w3-color, .w3-hover-color:hover {
    color: #fff!important;
    background-color: #f59621 !important;
    &.p-100 {
	    background-color: #00a880 !important;
	}
}
.w3-container, .w3-panel {
    padding: 0.01em 16px;
    font-size: 11px;
}
.w3-round-xlarge {
    border-radius: 16px;
}



    .udp {
    	width: 100px;
		height: 100px;
		margin: 30px auto 30px;

		&.adm {
			width: 150px !important;
			height: 150px !important;
			border-radius: 50%;
			border: 1px solid #004044;
		}
    }

    h2.proflle-name {
    	font-family: Poppins;
		font-style: normal;
		font-weight: 500;
		font-size: 22px;
		line-height: 33px;
		text-align: center;
		color: #fff;
    }

    h6.user-id {
    	font-family: Poppins;
		font-style: normal;
		font-weight: 300;
		font-size: 13px;
		line-height: 19px;
		text-align: center;
		color: #fff;
    }

    .profile-actions {
    	display: flex;
	    list-style: none;
	    margin-top: 20px;
	    color: #03CF9E;
	    text-align: center;
	    font-family: Poppins;
	    font-style: normal;
	    font-weight: normal;
	    font-size: 12px;
	    line-height: 18px;
	    cursor: pointer;
	    padding-left: 0;
	    margin-left: 2em;
	    margin-right: 2em;

		& > li > img {
		  cursor: pointer;
		}
    }

    .user-info {
    	display: flex;
    	margin-top: 20px;
    	padding: 0 2.5em 1em;
   	    border-top: 1px solid rgba(0, 0, 0, 0.2);
    	position: relative;
    	flex-direction: inherit;

    	h4 {
			font-family: Poppins;
			font-style: normal;
			font-weight: 700;
			font-size: 20px;
			line-height: 21px;
			color: #ffa500;
			text-align: center;
			text-transform: uppercase;
    	}

    	.drp-down {
    		position: absolute;
		    left: 10px;
		    top: 22px;
    	}

    	ul {
	    	display: flex;
		    list-style: none;
		    margin-top: 20px;
		    color: #03CF9E;
		    font-family: Poppins;
		    font-style: normal;
		    font-weight: normal;
		    padding-left: 0;

		    flex-direction: column;
		    text-align: left;

		    li {
				justify-content: space-between;
				font-family: Poppins;
				display: flex;
				font-style: normal;
				font-weight: 500;
				font-size: 13px;
				line-height: 18px;
			    margin-bottom: 5px;
				color: #a7a7a7;

				span {
					margin-right: 14px;
				}

				font {
					color: inherit;
				}
		    }
    	}

    	table {
    		margin-top: 10px;

    		td {
    			width: 50%;
    			display: inline-flex;
    			flex-direction: column;
    			margin-bottom: 1em;

    			&.full {
    				width: 100%;
    			}
    		}

			.txt-default {
				font-family: Poppins;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 18px;
			}

			.td-v {
				font-family: Poppins;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 21px;
				color: #FFA24D;
			}

			.td-b {
				font-family: Poppins;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 21px;
				color: #03CF9E;
			}

			.btn-down {
				width: 140px;
				height: 34px;
				border: 0;
				font-family: Poppins;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 18px;
				color: #FFFFFF;
				margin-top: 5px;
				background: #ffa500;
				border-radius: 2px;
			}
    	}
    }

}

::-webkit-scrollbar {
  width: 8px;
  background: #C4C4C4;
  border-radius: 1em;
}

::-webkit-scrollbar-track {
    background: #EAFFFA;
}

::-webkit-scrollbar-thumb {
  background: #ddd;
  cursor: pointer;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #C4C4C4;
  cursor: pointer;
}

.users-section-right {
	::-webkit-scrollbar {
	  width: 8px;
	  background: #004044;
	  border-radius: 1em;
	}

	::-webkit-scrollbar-track {
	    background: #004044;
	}

	::-webkit-scrollbar-thumb {
	  background: #ddd;
	  cursor: pointer;
	  border-radius: 10px;
	}

	::-webkit-scrollbar-thumb:hover {
	  background: #C4C4C4;
	  cursor: pointer;
	}
}


.tab-row {
	width: 100%;
	flex-direction: column;
	height: calc(100% - 272px);
	overflow: auto;
	margin-bottom: 0;
	padding-right: 7px;
	padding-bottom: 0px !important;
	display: none;
	background: #004044;

	&._active {
	    display: flex;
	    height: calc(100vh - 90px);
	    height: auto;
	}
	// &._active.profile-accounts {
	// 	height: calc(100vh - 175px);
	// }
	// &._active.profile-files, &._active.profile-notes {
	// 	height: calc(100vh - 16px);
	// }
	// &._active.profile-email {
	//     height: calc(100vh - 19px);
	// }
}
