#sales-container {
	padding: 2em !important;
	width: 100%;
    display: flex;
	overflow-x: hidden !important;
	position: relative;


    #boardlists {
	    height: calc(100vh - 315px) !important;
	    // height: 500px !important;
	    overflow: auto;
    }

	.pagination-2 {
	    display: flex;
	    justify-content: center;
	    margin-top: 0;
	}

	.table-filters {
	    margin-top: 0px;
	}

    .salt-title {
		top: 0 !important;
		margin-top: 0px !important;
		padding-top: 0px !important;
		position: relative !important;
		width: 100%;
		z-index: 1000;
		background: #004044;

    	tr {
    		display: flex;
    		width: 100%;

    		td {
			    width: 275px;
			    margin-right: 10px;
			    background: #195256;
			    padding: 1em;
			    color: #fff;
    		}
    	}
    }


    .page-title {
    	justify-content: space-between;
    }

	.search-container {
		width: 150px;
	    height: 40px;
   	    // background: #feffff;
	    border-radius: 5px;
	    margin-left: 0px !important;
	}

	.half-screen {
	    display: block;
	    position: fixed;
	    bottom: 0;
	    right: 0;
	    background: #07222491;
	    top: 0px;
	    width: 100%;

		&>div {
			border-radius: 1em 0 0 0;
			background: #004044;
			flex-direction: row;
			box-shadow: 0px 0px 1em #020202;
			height: 100%;
			width: 72%;
			top: 50px;
			position: absolute;
			bottom: 0;
			right: 0;

			z-index: 1000000000;
		}
		.users-section-right.shared {
			width: 100% !important;
			margin-left: 0px !important;
		}

		#users-container {
		    // top: -3em;
		}
		
		.hide {
		    display: none !important;
		}

	  .user-name {
		display: inline-block;
		position: relative;
		background: #00A880;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 1.3em;
		line-height: 38px;
		float: right;
		top: -48px;
		right: -1.2em;
		padding: 5px 2em 5px 1.2em;
		transform: skewX(-30deg);
		color: #FFFFFF;

		span {
			// font-weight: bold;
			display: inline-block;
			transform: skewX(30deg);
		}
	  }

	}

	table {
		width: 100%;
		border: 0;
		background: #002022;
		margin-top: 50px;
		border-collapse: collapse;
		thead, tbody {
			width: 100%;
			border: 0;
			border-collapse: collapse;
			tr {
				width: 100%;
				border: 0;
				height: 70px;
				left: 136px;
				top: 265px;
				background: #003134;;
				border-collapse: collapse;
				border-radius: 5px 5px 0px 0px;

				th {
					border: 0;
					border-collapse: collapse !important;
					font-family: Poppins;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 21px;
					text-align: left;
					padding-left: 20px;
					align-items: left;
					color: #FFFFFF;
				}
			}
		}

		tbody {
			tr {
				background: #006066;
				&:nth-child(even) {
					border-left: 3px solid #00A880;
				}
				&:nth-child(odd) {
					border-left: 3px solid #006066;
				}
				td {
					font-family: Poppins;
					font-style: normal;
					font-weight: 300;
					font-size: 14px;
					line-height: 21px;
					text-align: left;
					padding-left: 20px;
					align-items: left;
					color: #fff;
					position: relative;

					.d-file, .v-file {
						position: relative;
					    margin-left: 5px;
					    cursor: pointer;
					    top: 4px;
					}

					.v-file {
						top: 7px;
					}

					.dc-na {
						width: 30px;
					    height: 30px;
					    padding: 5px;
					    font-size: 14px;
					    border-radius: 50%;
					    align-items: center;
				        display: inline-flex;
					    justify-content: center;

					    &.r {
					    	background: #FF3B3B;
					    }
					    &.y {
					    	background: #FEC704;
					    }
					    &.g {
					    	background: #1FCF65;
					    }
					    &.p {
					    	background: #FE00A8;
					    }
					}

					.txt-default {
						position: relative;
					    top: -4px;
					}
				}
			}
		}
	}

	.sales-section-right {
		width: 100%;

		&.shared {
			width: calc(100% - 342px);
			margin-left: 342px;
		}

		.pagination-div {
		    justify-content: center;
		    margin-top: 1em;
		}
	}

// .create-btn {
//     width: 130px;
//     height: 40px;
//     border: 0;
//     outline: none;
//     cursor: pointer;
//     background: #03CF9E;
//     border-radius: 3px;
//     font-family: Poppins;
//     font-style: normal;
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 21px;
//     align-items: center;
//     color: #FFFFFF;
// }




.board-layout {
    font-family: Poppins;
    font-size: 15px;
    display: grid;
    grid-template-rows: max-content auto;
    grid-gap: 10px;
    padding: 10px 0;
    height: 800px;
    height: auto;
    position: relative;
}

.list-layout {
    display: grid;
    grid-gap: 10px;   
}

.board-text {
    font-weight: bold;
    font-size: 28px;
    padding: 5px;
}

.board-lists {
    display: grid;
    grid-auto-columns: 275px;
    grid-auto-flow: column;
    grid-gap: 10px;
    height: 400px;
  }

  .board-list {
    display: grid;
    grid-auto-rows: max-content;
    grid-gap: 10px;
    height: max-content;
    padding: .5em;
    background: #f5f5f51a;
    border-radius: 1em;
    padding-bottom: 2em;
  }
  
  .list-title {
	color: #fff;
    font-size: 16px;
    padding: .3em;
    position: relative;

    .stage-name {
    	cursor: pointer;
    }

    .stage-name-val {
    	display: none;
	    background: transparent;
	    color: #fff;
	    border: 0;
	    outline: none;
	    font-size: 16px;
	    padding: 0;
	    position: absolute;
	    top: 0;
	    left: 0;
	    padding-left: 5px;
    }

    .del-icon, .chk-icon {
    	position: absolute;
    	right: 5px;
    	top: 10px;
    	cursor: pointer;
    }

    .chk-icon {
    	display: none;
    	height: 20px;
    	width: 20px;
    }

    b {
    	font-size: 0.7em;
	    font-weight: normal;
	    position: relative;
	    top: -5px;
    }
  }
// .card {
//     background-color: white;
//     border-radius: 3px;
//     box-shadow: 0 1px 0 rgba(9,30,66,.25);
//     padding: 10px;
//     cursor:pointer;
//   }

.sf-card {
    border-radius: 0px;
    box-shadow: 0 1px 0 rgba(9,30,66,.25);
    padding: 10px;
    background-color: white !important;
    background: #fff !important;
    cursor: pointer;
}

.x-half {
	position: absolute;
	left: 24.5%;
	top: calc(50px);
	width: 2.5%;
	border: 2px solid #eeeeee80;
	border-radius: 50%;
	cursor: pointer;
	outline: none;
}

.card.sf-card {
    margin-top: 0px;
    margin-bottom: -8px;
    background-color: #fff !important;
    font-size: 12px;
    text-align: center;
    padding: 3px;
    display: inline-flex;
    height: 80px;
    border-radius: 0 1em 1em 0;
    flex-direction: row;
    position: relative;
    border: 1px solid #b3b3b3;
    border-left: 5px solid #D14;
    background: #fff !important;
    box-shadow: 0px 0px 4px #151515;

    &.npt {
    	cursor: move;
    }
}

.card.putable {
    opacity: 0.5;
    color: transparent;
    border: 1px solid transparent;
    background-color: rgba(255, 255, 255, 0) !important;
    // background-color: rgba(255, 255, 255, 0.43) !important;
}

.hide {
    display: none;
}

.card.highlight {
    // background-color: #a2e7fd !important;
    // border-style: dashed;
    // border-color: #000;
}



.sf-card > img {
	width: 35px;
    height: 35px;
    border-radius: 50%;
    margin: 2px 1px;
    border: 1px solid #000000;
}

.sf-card > b {
    font-size: 1.1em;
    font-family: Poppins;
    margin-left: 10px;
    height: 40px;
    color: #00a880;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}


.sf-card div.s2 {
	border-top: 1px solid #DDD;
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 36px;
    text-align: left;
    left: 0;
    // color: #00a880;
    right: 0;
    font-weight: bold;
    padding: 11px;
    font-family: Poppins;
    font-size: 12px;
    display: flex;
    flex-direction: column;


	img {
	    position: absolute;
	    // background: #000;
	    cursor: pointer;
	    width: 1.5em;
	    bottom: 5px;

	    &.call {
	    	right: 5em;
	    }

	    &.view {
		    right: 2em;
		    width: 2em;
		    bottom: 6px;
	    }
	}

    span {
    	display: inline-block;
    	float: right;
    }
}






}

// html, body, #root {
//     overflow: hidden;
// }

#boardlists::-webkit-scrollbar {
	width: 5px;
	height: 5px;
	background: #004044;
	border-radius: 0px;
}

#boardlists::-webkit-scrollbar-track {
	background: #004044 !important;
	border-radius: 0px;
}

#boardlists::-webkit-scrollbar-thumb {
	cursor: pointer;
	background: #00A880 !important;
	border-radius: 0;
	width: 5px;
	height: 5px;
}