.overlay.popups {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 110;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 11111111111;

  &.funnel {
    position: relative !important;
    background-color: transparent;
    justify-content: unset;

    .edit-modal-section {
      width: 100% !important;
    }
  }

  .vector {
    width: 100%;
    height: 262px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    position: absolute;
  }

  .radar1 {
    position: absolute;
    height: 200px;
    width: 200px;
    left: calc(50% - 100px);
    top: calc(50% - 100px);
  }

  .radar2 {
    position: absolute;
    height: 180px;
    width: 180px;
    z-index: 1;
    left: calc(50% - 90px);
    top: calc(50% - 90px);
  }

  .radar3 {
    position: absolute;
    height: 150px;
    width: 150px;
    z-index: 2;
    left: calc(50% - 75px);
    top: calc(50% - 75px);
  }

  .srcb {
    position: absolute;
    height: 100px;
    width: 100px;
    z-index: 3;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
  }

  .r-head {
    position: absolute;
    top: 367px;
    width: 100%;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    color: #fff;
  }

  .r-text {
    position: absolute;
    top: 424px;
    width: 100%;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    color: var(--custom_color);
  }

  label.datepicker {
    height: auto;
    padding: 0 2px;
    font-size: 14px;
    cursor: pointer;
    top: 7px !important;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    position: relative !important;

    img {
      top: -1px;
      margin-right: 3px;
      position: relative;
    }
  }

  .dpk-t {
    position: relative !important;
    left: 2px !important;
    top: 6px !important;
  }

  .dpk-m {
    top: 5px !important;
    left: -12px !important;
    cursor: pointer;
    font-size: 110%;
    position: relative !important;
    -webkit-user-select: none;
    user-select: none;
  }

  .dpk-s {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }

  .dpk-s, .dpk-s2 {
    option {
      outline: 0 !important;
      color: #fff !important;
      text-align: center !important;
      background: #03aa82 !important;
      border: 1px solid #12b991 !important;
    }
  }
  
  .deposit-modal-section {
    z-index: 20;

    .modal-main-img {
      width: 50px;
    }

    .upper-modal {
      position: relative;
      width: 180px;
      margin: 0 auto;
      display: flex;
      padding: 10px 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: var(--custom_color);
      border-top-left-radius: 1em;
      border-top-right-radius: 1em;

      .modal-cancel {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
      }
    }

    .lower-modal {
      padding: 30px;
      width: 280px;
      text-align: center;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      font-family: Poppins, sans-serif;
      border-radius: 1em;

      .lower-modal-content {
        width: 240px;
        h6 {
          color: #7b7e94;
          font-size: 17px;
        }

        p {
          color: #7b7e94;
          font-size: 13px;
        }
        .cm-undo, .cm-ok {
          outline: none !important;
          position: relative;
          width: 100px;
          height: 30px;
        }
        .cm-undo {
          border: 1.5px solid var(--custom_color);
          box-sizing: border-box;
          border-radius: 50px;
          background: #fff;
          float: left;
        }
        .cm-ok {
          border: 1.5px solid var(--custom_color);
          box-sizing: border-box;
          background: var(--custom_color);
          color: #fff;
          border-radius: 50px;
          float: right;
        }
      }
    }
  }

  .edit-modal-section {
    z-index: 20;
    position: absolute;
    width: 710px;
    height: 380px;
    background: #EAFFFA;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px 5px 0px 0px;
    
    &.i {
      width: fit-content;
      height: auto;
      min-width: 300px;
      min-height: 300px;
      // justify-content: center;
      // align-items: center;
      // display: flex;
      // flex-direction: column;

      // .slod {
      //   color: #444;
      //   position: absolute;
      // }
    }

    .modal-cancel {
      position: absolute;
      cursor: pointer;
      right: 20px;
      top: 18px;
    }

    .edit-header {
      height: 50px;
      width: 100%;
      background: linear-gradient(90deg, #01A880 32.82%, #2AD3AB 89.15%);
      border-radius: 5px 5px 0px 0px;
      font-size: 20px;
      line-height: 30px;
      color: #FFFFFF;
      padding: 10px 2em;
      font-family: Poppins, sans-serif;

      // .modal-cancel {
      //   position: absolute;
      //   cursor: pointer;
      //   right: 20px;
      //   top: 18px;
      // }
    }

    .edit-title {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      display: flex;
      color: #000000;
      cursor: text;
      padding: 10px 2em;
      outline: none !important;

      &.task {
        border-bottom: 0.5px solid #03ce9f38;
      }

      &.bd-t {
        border-top: 0.5px solid var(--custom_color) !important;
      }

      &.bd-b {
        border-bottom: 0.5px solid var(--custom_color) !important;
      }

      .to, .from {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        color: var(--custom_color);
        display: inline-flex;
        width: 60px;
        font-size: 14px;
        line-height: 21px;
        align-items: center;
      }

      .email, input, textarea {
        background: #DEFFF7;
        border: 1px solid var(--custom_color);
        border-radius: 2px;
        margin-left: 1em;
        height: 34px;
        width: auto;
        font-family: Poppins;
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 19px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: #000000;
        padding-left: 10px;
        padding-right: 30px;
        position: relative;

        img {
          position: absolute;
          right: 10px;
          width: 12px;
          cursor: pointer;
        }
      }

      input, textarea {
        background: transparent;
        outline: none;
        width: 100%;
        border: 0;
      }

      textarea {
        height: 150px;
      }
    }

    .edit-content {
      display: block;
      background: transparent;
      height: calc(100% - 150px);
      border-top: 0.5px solid #03ce9f38;
      border-bottom: 0.5px solid #03ce9f38;
      font-family: Poppins;
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      overflow: auto;
      padding: 10px 3em;
      outline: none !important;
      line-height: 19px;
      color: #000000;

      &.meet {
        height: calc(100% - 220px);
      }

      &.task {
        width: calc(100% - 275px) !important;
        height: calc(100% - 275px);
        padding-top: 1em;
        border: 0;
      }
    }

    .edit-settings {
      padding: 10px 3em;
      display: block;
      height: 50px;
      width: 100%;

      &.float {
        position: absolute;
        width: 275px;
        right: 0;
      }

      &.task {
        height: 70px;
        border-top: 0.5px solid #03ce9f38;
      }

      &.meet {
        padding: 10px 2em;
        height: 65px;
        border-top: 0.5px solid #03ce9f38;
      }

      .e-option {
        display: inline-block;
        padding: 0 10px;
        width: auto;

        .option {
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: #B8C4CF;
        }

        .field {
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: var(--custom_color);

          &>*:not(.dpk), #some-id {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            background: transparent;
            color: var(--custom_color);
            border: 0;
            top: 5px;
            outline: none !important;
            left: -5px;
            position: relative;

            &>*:not(.dpk) {
              font-family: Poppins;
              font-style: normal;
              font-weight: normal;
              background: transparent;
              color: inherit;
            }
          }
        }
      }
    }

    [data-placeholder]:empty:before {
      content: attr(data-placeholder);
      color: #888;
    }

    .edit-footer {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 10px 2em;

      .action {
        width: 100px;
        height: 30px;
        float: right;
        outline: none;
        background: var(--custom_color);
        border-radius: 2px;
        font-family: Poppins;
        font-style: normal;
        line-height: 19px;
        font-weight: 500;
        border: 0px;
        cursor: pointer;
        font-size: 13px;
        text-align: center;
        color: #FFFFFF;

        &.large {
          width: 170px;
        }
      }
    }
  }
}
