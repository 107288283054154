#devfx-app {
    // user-select: none;

    .sblur {
        filter: blur(3px);
    }

    .hr-resizable {
        transition: 400ms ease-in-out all;
        pointer-events: none;
        width: calc(100% - 20px);
        margin: 20px 0 10px 0;
        left: 10px;
        resize: vertical;
        overflow: auto;
        position: absolute;
        z-index: 100000;

        &.rz {
            // background-color: #fafafa33;
        }
    }

    .p_closed {
        filter: grayscale(2);
        &.ac {
            opacity: 0.5;
        }
    }

    .cpc {
        height: 10px !important;
        width: 10px !important;
        margin-right: 2px !important;
    }

    .graftype-alt {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        align-items: center;
        position: relative;
        top: 5px;
        justify-content: center;
    }

    .full, .full-auto {
        display: flex;
        width: 100%;
        padding: 0px;
        margin: 0px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    .full-auto {
        flex-direction: column;
        height: 100%;
        justify-content: space-evenly;
        .autobt {
            position: relative;
            // top: 200px;
            width: 50px;
            height: 50px;
            border: 0px;
            opacity: 0.1;
            outline: none;
            box-shadow: none;
            cursor: pointer;
            background-color: transparent;
            border-radius: 50%;
    
            &.sh {
                opacity: 1;
            }
    
            img, svg {
                width: 100%;
                height: 100%;
            }

            label {
                position: absolute;
                bottom: -25px;
                width: 100px;
                left: -25px;
                font-size: 10px;
            }
        }

        time {
            position: relative;
            font-size: 27px;
        }

        time.blink {
            position: relative;
            // background: #1FCF65;
            padding: 6px 20px;
            border-radius: 3em;
            color: #1FCF65;
        }
    }

    .marqau {
        height: 35px;
        width: 100%;
        color: #1FCF65;
        align-items: center;
        display: flex;
    }

    .blink {
        animation: blink-animation 2s infinite;
        -webkit-animation: blink-animation 2s infinite;
    }
    @keyframes blink-animation {
        50%     { opacity: 0.3; }
        100%    { opacity: 1; }
    }
    // @-webkit-keyframes blink-animation {
    //     0%      { opacity: 0.5; transform: scale(1.1); }
    //     50%     { opacity: 0.5; transform: scale(1); }
    //     100%    { opacity: 1; transform: scale(1.2); }
    // }

    .trader {
        display: flex;
        flex-direction: row;
        list-style: none;
        height: 32px;
        padding-left: 0px;
        position: relative;
        width: 240px;
        margin-top: 2px;
        margin-bottom: 4px;
        border-radius: 2em;
        background: #000000;
        user-select: none;
        border: 1px solid var(--custom_color);
        // box-shadow: inset 0px 0px 11px var(--custom_color);

        li {
            display: inline-flex;
            margin: 0px;
            cursor: pointer;
            font-size: 12px;
            z-index: 100;
            color: #fff;
            padding: 0px 10px;
            justify-content: center;
            align-items: center;
            width: 50%;
        }

        .aspan {
            height: calc(100% - 6px);
            width: 50%;
            background-color: var(--custom_color);
            color: var(--custom_alt_color) !important;
            z-index: 0;
            transition: 200ms ease-in-out all;
            left: 3px;
            border-radius: 2em;
            position: absolute;
            top: 3px;

            &.auto {
                left: calc(50% - 3px);
            }
        }
    }

    .container {
        max-width: 100vw;
        width: 100vw;
        padding: 0px;
        min-height: calc(100vh - 70px);
        height: auto;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background: #141517 !important;
            background: #D14 !important;
            border-radius: 1em;
        }

        &::-webkit-scrollbar-thumb {
            cursor: pointer;
            background: #141517 !important;
            background: #D14 !important;
            border-radius: 1em;
        }

        .trade-history {
            padding: 0px !important;
            .table-responsive {
                width: 100%;
                height: 175px;
                &.isa {
                    height: 235px;
                }
            }

            &.exit {
                .table-responsive {
                    height: calc(100vh - 65px);
                }
            }
        }

        .trade-page {
            display: flex;
            padding: 0px;
            flex-direction: row;

            #trade-body, #market-menu {
                min-height: calc(100vh - 70px);
                justify-content: center;
                display: inline-flex;
                position: relative;
                align-items: center;
                justify-content: flex-start;
                flex-direction: column;

                .cancel {
                    top: 15px;
                    right: 15px;
                    width: 15px;
                    height: 15px;
                    cursor: pointer;
                    position: absolute;
                }
            }

            .favourites {
                padding-left: 20px;
                display: flex;
                width: 100%;
                flex-direction: column;
                height: auto;
                list-style: none;
                margin-top: 60px;
                padding-top: 5px;
                justify-content: flex-start;
                align-items: flex-start;
                border-bottom: 1px solid #3c3b3b;

                h4 {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 25px;
                    color: #FFFFFF;
                }

                .favourite-list  {
                    padding-left: 0px;
                    display: flex;
                    width: 100%;
                    height: 30px;
                    flex-direction: row;
                    list-style: none;
                    cursor: pointer;
                    font-size: 16px;
                    color: #B2B7C0;
                    margin-bottom: 0px;
                    word-wrap: normal;
                    top: -4px;
                    position: relative;
                    
                    overflow-x: auto;
                    overflow-y: hidden;
                    white-space: nowrap;

                    li {
                        display: inline-flex;
                        margin-right: 1em;
                        justify-content: center;
                        align-items: center;
                        * {
                            padding-right: 10px;
                        }
                        small {
                            color: #1FCF65;
                            &.red {
                                color: #FF2E53;
                            }
                        }
                    }
                     
                    &::-webkit-scrollbar {
                        width: 8px;
                        height: 1px;
                        background: #000;
                        border-radius: 1em;
                    }
        
                    &::-webkit-scrollbar-thumb {
                        cursor: pointer;
                        background: #000 !important;
                        border-radius: 1em;
                    }
                }
            }

            .favourites, .trade-comp-container {
                width: calc(100% - 200px) !important;
            }

            .scrollable-1.full {
                .favourites, .trade-comp-container {
                    width: 100% !important;
                }
            }

            #trade-body {
                // width: calc(100vw - 331px - 5px);
                // justify-content: flex-start;
                // align-items: flex-start;
                // padding: 0px;
                width: calc(100vw - 331px - 5px);
                justify-content: flex-start;
                align-items: flex-start;
                padding: 0px;
                position: relative;
                height: calc(100vh - 70px);
                overflow: auto;

                .scrollable {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: auto;
                    overflow-x: hidden;
                }

                .page-header {
                    display: flex;
                    width: calc(100vw - 331px - 5px);
                    flex-direction: row;
                    height: 60px;
                    color: #B2B7C0;
                    border-bottom: 1px solid #3c3b3b;
                    margin-bottom: 0px;
                    position: fixed;
                    top: 70px;
                    border-radius: 0px;
                    z-index: 1000;
                    background: #141517;

                    .tu-filter {
                        width: 500px;
                        display: flex;
                        .t-accs {
                            background: transparent;
                            border: 1px solid #363739;
                            border-radius: 0px;
                            width: 250px;
                            height: 38px;
                            cursor: pointer;
                            text-align: center;
                            outline: none !important;
                            font-size: 14px;
                        }
                    }

                    &.isa {
                        width: 100% !important;
                        position: relative;
                        top: 0px;
                        z-index: 100000000000;
                        justify-content: space-between;
                    }

                    .isg {
                        width: 250px;
                        position: relative;
                        input {
                            background: transparent;
                            border: 1px solid #363739;
                            border-radius: 0px;
                            font-size: 14px;
                            height: 38px;
                        }
                        .si {
                            position: absolute;
                            cursor: pointer;
                            top: 11px;
                            height: 14px;
                            right: 5px;
                        }

                        .gr-dropdown.accs {
                            display: none;
                            left: unset;
                            z-index: 1;
                            padding: 0px;
                            right: unset;
                            top: 40px;
                            min-width: 250px;
                            background: #363636;
                            // background: #000;
                            box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);

                            .cgt {
                                display: inline-block;
                                width: 250px;
                            }

                            &._big {
                                width: 1060px;
                                padding: 25px;
                                right: -250px;
                                top: 55px;
                                overflow: auto;
                                max-height: 500px;
                                .cgt {
                                    float: left;
                                    padding-top: 4px;
                                    padding-bottom: 4px;
                                }
                            }

                            &._active {
                                display: block;
                            }
                        }
                    }

                    ul {
                        display: flex;
                        flex-direction: row;
                        padding-left: 8px;
                        height: 60px;
                        padding-top: 0px;
                        cursor: pointer;
                        font-size: 16px;
                        margin: 0 !important;
                        list-style: none;
                    }

                    li {
                        padding: 5px 0;
                        margin: 0 25px 0 0;
                        display: inline-flex;
                        justify-content: flex-start;
                        flex-direction: column;
                        letter-spacing: 1px;
                        span, b {
                            text-align: left;
                        }
                        span {
                            font-size: 10px;
                            margin-bottom: 5px;
                        }
                        b {
                            color: #fff;
                        }
                        .pl, .pr {
                            &.profit {
                                color: #1FCF65;
                            }
                            &.loss {
                                color: #FF2E53;
                            }
                        }
                    }
                }
            }

            .scrollable-1 {
                display: flex;
                flex-direction: column;
                position: relative;
                width: 100%;
                height: auto;
            }

            .order-book {
                position: absolute;
                top: -1px;
                right: 0px;
                z-index: 10000;
                width: 195px;
                display: flex;
                flex-direction: column;
                background: transparent;
                background: #141517;
                border-left: 1px solid #3c3c3c;
                height: 100%;

                h5 {
                    height: 40px;
                    padding-top: 10px;
                }
                h6 {
                    height: 40px;
                    display: flex;
                    margin: 0;
                    height: 40px;
                    align-items: center;
                    justify-content: space-evenly;
                }

                ul {
                    display: flex;
                    list-style: none;
                    padding-left: 0px;
                    width: 100%;
                    height: calc(50% - 40px);
                    overflow: hidden;
                    flex-direction: column;
                    border-top: 0.5px solid #3c3c3c;
                    border-bottom: 0.5px solid #3c3c3c;
                    // padding-top: 10px;
                    // padding-bottom: 10px;
                    margin: 0px;
                    overflow: hidden;

                    li {
                        display: flex;
                        flex-direction: row;
                        padding: 2px 1em;
                        margin: 0;
                        position: relative;
                        align-items: center;
                        justify-content: space-between;
                        height: 10%;

                        .perc {
                            height: 100%;
                            z-index: 0;
                            right: 0px;
                            top: 0px;
                            display: flex;
                            position: absolute;
                            padding-right: 0px;
                            // background: #bab9b912;
                            background: #ff2e5317;

                            &.alt {
                                background: #1fcf651a;
                            }
                        }
                        * {
                            font-size: 12px;
                        }

                        .rt-1 {
                            color: #DF3636;
                        }

                        .rt-2 {
                            color: #1FCF65;
                        }

                        .rt-0 {
                            font-size: 12px;
                            color: #A09F9F;
                        }
                    }
                }

                &.alt {
                    position: relative;
                    display: flex;
                    width: 100vw;
                    height: 255px;
                    overflow: hidden;
                    margin-bottom: 50px;
                    flex-direction: column;

                    h5 {
                        width: 100%;
                        display: flex;
                        margin-top: 10px;
                        font-weight: 700;
                        text-align: center;
                        justify-content: center;
                        border-bottom: 1px solid #252525;
                        padding-bottom: 5px;
                    }

                    .ob {   
                        display: flex;
                        width: 100vw;
                        flex-direction: row;

                        ul {
                            border: 0px;
                            width: 50%;
                            li {
                                height: 70px;

                                &:first-child {
                                    border-bottom: 1px solid #252525;
                                }
                            }
                        }
                    }
                }

                &.fhi {
                    height: 100% !important;
                }
            }

            .page-header {
                width: calc(100vw - 331px - 5px - 200px) !important;
            }

            #market-menu {
                border-left: 1px solid #3C3C3C;
                width: 330px;
                padding: 5px 0 0;
                background: #141517;
                overflow-y: auto;
                height: calc(100vh - 93px - 50px - 5px);

                .bs-section {
                    display: block;
                    text-align: center;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    overflow: auto;
                    height: calc(100% - 230px);
                }

                .i-name {
                    font-size: 20px;
                    line-height: 24px;
                    font-weight: bold;
                    cursor: pointer;
                    display: flex;
                    margin-top: 2px;
                    margin-bottom: 2px;
                }

                .irow {
                    width: 230px;
                    border-bottom: 1px solid #DADADA;

                    label {
                        color: #fff;
                        font-size: 16px;
                        cursor: pointer;
                        line-height: 20px;
                        padding-bottom: 4px;
                        position: relative;
                        top: 3px;
                        border-bottom: 3px solid transparent;
                    }

                    .ibuy.active {
                        // top: 0px;
                        color: #1FCF65;
                        border-bottom: 3px solid #1FCF65;
                    }

                    .isell.active {
                        // top: 0px;
                        color: #FF2E53;
                        border-bottom: 4px solid #FF2E53;
                    }
                }

                

                .buy-btn {
                    background-color: #1FCF65 !important;
                    border: 1px solid #1FCF65 !important;
                    box-shadow: none !important;
                    outline: none !important;
                    height: 35px !important;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                }

                .sell-btn {
                    background-color: #FF2E53 !important;
                    border: 1px solid #FF2E53 !important;
                    box-shadow: none !important;
                    outline: none !important;
                    height: 35px !important;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                }

                .mkic {
                    filter: grayscale(1);
                    border-radius: 0px !important;
                    background: #393939d9 !important;
                    border: 1px solid #f1f1f13b !important;
                }
                
                .block {
                    display: flex;
                    width: calc(100% - 2rem);
                    flex-direction: column;
                    padding: 10px 8px;
                    padding: 5px;
                    margin: 0;

                    .vll, .vli {
                        width: auto;
                        height: 40px;
                        background: transparent;
                        color: #fff;
                        margin: 0 auto;
                        font-size: 14px;
                        display: flex;
                        text-align: center;
                    }

                    .vll {
                        height: 25px;
                    }

                    .vli {
                        padding-right: 15px;
                    }

                    .ivlv {
                        position: absolute;
                        right: 100px;
                        height: 12px;
                        width: 12px;
                        cursor: pointer;
                        &.u {
                            top: 3px;
                        }
                        &.d {
                            bottom: 3px;
                        }
                    }

                    .vli {
                        width: 90px;
                        height: 30px;
                        border: 1px solid #A09F9F;
                    }

                    ul {
                        display: flex;
                        padding-left: 0px;
                        margin-bottom: 0px;
                        flex-direction: column;

                        li {
                            display: inline-flex;
                            margin-bottom: 2%;
                            justify-content: space-between;

                            span {
                                font-size: 14px;
                            }

                            span.alt {
                                text-align: right;
                                border: 0px;
                                font-size: 14px;
                            }
                        }
                    }

                    &.matb {
                        ul {
                            li {
                                margin-bottom: 0.3%;
                            }
                        }
                    }
                }

                .search-g {
                    width: 100%;
                    padding: 8px 24px 4px;
                    border-top: 1px solid #3C3C3C;
                    margin-bottom: 0px !important;

                    input {
                        color: #fff;
                        font-size: 14px;
                        border-width: 1px;
                        border-radius: 0px;
                        border-color: #767676;
                        background-color: transparent;
                    }

                    img {
                        position: absolute;
                        bottom: 14px;
                        width: 15px;
                        right: 35px;
                    }
                }
                

                .form-group {
                    position: relative;
                    margin-bottom: 15px;

                    .grl {
                        position: absolute;
                        color: #686767;
                        left: 1em;
                        bottom: 4px;
                        font-size: 14px;
                    }

                    &.aamt {
                        display: flex;
                        margin-top: 5px;
                        margin-bottom: 5px;
                        justify-content: space-between;

                        button.auto-amt {
                            background-color: transparent;
                            border-radius: 2em;
                            border-radius: 0px;
                            height: 80px;
                            color: #fff;
                            font-size: 12px;
                            height: 30px;
                            padding: 2px 4px;
                            border: 1px solid #767676;
                            border: 0px;
                            border-bottom: 3px solid transparent;
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            box-shadow: none !important;

                            &.active {
                                font-weight: 600;
                                color: #1fcf65;
                                border-bottom: 3px solid #1fcf65;
                                &._sel {
                                    color: #FF2E53;
                                    border-bottom: 3px solid #FF2E53;
                                }
                            }
                        }

                        input.auto-amt {
                            background-color: transparent;
                            border-radius: 0px;
                            padding: 2px 10px;
                            height: 25px !important;
                            color: #fff;
                            font-size: 11px;
                            text-align: center;
                            height: 30px;
                            width: calc((100% / 3) - 15px);
                            border: 1px solid #ffffff63;
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            box-shadow: none !important;

                            &.active {
                                background-color: #00000042;
                                // border: 1px solid var(--custom_color)ba !important;
                            }
                        }
                    }
                }

            
                .dform {
                    width: calc(100%);
                    margin-bottom: 5px;

                    .form-group {
                        display: flex;
                        margin-bottom: 5px;
                        flex-direction: column;

                        &.hide {
                            display: none;
                        }

                        label {
                            margin-bottom: 5px;
                            letter-spacing: 2px;
                            text-transform: initial;
                        }

                        input {
                            height: 30px;
                            text-align: right;
                            font-size: 14px;
                            font-weight: bold;
                            border-radius: 0px !important;
                        }
                    }
                }
            }



            .i-list {
                padding-left: 0px;
                list-style: none;
                width: 100%;
                padding: 0 1em;
                margin-bottom: 0px;
                overflow: auto;
                height: 180px;
                // overflow-y: auto;
                // height: calc(100vh - 93px - 50px - 5px);

                li {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    padding-bottom: 5px;
                    padding-top: 5px;
                    height: max-content;
                    border-bottom: 1px solid #5d5d5d52;

                    &:disabled {
                        opacity: 0.2;
                        filter: grayscale(1);
                    }

                    .i-top, .i-act, .i-down {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                    }

                    .i-top, .i-down {
                        color: #A09F9F;
                        margin-top: 5px;
                        margin-bottom: 1px;
                        span {
                            font-size: 12px;
                            // letter-spacing: 1px;
                            &.i-name {
                                letter-spacing: 2px;
                                font-size: 12px !important;
                                color: #fff;
                            }
                            &.ic {
                                display: inline-flex;
                                justify-content: flex-end;
                                align-items: flex-end;

                                img {
                                    height: 20px;
                                    width: 20px;
                                    bottom: 4px;
                                    cursor: pointer;
                                    position: relative;
                                }
                            }
                        }
                    }

                    .i-down {
                        margin-top: 10px;
                    }

                    .i-top {
                        margin-top: 0px;
                    }

                    .i-act {
                        .sell-act, .buy-act {
                            color: #fff;
                            outline: none !important;
                            box-shadow: none !important;
                            border: 0px !important;
                            width: 110px;
                            height: 42px;
                            cursor: pointer;
                            position: relative;
                            border-radius: 0px;
                            background-color: #FF2E53 !important;
                            display: inline-flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            padding-top: 5px;
                            padding-left: 1em;

                            .t, .r {
                                text-align: left;
                                font-size: 12px;
                                width: 100%;
                            }
                            .r {
                                font-size: 10px;
                            }

                            img {
                                position: absolute;
                                right: 10px;
                                top: 10px;
                            }
                        }
                        .buy-act {
                            background-color: #1FCF65 !important;

                            .t, .r {
                                text-align: right;
                                font-size: 12px;
                                width: 100%;
                            }

                            img {
                                position: absolute;
                                right: unset;
                                left: 10px;
                                top: 10px;
                            }
                        }
                        .ait-act {
                            display: inline-flex;
                            height: 42px;
                            width: calc(100% - 220px);
                            justify-content: center;
                            align-items: center;
                            background-color: #1E1F23;
                            img {
                                width: 16px;
                                height: 18px;
                            }
                        }
                    }
                }
            }

        ::-webkit-scrollbar {
            width: 8px;
            background: #141517;
            border-radius: 1em;
        }
        
        ::-webkit-scrollbar-track {
            background: #141517 !important;
            border-radius: 1em;
        }
        
        ::-webkit-scrollbar-thumb {
            cursor: pointer;
            background: #555 !important;
            border-radius: 1em;
        }
        @media only screen and (min-height: 830px) {
            #market-menu .bs-section {
                display: flex !important;
            }
        }
        @media only screen and (min-height: 900px) {
            #market-menu .block ul li, .form-group.aamt {
                margin-bottom: 4.5% !important;
            }
        }
        @media only screen and (max-width: 1500px) {
            .bs-section {
                // padding-top: 100px;
            }
            th, td {
                font-size: 12px !important;
                * {
                    font-size: 100% !important;
                }
            }
            th {
                font-size: 10px !important;
            }
            #market-menu .block ul li {
                // margin-bottom: 0px !important;
                span {
                    font-size: 12px !important;
                }
            }
            
        }
        }
    }
}


.b-tabs {
    position: fixed;
    display: none;
    padding: 0px;
    margin: 0px;
    width: 100%;
    bottom: 0px;
    height: 80px;
    flex-direction: row;
    background: #1E1F23;
    // z-index: 10000000000000;
    z-index: 10000;
    box-shadow: 0px -13px 36px rgba(52, 89, 191, 0.05);
    border-radius: 0px;

    li {
        display: inline-flex;
        width: calc(100% / 5);
        flex-direction: column;
        cursor: pointer;
        align-items: center;
        justify-content: center;

        span {
            margin-top: 5px;
            font-size: 12px;
            color: #747E98;
        }

        &._active {
            span {
                color: #fff !important;
            }
        }
    }

    &.alt {
        li {
            width: calc(100% / 4) !important;
        }
    }
}