.top-nav {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 10px 40px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.umute {
  // position: absolute;
  z-index: 10000;
  height: 35px;
  width: 35px;
  right: 5px;
  bottom: 5px;
  padding: 8px;
  // box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.6);
  cursor: pointer;
  // border-radius: 50%;
  transition: 250ms ease-in all;
  // transform: scale(.8);
  // opacity: 0.7;
  &:hover {
    opacity: 1;
    transform: scale(1);
  }
}

.__newMessage ,
.__newNotice {
  top: -5px;
  left: 1em;
  height: 20px;
  width: 20px;
  font-weight: bold;
  font-size: 12px;
  position: absolute;
  box-shadow: 0px 2px 3px #000;
  border: 1px solid #c30e0e;
  background: red;
  justify-content: center;
  font-family: 'Poppins';
  border-radius: 50%;
  align-items: center;
  display: flex;
}

.timeout {
  cursor: pointer;
  position: absolute;
  left: calc(50% - 75px);
  width: 150px;
  text-align: center;
  height: 33px;
  top: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  // top: 0px;
  z-index: 100;
  color: #fff;
  font-size: 1em;
  // border-radius: 0 0 1em 1em;
  // border-radius: 1em;
  border: 0px;
  cursor: pointer;
  font-family: 'Poppins';
  background: #393939;
  background: transparent;
  text-transform: capitalize;

  img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  &.aff {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    left: calc(50% - 100px);
    width: 200px;
    top: 5px;
    flex-direction: column;
    * {
      font-size: 1.1em !important;
    }
  }
}

.__newNotice {
  top: -4px;
  height: 10px;
  width: 10px;
  left: .9em;
  left: 77%;
  font-size: .5em;
  border: 1px solid #0989d1 !important;
  background: #5aabe7 !important;
}

.dropdown {
  z-index: 2 !important;
  cursor: pointer;
  position: relative;
  width: 30px !important;

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
}

#acfb {
  width: 100%;
  display: flex;
  background: #00a880;
  font-family: 'Poppins';
  cursor: pointer;
  color: #fff;
}

.top-nav-list {
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
  padding: 0;
  z-index: 100000000000000000000000;

  // li:not(:nth-child(3)) {
  //   width: 20px;
  //   height: 20px;

  //   img {
  //     width: 20px;
  //     height: 20px;
  //   }
  // }

  .save-changes {
    width: 150px;
    height: 30px;
    background: #00A880;
    border-radius: 3px;
    border: 0px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none !important;
    color: #FFFFFF;
  }

  .tcli {
    width: 100px;
    height: 30px;
    background: #212121;
    border-radius: 2em;
    border: 0px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    border: 1px solid #ffffff2e;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none !important;
    color: #FFFFFF;
    margin-right: 15px;

    &:hover {
      background: #000;
    }
  }

  .admin-search-input {
    position: relative;
    background-color: inherit;
    width: 260px;
    height: 50px;
    margin-left: 1em;
    background: #00A880;
    border-radius: 3px;
    font-family: Poppins, sans-serif;

    img {
      position: absolute;
      right: 10px;
      top: 6px;

      width: 15px;
      height: 15px;
    }

    input {
      background-color: inherit;
      border: none;
      height: 100%;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: center;
      color: #FFFFFF;
    }

    input::placeholder {
      color: #BAFFEE !important;
    }
  }

  .admin-nav-plus {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    margin-left: 1em;
    background: #00A880;
    border-radius: 5px;
  }

  li {
    color: #ffffff;
    font-family: Poppins, sans-serif;
    font-size: 13px;
  }

  .logout-btn {
    cursor: pointer !important;
  }


  &._admin {
    width: 100% !important;
    position: relative;
    display: flex;
    justify-content: flex-end;

    .dropdown {
      position: absolute;
      left: 0;

      &>img {
        height: 45px;
        width: 45px;
        margin-top: 5px;
        border-radius: 2em;
      }

      .profile-dropdown {
        right: unset !important;
        left: -5px !important;
        top: 50px !important;
      }

      .profile-dropdown::before {
        right: unset !important;
        left: 10px !important;
      }
    }

    .nav-aname {
      position: absolute;
      left: 60px;
      display: flex;
      flex-direction: column;

      .fl {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #FFFFFF;
      }
      .em {
        font-family: Poppins;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        color: #ebebeb;
      }
    }
  }
}

// .dropdown:hover .profile-dropdown {
//   display: block !important;
// }

.overlay.drop {
  background-color: rgba(0, 0, 0, 0);
}

.dropdown.hover .profile-dropdown {
  display: block !important;
}

.live-chat-mbox.mbox .message-dropdown {
  display: block !important;
}

@media screen and (max-width: 600px) {
  .top-nav {
    .top-nav-list {
      width: 60%;
    }
  }

  .hide-mobile {
    display: none;
  }
}
