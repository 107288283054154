#users-container {
	padding: 2em !important;
	width: 100%;
	position: relative;
    height: calc(100% - 120px);
    display: flex;


	.users-section-right {
		width: 100%;

		&.shared {
			width: calc(100% - 342px);
			margin-left: 342px;
		}

		.pagination-div {
		    justify-content: center;
		    margin-top: 1em;
		}
	}

	.table-header li.check-row, .table-body li.check-row {
		padding-left: 0em !important;
		width: 50px !important;
		position: relative !important;
		top: -5px;
		left: 0px;
		cursor: pointer;
	}
}

.ed-ea {
	position: relative;
    left: 5px;
    color: #fff;
    text-decoration: none !important;
    font-weight: 300;
    margin-right: 20px;
    border: 1px solid #838383;
    padding: 3px 5px;
    border-radius: 4px;
    font-size: 12px;

	&:hover {
		background-color: #838383;
	}
}

img.veri {
	width: 20px;
    height: 20px;
    margin-left: 5px;
}

.ent {
	background: #3f51b5;
    color: #fff;
    border: 1px solid #000;
    padding: 8px 1em;
    border-radius: 4px;
    display: inline-flex;
	justify-content: center;
	align-items: center;
    cursor: pointer;

	img {
		width: 16px;
		height: 16px;
		margin-right: 5px;
	}
}

html, body, #root {
    overflow: hidden;
}