.valu {
	outline: none !important;
	display: inline;
  font-family: 'Poppins';
	padding: 0;
	border: 0;
	background: transparent;
}

select.lev {
	border: 0;
	outline: none !important;
	background: transparent;
	color: #fff;
	font-family: 'Poppins';

	option {
		background: #00A880;
	}
}

.direction, .directionSell {
  height: 25px !important;
}

.page-title {
	display: flex;
	margin-top: 16px;
	color: #fff;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 32px;
	line-height: 48px;
	color: #FFFFFF;
}

.pointer {
  cursor: pointer;
}

/* The checkbox-container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.min-100 {
		display: inline-block;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid #03CF9E;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 5px;
  top: 2px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  border-color: #03CF9E;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.col-12:not(._alt) {
	overflow: auto !important;
}

.checkbox-container-2 .checkmark:after {
  border-color: #C4C4C4;
}

.checkbox-container-2 .checkmark {
  border: 1px solid #C4C4C4;
 }

.txt-light {
	color: #FFF;
	font-family: Poppins;
	font-style: normal;
}

.txt-warning {
	color: #FFE602;
	font-family: Poppins;
	font-style: normal;
}

.txt-info {
	color: #68C7E4;
	font-family: Poppins;
	font-style: normal;
}

.txt-success {
	color: #03CF9E;
	font-family: Poppins;
	font-style: normal;
}

.txt-default {
	color: #C4C4C4;
	font-family: Poppins;
	font-style: normal;
}

.txt-danger {
	color: #FF1E1E;
	font-family: Poppins;
	font-style: normal;
}

.col-12:not(._alt) {
	height: calc(100vh - 70px) !important;
}

.dash-container.admin .col-12:not(._alt) {
  height: calc(100vh - 50px) !important;
}

.news-section-left::-webkit-scrollbar, .col-12::-webkit-scrollbar {
  width: 8px;
  background: #000;
  border-radius: 1em;
}

.news-section-left::-webkit-scrollbar-track, .col-12::-webkit-scrollbar-track {
  background: #000 !important;
	border-radius: 1em;
}

.news-section-left::-webkit-scrollbar-thumb, .col-12::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #393932  !important;
  border-radius: 1em;
}