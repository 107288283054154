.message-dropdown {
    position: absolute;
    cursor: pointer;
    display: none;
    right: -121px;
    top: 40px;
    background: #1E1F23;
    border-radius: 3px;
    z-index: 20000000000 !important;
    width: 280px;
    box-shadow: 1px 2px 10px #212121;
    width: 280px;
    height: auto;
    border-radius: 3px;
  
    &.user {
      right: -5px;
    }
  
    .section1 {
      width: 280px;
      height: 50px;
      position: relative;
      background: #303030;
      border-radius: 3px 3px 0px 0px;
      padding: 10px 8px;
      align-items: center;
      display: flex;
      flex-direction: revert;
      justify-content: flex-start;

      .admt {
        font-size: 14px;
        color: #B2B2B2;
        padding-left: 10px;
      }

      img.udp {
        width: 30px;
        height: 30px;
        display: inline-flex;
        margin-left: 1em;
      }
  
      img.dbn {
        position: absolute;
        right: 1em;
        top: 17px;
        width: 15px;
        height: 13.57px;
      }
  
      .grey {
        color: #A09F9F;
      }
  
      div {
        margin: 10px 0 0 0;
      }
  
      h3 {
        font-size: 17px;
      }
  
      p {
        font-size: 12px;
      }
  
      h2 {
        font-size: 25px;
      }
    }
  
    .section2 {
      width: 100%;
      height: auto;
      height: 250px;
      // overflow: auto;
  
      p {
        font-size: 12px;
        color: #A09F9F;
        margin-bottom: 8px;
      }
  
      ul {
        width: 100%;
        padding-left: 0px;
        list-style-type: none;
        padding-top: 1em;
        height: 100%;
        overflow: auto;
  
        li {
          width: 100% !important;
          margin-bottom: 5px;
          font-weight: 300 !important;
          cursor: pointer;
  
          .m-time {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 12px;
            display: flex;
            margin: 10px;
            align-items: center;
            color: #A7A7A7;
          }
  
          &.y-msg {
            padding: 5px 1em;
            display: flex;
  
            div.m-text {
              word-break: break-all;
              padding: 5px 10px;
              width: auto;
              width: auto;
              // min-width: 140px;
              min-width: 80px;
              font-size: 12px;
              display: inline-flex;
              background: #303030;
              border-radius: 3px;
              position: relative;
  
              &::before {
                position: absolute;
                width: 17px;
                height: 20px;
                left: -4px;
                top: -5px;
                content: '';
                z-index: 0;
                border-top: 10px solid transparent;
                border-right: 13px solid #303030;
                border-bottom: 14px solid transparent;
                transform: rotate(-52deg);
              }
            }
          }
  
          &.m-msg {
            padding: 10px 1em;
            display: flex;
            justify-content: flex-end;
  
            div.m-text {
              word-break: break-all;
              float: right;
              padding: 5px 10px;
              width: auto;
              // min-width: 140px;
              min-width: 80px;
              font-size: 12px;
              display: inline-flex;
              background: var(--custom_color);
              border-radius: 3px;
              position: relative;
  
              &::before {
                position: absolute;
                width: 17px;
                height: 20px;
                right: 0px;
                top: -10px;
                content: '';
                z-index: 0;
                border-top: 0px solid transparent;
                border-right: 20px solid var(--custom_color);
                border-bottom: 18px solid transparent;
                transform: rotate(90deg);
              }
            }
          }

          .iim {
            padding: 0px !important;
          }
        }
      }
    }
  
    .section3 {
      display: flex;
      width: 100%;
      height: 50px;
      padding: 10px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-top: 1px solid #dadada8a;
  
      input {
        width: 223px;
        height: 30px;
        border: 0;
        color: #fff;
        padding: 3px;
        background: transparent;
        outline: none !important;
      }
  
      .send {
        width: 35px;
        border: 0;
        height: 35px;
        margin-left: 10px;
        outline: none;
        display: inline-flex;
        cursor: pointer;
        background: var(--custom_color);
        border-radius: 6px;
        justify-content: center;
        align-items: center;
      }
    }
  }
  
  .message-dropdown::before {
    content: '';
    background: #303030;
    width: 15px;
    height: 15px;
    position: absolute;
    top: -4px;
    right: 125px;
    z-index: -1;
    display: block;
    transform: rotate(45deg);
  }
  
  .live-chat-mbox.mbox .message-dropdown.mobile {
    display: block !important;
    width: 98%;
    right: 1% !important;
    top: 48px;
    bottom: 2px;
    position: fixed;
  }
  
  .message-dropdown.mobile {
  
    .section1 {
      width: 100%;
    }
  
    .section2 {
      height: calc(100% - 100px);
    }
  
    .section3 input {
      width: calc(100% - 70px);
    }
  }
  
  .message-dropdown.mobile::before {
    background: #ffffff;
    right: 55%;
  }