#news-container-admin {
	padding: 2em !important;
	width: 100%;
	position: relative;
    height: auto;
    display: flex;
    height: calc(100% - 260px);

	.f9 {
		background-color: #f9f9f9;
	}

    .loader-container {
    	position: absolute;
	    top: 30%;
	    z-index: 100000000;
	    left: 46.5%;
    }

	a.publish-btn {
		position: fixed;
		width: 150px;
		height: 50px;
		flex-direction: column;
		justify-content: center;
		right: calc(50% - 75px);
		bottom: 20px;
		border: 0;
		display: flex;
		flex-direction: row;
		background: #03CF9E;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.81);
		border-radius: 80px;
		border: 1px solid #10785f;
		font-family: Poppins;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
		align-items: center;
		color: #FFFFFF;

		img {
		    padding: 5px;
		    position: relative;
		    width: 30px;
		    border-radius: 50%;
		    height: 30px;
		    left: 6px;
		    background: #fff;
		}
	}

	.news-section-left {
		padding-right: 6%;
		width: 60%;
		float: left;
		overflow: auto;

		img.ifm {
			width: 35%;
			display: inline-block;
			float: left;
			background-color: #f9f9f9;
			margin-right: 2em;
			margin-bottom: 1em;
		}
	}

	.news-section-right {
		padding: 0 2em;
		width: 40%;
		float: left;
		
		ul.todays-ul {
			display: block;
			width: 100%;
			margin-top: 1em;
			list-style: none;
			margin-left: 0;
			padding-left: 0;
			list-style: none;
			border: .5px solid #CCCCCC;
			border: 0;
			box-shadow: 1px 2px 3px 1px #070707;
			border-radius: 5px;

			li.todays-li {
				padding: 1em;
				background: #004448;
				justify-content: end;
				display: flex;
				border: .5px solid #CCCCCC;
				border: 0;

				img {
				    height: 80px;
				    float: left;
				    min-width: 80px;
				    padding-right: 0;
				}
				span {
				    margin-left: 2em;
					float: left;
					width: -webkit-fill-available;
					font-family: Poppins;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 21px;
					color: #FFFFFF;
					position: relative;
				}
			}
		}

		.fti {
			width: 100%;
			margin-top: 1em;
		}

	}

	h1.news-header {
		font-family: Poppins;
		font-weight: bold;
		font-size: 20px;
		line-height: 30px;
		color: #FFFFFF;
	}

	.read-more {
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
		align-items: center;
		text-align: right;
		color: #1FCF65;
	}

	p.news-content {
		font-family: Poppins;
		min-height: 120px;
		font-size: 14px;
		line-height: 21px;
		margin-top: 1em;
		font-weight: bold;
		color: #8A8A8A;
	}

	.similar-stories {
		margin-top: 3em;

		h1 {
			font-family: Poppins;
			font-style: normal;
			font-weight: 500;
			font-size: 22px;
			line-height: 32px;
			display: flex;
			align-items: center;
		}

		ul.stories {
			display: flex;
			width: 100%;
			margin-top: 1em;
			list-style: none;
			margin-left: 0;
			padding-left: 0;

			li {
				width: 240px;
				float: left;
				margin-right: 2em;

				p {
					font-family: Poppins;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 21px;
					color: #FFFFFF;
				}

				p.n-title {
				    overflow: hidden;
				    height: 80px;
				}
			}
		}

		.ss-thumbnail {
			width: 100%;
			min-width: 200px;
			max-width: 100%;
			border-radius: 5px;
			background-color: #f9f9f9;
		}
	}

	.btn.del {
		width: 73px;
		height: 30px;
		float: right;
		cursor: pointer;
		border: 0px solid;
		color: #fff;
		margin-top: 5px;
		background: #FF5722;
		border-radius: 2px;
		position: absolute;
		bottom: 0;
		right: 100px;
		background: #f23426;
	}

	.btn.read {
		width: 73px;
		height: 30px;
		float: right;
		cursor: pointer;
		border: 0px solid;
		color: #fff;
		margin-top: 5px;
		background: #008EDE;
		border-radius: 2px;
	}
	.btn-bottom {
		position: absolute;
		bottom: 0;
		right: 0;
	}

	.pagination-div {
	    justify-content: center;
	    margin-top: 1em;
	}
}

@media screen and (max-width: 1000px) {
	#news-container {
		.read.btn-bottom {
			position: relative !important;
		}
		.similar-stories {
			overflow: auto;
		}
	}
}

@media screen and (max-width: 900px) {
#news-container {
	display: block !important;
	.news-section-left,
	.news-section-right {
		padding: 0 !important;
		width: 100% !important;
	}
	.news-section-right { 
		margin-top: 2em;
	}
}
}


@media screen and (max-width: 700px) {
#news-container {
	.news-section-left {
		img.ifm {
			display: block;
			width: 100%;
		}
	}

}

}