.profile-notes {


	ul {

		display: block;
		list-style: none;
		padding-left: 0;
		width: 100%;

		li {
			padding: 1em;
			background: #006066;
			border-radius: 5px;
			width: 44%;
			height: 200px;
			position: relative;
			margin: 1em 3%;
			display: inline-flex;
			flex-direction: row;

			&.upc {
				width: 94%;
				height: auto;
				position: relative;
			    // background: gray !important;

			    h4 {
			    	text-align: left;
			    }

			    .d-act {
				    position: absolute;
				    right: 0;
				    width: auto;
				    display: inline-flex;
				    flex-direction: row;
				    top: -20px;

				    img {
				    	margin-left: 10px;
				    }
			    }
			}

			h4 {
				font-family: Poppins;
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 30px;
				display: flex;
				margin-top: -7px;
				align-items: center;
				text-align: center;
				color: #FFFFFF;
				margin-bottom: 5px;
			}

			div {
				display: inline-flex;
				flex-direction: column;
				align-items: center;

				&.upc {
					align-items: unset;
					text-align: left !important;

					h6 {
						font-family: Poppins;
						font-style: normal;
						overflow: hidden;
						font-weight: 300;
						font-size: 13px;
						line-height: 19px;
						margin-top: 1em;
						color: #C4C4C4;
						margin-left: -2.5em;
				    	
				    	img {
							display: inline-block;
							width: 18px;
							position: relative;
							top: 6px;
							margin-right: 10px;
							margin-left: 0px;
				    	}
					}
				}

				p {
					font-family: Poppins;
					font-style: normal;
					overflow: hidden;
					font-weight: 300;
					font-size: 13px;
					line-height: 19px;
					margin-top: 1em;
					color: #C4C4C4;
				}

				&.d-note {
					margin-left: 1em;
					width: calc(100% - 90px);
					text-align: center;
				}

				&.d-act {
					align-items: center;
				    float: right;
				    padding: 1em;
				    height: 80%;
				    width: 74px;
				    justify-content: center;

				    img {
				    	cursor: pointer;
				    	margin-top: 10px;
				    	margin-bottom: 10px;
				    }
				}
			}

			.d-foot {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 50px;
				width: 100%;
				display: flex;
				flex-direction: row;
				background: #00A880;
				align-items: center;
				justify-content: center;
				text-align: center;
				border-radius: 0px 0px 5px 5px;

				p {
					width: 100%;
					margin-top: 0;
				}

				.creator {
					font-family: Poppins;
					font-weight: 300;
					font-size: 12px;
					line-height: 18px;
					color: #A7FF37;
					display: inline-flex;
				}

				.modified {
					display: inline-flex;
					font-family: Poppins;
					font-style: italic;
					font-weight: 300;
					font-size: 12px;
					line-height: 18px;
					float: right;
					padding-right: 1em;
					color: #FFFFFF;
				}
			}
		}
	}
}