.restriction-nav li {
    width: auto;
    padding-left: 15px;
    padding-right: 15px;

    div {
        font-size: 10px;
    }
}

.setapsect {
    .restriction-nav li {
        padding-left: 10px;
        padding-right: 10px;
        height: 40px !important;
        &::before {
            height: 40px !important;
        }
    }
}

.apc {
    color: #ffa500 !important;
}

.ans {
    width: 200px;
    margin-top: 1em;
    color: #fff;
}

.ar-r {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.at-create.alt {
    background-color: darkblue;
}

.shr {
    border: 1px solid #323232 !important;
    margin-left: -3em;
    opacity: 1;
    margin-top: 25px;
    width: calc(100% + 6em);
}

.clfg {
    position: absolute;
    top: 5px;
    right: 2px;
    cursor: pointer;
    color: #ff5722;
}

.settings-action {
    .switch-box {
        input:not(:checked) + .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #585858;
            transition: .4s;
        }
    }
}

.settings-action {
    display: flex;
    flex-direction: column;
    h3 {
        color: #ffa500;
        margin-bottom: 1em;
    }
    .form-control, .form-control:focus {
        color: #fff !important;
        border: 1px solid #484848 !important;
        background-color: transparent !important;
    }
    label {
        font-size: 14px;
        margin-bottom: 10px;
        color: #fff;

        small {
            display: inline-flex;
            margin-left: 10px;
            font-size: 10px;
            cursor: pointer;
            color: #ffa500;
        }
    }
    .cs_logo {
        top: 100px;
        right: 100px;
    }
    .sb-btn {
        width: 100%;
        background: #ffa500;
        border-radius: 3px;
        border: none;
        color: #FFFFFF;
        font-size: 14px;
        cursor: pointer;
    }
}

.tb-ac-set {

    th, td {
        font-weight: 100 !important;
        border-bottom: 1px solid #393939 !important;
    }
}