.account-overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Poppins, sans-serif;

  &.accda {
    z-index: 1000000000;
    .credit-modal {
      max-width: 420px;
      margin-bottom: 12px;
    }

    .smol.err {
      width: 100%;
      max-width: 420px;
      background: #D14;
      padding: 3px 10px;
      color: #fff !important;
    }

    .acc-ft {
      width: 100%;
      max-width: 420px;

      .col-full {
        width: 90%;
        margin-left: calc(5% + 10px);
        flex-direction: row;
        justify-content: space-between;
        display: flex;

        .contid.alt {
          font-size: 14px !important;
          width: 45% !important;
          border-radius: 12px !important;
        }

        .close {
          background: #D14 !important;
          border: 1px solid red !important;
        }
      }
    }
  }

  .account-modal {
    width: 450px;
    background: #1e1f23 !important;
    padding: 40px;
    position: relative;

    .account-close-btn {
      position: absolute;
      right: 15px;
      cursor: pointer;
    }

    .account-form-section {
      label {
        color: #a09f9f;
        width: 100%;
        text-align: left;
        margin-bottom: 7px;
      }

      input {
        border: none;
        background-color: transparent !important;
        border: 1px solid #fff !important;
      }

      margin-bottom: 20px;
      width: 100%;
    }

    input[type='submit'] {
      margin-top: 10px;
      border: none;
      background: #ffa500 !important;
      border-radius: 2px;
      width: 150px;
      height: 40px;
      color: #fff !important;
      font-family: Poppins, sans-serif;
      cursor: pointer;
    }

    .card-small {
      width: 70%;
    }

    h4 {
      color: #fff !important;
      margin-bottom: 10px;
    }
  }

  .account-form-flex {
    display: flex;
    width: 100;
    justify-content: space-between;

    .account-form-section {
      width: 60% !important;
      &.cvv, &.month, &.year {
        width: 30% !important;
      }
    }
  }

  .account-flex-2 {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

// input[type="month"]::-webkit-inner-spin-button,
// input[type="month"]::-webkit-calendar-picker-indicator {
//     display: none;
//     -webkit-appearance: none;
// }

@media screen and (max-width: 500px) {
  .account-overlay {
    .account-modal {
      width: 320px;
    }
  }
}
